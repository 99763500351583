<template>
    <Loading v-if="isLoginGlobal"/>

    <div class="container-quiz"> 
        <SideHeader/>
        <div class="container-right-quiz">
            <TopHeaderLogin/>
            <div class="main-quiz">
                <BreadCrumbHeader
                :pathPage="path"
                :optionMenu="optionMenu"
                :optionLeftName="optionLeftName"
                :optionRightName="optionRightName"
                />
                <div class="box-select-class">
                    <Multiselect
                    v-model="classValue"
                    :options="optionClass"
                    class="multiSelectClass"
                    :placeholder="classValue"
                    />
                    <Multiselect
                    v-model="sceneValue"
                    :options="optionScene"
                    class="multiSelectClass"
                    :placeholder="sceneValue"
                    />
                    <Multiselect
                    v-model="studentValue"
                    :options="optionStudent"
                    class="multiSelectClass"
                    :placeholder="studentValue"
                    
                    />
                </div>
                <div class="main-box-quiz">
                    <!-- STANDPOINT -->
                    <div class="main-datatable-top">
                        <NavigationAnalytics  :pageStatusParams="pageStatus"/>
                        <div class="new-card-datatable-top">
                            <div class="box-show-list">
                                <p>Quiz</p>
                                <div class="navigation" v-if="isMobilePageActive">
                                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link active" id="pills-reporting-tab" data-bs-toggle="pill" data-bs-target=".test3" type="button" role="tab" aria-controls="pills-reporting" aria-selected="true">
                                                <i class="fa-solid fa-file-lines"></i>
                                                Reporting
                                            </button>
                                        </li>
                                        <p class="d-flex justify-content-center align-items-center"> | </p>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link" id="pills-analytics-tab" data-bs-toggle="pill" data-bs-target=".test4" type="button" role="tab" aria-controls="pills-analytics" aria-selected="false">
                                                <i class="fa-solid fa-chart-column"></i>
                                                Analytics
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="tab-content box-quiz-student">
                                <div id="test" class="tab-pane fade show active table-quiz-student test3">
                                    <div class="quiz-top">
                                        <p>Tabel interaksi siswa terhadap Quiz</p>
                                        <div class="new-box-quiz-top">
                                            <div class="box-new-sort">
                                                <img src="../../assets/sort.svg" alt="" id="sort_active_img" @click="open_sort_standpoint('sort_sp')" v-if="!isSortActiveSp"> 
                                                <!-- isSortActive.value = true -->
                                                <img src="../../assets/sort.svg" alt="" id="sort_active_img" @click="close_sort_standpoint('sort_sp')" v-else> 
                                                <ul class="options-sort" id="sort_sp">
                                                    <li class="sort_sp_class" v-for="(items,id) in optionStandpointquizSort" :key="id" @click="change_sort_standpoint(items.id,'sort_sp')">
                                                        <img src="../../assets/active_icon_dot.svg" alt="" v-if="standPointSortActive === items.id">
                                                        <img src="../../assets/active_icon_dot_white.svg" alt="" v-else>
                                                        <p>{{items.value}}</p>
                                                        <img :src="items.img" alt="" class="icon_arrow">
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="btn-export-all" @click="export_to_csv('quiz')">
                                                <img src="../../assets/icon_download_new.svg" alt="">
                                                <p>Export All</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="box-new-quiz-table">
                                        <table class="assesment-table-detail" id="standpoint_id_table" v-if="standPointTable.length > 0 && isLoadingFetching === false">
                                            <tr>
                                                <th>
                                                    <div class="box-item-detail">
                                                        <p>Nomor</p>     
                                                    </div>
                                                </th>
                                                <th>
                                                    <div class="box-item-detail">
                                                        <p>Pertanyaan</p>     
                                                    </div>
                                                </th>
                                                <th>
                                                    <div class="box-item-detail">
                                                        <p>Durasi Interaksi</p>     
                                                    </div>
                                                </th>
                                                 <th>
                                                    <div class="box-item-detail">
                                                        <p>Pengulangan</p>     
                                                    </div>
                                                </th>
                                            </tr>
                                                <tr v-for="(user,id) in standPointTable" :key="id" :load="log(user)">
                                                    <td v-if="id >= start_page_standpoint && id < end_page_standpoint " >
                                                    <p v-if="standPointSortActive === 'sp_nomor_asc' || standPointSortActive === 'sp_nomor_desc'">{{user.id}}.</p>
                                                    <p v-else> {{id+1}}.</p>
                                                    </td>
                                                
                                                    <td v-if="id>=start_page_standpoint && id < end_page_standpoint">
                                                        <div class="new-image-profile-card">
                                                            <!-- <div class="profile-card">
                                                                <img :src="`${user.img}`" alt="">
                                                            </div> -->
                                                            <p>{{user.oldData.question}}</p>
                                                        </div>
                                                    </td>
                                                    <td v-if="id>=start_page_standpoint && id < end_page_standpoint">
                                                        {{user.durationMinute}}
                                                    </td>
                                                    <td v-if="id>=start_page_standpoint && id < end_page_standpoint">
                                                        <p>{{user.oldData.iteration}}</p>
                                                    </td>
                                                </tr>
                                        </table>
                                        <div class="empty-card"  v-else-if="isLoadingFetching === true">
                                            <div class="spinner-border text-primary" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                        <div class="empty-card" v-else>
                                            <p>No data to display</p>
                                            <p>Try adjusting your search of filter to find what your're looking for</p>
                                        </div> 
                                    </div>
                                    <div class="paginate-left">
                                        <p>Showing {{start_page_standpoint+1}} to {{end_page_standpoint}} of {{total_item_table}} entries</p>
                                            <paginate
                                            v-model="paginate_standpoint"
                                            :page-count=total_page_table
                                            :page-range="3"
                                            :margin-pages="2"
                                            :click-handler="changePageStandpoint"
                                            :prev-text="'<'"
                                            :next-text="'>'"
                                            :container-class="'pagination'"
                                            :page-class="'page-item'">
                                        </paginate>
                                    </div>
                                </div>
                                <div id="test2" class="tab-pane fade  box-chart-student test4" :class="{'active show': isMobilePageActive === false }">
                                    <div class="card-chart-student">
                                        <p>Durasi Interaksi Siswa Terhadap Quiz</p>
                                        <Multiselect
                                            v-model="statusChartStandpoint"
                                            :options="optionStandpoint"
                                            class="multiSelectClass"
                                            :placeholder="statusChartStandpoint"
                                        />
                                    </div>
                                    <div id="chart" v-if="statusChartStandpoint === 'Durasi Interaksi' && isLoadingFetching === false">
                                        <apexchart type="bar" height="300"  id="standpoint_id_chart" :options="standpointValueOptions" :series="standpointValueChart"></apexchart>
                                    </div>
                                    <div id="chart" v-else-if="statusChartStandpoint === 'Pengulangan' && isLoadingFetching === false">
                                        <apexchart type="bar" height="300"  id="standpoint_id_chart" :options="standpointValueOptionsPengulangan" :series="standpointValueChart"></apexchart>
                                    </div>
                                    <div class="empty-card"  v-else-if="isLoadingFetching === true">
                                        <div class="spinner-border text-primary" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                    
                                    <!-- <div class="box-donut-chart" v-if="statusChartStandpoint === 'Donut'">
                                        <pie-chart
                                            
                                            class="donut-chart" art     
                                            :donut="true"
                                            :data="video_percentage"
                                            :colors="['#004F98', '#E8E8E8']"
                                            borderWidth="10px"
                                        ></pie-chart>
                                        <p id="video_id_watch" >
                                            <span>55%</span>   <br>
                                        Video ditonton hingga selesai</p>
                                        <div class="box-donut-right">
                                            <div class="card-donut">
                                                <p>
                                                    <span>35 </span> 
                                                    min 
                                                    <span>40 </span> 
                                                        sc</p>
                                                <p>
                                                        <span>
                                                        <img src="../../assets/icon_video_blue.svg" alt="">
                                                    </span>
                                                    Durasi Siswa Menonton</p>
                                            </div>
                                                <div class="card-donut">
                                                <p>35 min 400 sc</p>
                                                <p>
                                                    <span>
                                                        <img src="../../assets/icon_gray_video.svg" alt="">
                                                    </span>
                                                    Durasi Siswa Menonton</p>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="paginate-left">
                                        <p>Showing {{start_page_chart_sp+1}} to {{end_page_chart_sp}} of {{total_item_table}} entries</p>
                                            <paginate
                                            v-model="paginate_chart_standpoint"
                                            :page-count=total_page_chart
                                            :page-range="3"
                                            :margin-pages="2"
                                            :click-handler="changeChartStandpoint"
                                            :prev-text="'<'"
                                            :next-text="'>'"
                                            :container-class="'pagination'"
                                            :page-class="'page-item'">
                                        </paginate>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- STANDPOINT -->
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import SideHeader from '../../components/Header/SideHeader.vue'
    import TopHeaderLogin from '../../components/Header/TopHeaderLogin.vue'
    import BreadCrumbHeader from '../../components/Header/BreadCrumbHeader.vue'
    // import FooterOverview from '../../components/Footer/FooterOverview.vue'
    import {ref,onMounted, onBeforeMount,watch} from 'vue'
    import VueSelect from 'vue-next-select'
    import 'vue-next-select/dist/index.min.css'
    // import Treeselect from '@riophae/vue-treeselect'
    // import the styles
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    import Multiselect from '@vueform/multiselect'
    import Axios from 'axios'
    import NavigationAnalytics from '@/components/Navigation/Navigation.vue'
    import Paginate from 'vuejs-paginate-next';
    import {useStore} from 'vuex'
    import Loading from '../../components/Loading/Loading.vue'
    
    export default {
        name:'analyticsQuizAct',
        components:{
            SideHeader,
            TopHeaderLogin,
            BreadCrumbHeader,
            // FooterOverview,
            // Treeselect,
            Multiselect,
            NavigationAnalytics,
            Paginate,
            Loading
        },

        
        setup(){
            const store = useStore()
            let isLoadingFetching = ref(true)
            let uidCustomer = ref()
            let isLoginGlobal = ref(true)
            let path = ref('Analytics')
            let optionMenu = ref(false)
            let optionLeftName = ref ('New')
            let optionRightName = ref ('Filter')
            let value = ref (null)
            let classValue = ref('Class')
            let sceneValue = ref('Scene')
            let studentValue = ref('Student')
            let options = ref(['Batman','Robin','Joker',])
            let optionClass = ref()
            let optionScene = ref()
            let optionStudent = ref()
            let pathQuizAct = ref('/analyticsQuizAct') // direction untuk path. testing. gak jadi di pake
            let pageStatus = 'analyticsQuizAct'
            let studentChartValue = ref({'1':10,'2':20,'3':30,'4':20,'May':15,"June":'17',"July":21,"August":30,"September":12,"October":22,"November":88,"December":12})
            let page_paginate=ref(0)
            let valueClass = ref('Class')
            let optionSort = ref([     
                { value: 'Nama Siswa', name: 'Nama Siswa', icon:require('../../assets/overview/arrow_navy.svg') },
                { value: 'Score', name: 'Score', icon:require('../../assets/overview/arrow_navy.svg') },
            ])
            let sort_by_active=ref('Sort By')
            let isMobilePageActive = ref(false)

            let classUidActive = ref('')
            let sceneUidActive = ref('')
            let studentUidActive = ref('')

            let allDataOverview = ref()

            watch(async()=>classValue.value,function(){
                if(classValue.value !== 'Class' &&  classValue.value !== null) {
                    let allSceneValue = []        
                    allDataOverview.value.filter((val)=>{
                        if(val.name === classValue.value){
                            
                            classUidActive.value  = val.id
                            val.sceneList.forEach((value)=>{
                                allSceneValue.push(value.name)
                            })
                        }
                    })
                    
                    if(allSceneValue.length > 0 ){
                        optionScene.value = allSceneValue
                        sceneValue.value = allSceneValue[0]
                    }else {
                        // classValue.value = 'Class'
                        sceneValue.value = 'Scene'
                        studentValue.value = 'Student'
                        sceneUidActive.value = ''
                        studentUidActive.value = ''
                        optionScene.value = []
                        optionStudent.value = []
                        standPointTable.value = []

                        findDataForTableChart(paginate_chart_standpoint.value,'standpoint')
                    }
                    
                    // console.log(optionStudent.value)
                }else {
                    classValue.value = 'Class'
                    sceneValue.value = 'Scene'
                    studentValue.value = 'Student'
                    studentUidActive.value = ''
                    sceneUidActive.value = ''
                    optionScene.value = []
                    studentValue.value = []
                }

                if(classUidActive.value !== '' && sceneUidActive.value !== '' && studentUidActive.value  !== ''){
                    fetching_interaction()
                }
            })

            watch(async()=>sceneValue.value,(()=>{
                if(sceneValue.value !== null && sceneValue.value !== 'Scene'){
                    let allStudentValue = []
                    let allStudentUid = []
                    allDataOverview.value.filter((val)=>{
                        if(val.name === classValue.value){

                            sceneUidActive.value = val.id
                            val.viewerList.forEach((value)=>{
                                allStudentValue.push(value.name)
                                allStudentUid.push(value.id)
                            })
                        }
                    })
                    optionStudent.value = allStudentValue
                    if(optionStudent.value.length > 0 ){
                        studentValue.value = optionStudent.value[0]
                        studentUidActive.value = allStudentUid[0]
                        
                    }
                    // if(studentUidActive.value !== ''){
                    //     fetching_interaction()
                    // }
                }else {
                    sceneValue.value= 'Scene'
                    studentValue.value = 'Student'
                    sceneUidActive.value = ''
                    studentUidActive.value = ''
                    optionStudent.value = []
                    
                }
                if(classUidActive.value !== '' && sceneUidActive.value !== '' && studentUidActive.value  !== ''){
                    fetching_interaction()
                }
            }))

            watch(async()=>studentValue.value, (()=>{
                if(studentValue.value !== null && studentValue.value !== 'Student'){
                    console.log('masuk ke if student value ada')
                    let scene_id = []
                    let student_id = []
                    allDataOverview.value.filter((val)=>{
                        if(val.name === classValue.value){
                            //
                            if(val.sceneList.length > 0){
                                scene_id = val.sceneList.filter((value)=>{
                                    if(value.name === sceneValue.value){
                                        console.log(value,' value scene')
                                        return value.id
                                    }
                                })
                            }
                            if(val.viewerList.length > 0 ){
                               student_id =  val.viewerList.filter((value)=>{
                                    if(value.name === studentValue.value){
                                        console.log(value,' student value')
                                        return value.id
                                    }
                                })
                            }  
                        }
                    })
                    sceneUidActive.value = scene_id[0].id
                    studentUidActive.value = student_id[0].id 
                    if(scene_id[0] !== undefined){
                        if(student_id[0] !== undefined){
                            fetching_interaction()
                            
                        }else {
                            studentUidActive.value = ''
                        }
                    }else {
                        sceneUidActive.value = ''
                    }
                    console.log(scene_id[0])
                    console.log(student_id[0])

                }else {
                    studentValue.value = 'Student'
                    studentUidActive.value = ''
                }

                if(classUidActive.value !== '' && sceneUidActive.value !== '' && studentUidActive.value  !== ''){
                    fetching_interaction()
                }
                
            }))

            // CHECKING SCREEN

            var onresize = function() {
                //your code here
                //this is just an example
                let width = document.body.clientWidth;
                if(width<600){
                    isMobilePageActive.value = true
                }else {
                    isMobilePageActive.value = false
                }
            }
            onMounted(()=>{
               window.addEventListener("resize", onresize);
            })
          
            // CHECKING SCREEN
  


            // STANDPOINT STATE 
            let video_percentage = ref(0)
            let paginate_standpoint = ref(1)
            let start_page_standpoint = ref(0)
            let end_page_standpoint = ref(5)
            let total_page_table = ref(0)
            let total_page_chart = ref(0)
            let total_item_table = ref(0) // 
            let optionStandpointquizSort = ref([]) // state buat sort dropdown table
            // status chart => yg nentuin dia ngebuka column chart / donut chart
            let isSortActiveSp = ref(false) // Open or Close button sort
            
            let paginate_chart_standpoint=ref(1)
            let start_page_chart_sp = ref(0)
            let end_page_chart_sp = ref(10)
            
            let standPointSortActive = ref('sp_nomor_asc') // sort yang lagi active
            let statusChartStandpoint=ref('Durasi Interaksi') // dropdown chart
            let optionStandpoint = ref() // option dropdown chart

            
            let standPointTable = ref([]) // ini untuk data table standpoint
            let standpointValueChart = ref([])
            let updateStandpointValueOptions=ref()

            const log=()=>{
                // console.log(params)
            }
            let standpointValueOptions=ref(
                {
                    chart:{
                    type:'bar',
                    height:300
                        },plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '70%',
                        endingShape: 'rounded'
                    },
                    },dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    xaxis: {
                        categories: [],
                    },
                    yaxis: {
                        title: {
                            text: 'Durasi Interaksi (second)'
                        }
                    },
                    fill: {
                        opacity: 1 ,
                    }, 
                    tooltip: {
                        y: {
                            formatter: function (val) {
                            return "" + val + " second"
                            }
                        }
                    }
                }
            )
            let standpointValueOptionsPengulangan=ref(
                {
                    chart:{
                    type:'bar',
                    height:300
                        },
                    plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '70%',
                        endingShape: 'rounded'
                    },
                    },dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    xaxis: {
                        categories: [],
                    },
                    yaxis: {
                        title: {
                            text: 'Durasi Pengulangan (second)'
                        }
                    },
                    fill: {
                        opacity: 1 ,
                    }, 
                    tooltip: {
                        y: {
                            formatter: function (val) {
                            
                            return ": " + val 
                            }
                        }
                    }
                }
            )
            
            
            const open_sort_standpoint=(params)=>{
                // console.log('sort open')
                if(params === 'sort_sp'){
                    isSortActiveSp.value = true
                }
                    document.getElementById(params).style.display = 'block'
            }
            const close_sort_standpoint=(params)=>{
                // console.log('sort close')
                // console.log(params)
                if(params === 'sort_sp'){
                    isSortActiveSp.value = false
                }
                    document.getElementById(`${params}`).style.display = 'none'
            }
            const change_sort_standpoint=(sort_parent,sort_child)=>{
                // console.log(sort_parent)
                if(sort_child === 'sort_sp'){
                    standPointSortActive.value= sort_parent
                    sortFuncStandpoint(standPointTable.value)
                }

                close_sort_standpoint(sort_child)

                // document.getElementsByClassName('sort_sp_class').classList.remove('sort_active_id')
                // document.getElementById(id).addClasslist('sort_active_id')         
            }

            const changePageStandpoint=(pageNum)=>{
                //  console.log(pageNum)
                let new_end = pageNum * 5
                let new_start = new_end - 5 
    
                start_page_standpoint.value =  new_start
                end_page_standpoint.value = new_end
                // console.log(new_end)
            }
            const changeChartStandpoint=(pageNum)=>{
                // console.log(pageNum)
                let new_end = pageNum * 10
                let new_start = new_end - 10
    
                start_page_chart_sp.value =  new_start
                end_page_chart_sp.value = new_end
                paginate_chart_standpoint.value = pageNum
                // console.log(paginate_chart_standpoint.value,  'page ke')

                findDataForTableChart(pageNum, 'standpoint')
                
            }


            const findDataForTableChart=(pageNum,params)=>{

                if (params === 'standpoint'){
                    paginate_chart_standpoint.value = pageNum
                    let dataChartStandpoint = []
                    let idChartStandpoint = []
                    let max_item_per_page = pageNum * 10
                    
                    let start_item = max_item_per_page - 10 // index untuk filter item chart
                    if(statusChartStandpoint.value === 'Durasi Interaksi'){
                        standPointTable.value.map((val,index)=>{
                            if(index >= start_item && index < max_item_per_page){
                                let find_id = index+1
                                dataChartStandpoint.push(val.durationSecond)
                                idChartStandpoint.push(find_id)
                            }
                        })
                        standpointValueChart.value = [{
                            name: 'Durasi Siswa',
                            data: dataChartStandpoint,
                            color:'#004F98'
                        }]
                        standpointValueOptions.value = {
                            chart:{
                            type:'bar',
                            height:300
                                },plotOptions: {
                            bar: {
                                horizontal: false,
                                columnWidth: '70%',
                                endingShape: 'rounded'
                            },
                            },dataLabels: {
                                enabled: false
                            },
                            stroke: {
                                show: true,
                                width: 2,
                                colors: ['transparent']
                            },
                            xaxis: {
                                categories: idChartStandpoint,
                                title:{
                                    text:'Pertanyaan'
                                }
                            },
                            yaxis: {
                                title: {
                                    text: 'Durasi Interaksi (detik)'
                                }
                            },
                            fill: {
                                opacity: 1 ,
                            }, 
                            tooltip: {
                                y: {
                                    formatter: function (val) {
                                    return "" + val + " Second"
                                    }
                                }
                            }
                        }
                        // console.log(standpointValueChart.value, ' stand point value')
                        // console.log(standpointValueOptions.value,' stand point value')
                    }else if(statusChartStandpoint.value === 'Pengulangan'){
                        standPointTable.value.map((val,index)=>{
                            if(index >= start_item && index < max_item_per_page){
                                let find_id = index+1
                                dataChartStandpoint.push(val.oldData.iteration)
                                idChartStandpoint.push(find_id)
                            }
                        })
                        standpointValueChart.value = [{
                            name: 'Pengulangan',
                            data: dataChartStandpoint,
                            color:'#004F98'
                        }]
                        standpointValueOptionsPengulangan.value = {
                            chart:{
                            type:'bar',
                            height:300
                                },plotOptions: {
                            bar: {
                                horizontal: false,
                                columnWidth: '70%',
                                endingShape: 'rounded'
                            },
                            },dataLabels: {
                                enabled: false
                            },
                            stroke: {
                                show: true,
                                width: 2,
                                colors: ['transparent']
                            },
                            xaxis: {
                                categories: idChartStandpoint,
                                title:{
                                    text:'Quiz'
                                }
                            },
                            yaxis: {
                                title: {
                                    text: 'Durasi Pengulangan'
                                }
                            },
                            fill: {
                                opacity: 1 ,
                            }, 
                            tooltip: {
                                y: {
                                    formatter: function (val) {
                                    return "" + val + " Pengulangan"
                                    }
                                }
                            }
                        }
                    }     
                    standpointValueOptions.value.xaxis.categories = idChartStandpoint
                    standpointValueOptionsPengulangan.value.xaxis.categories = idChartStandpoint
                }      
            }

            watch(async()=>statusChartStandpoint.value,function(){
                // console.log(statusChartStandpoint.value)
                //beda
                findDataForTableChart(paginate_chart_standpoint.value, 'standpoint')
            })

            const export_to_csv=(params)=>{
                // console.log(params)
                // var csv = [];
                
                // for (var i = 0; i < rows.length; i++) {
                    //     var row = [], 
                //     cols = rows[i].querySelectorAll("td, th");
                //     console.log(cols)
                //     // console.log(cols)
                    
                //     for (var j = 0; j < cols.length; j++) 
                //         console.log(cols[j].innerText)
                //         row.push(cols[j].innerText);
                    
                //     csv.push(row.join(","));	
                //     console.log(row)	
                // }
                let arrayToPdf = [
                    // 'Nomor,Durasi Interaksi,Pengulangan',
                ]
                var rows = document.querySelectorAll("#standpoint_id_table tr");
                let row = []
                rows[0].querySelectorAll('td,th').forEach((val)=>{
                    // console.log(val.innerText)
                    row.push(val.innerText)
                })
                arrayToPdf.push(row.join(','))
                // console.log(rows[0].querySelectorAll('td,th'))
                // console.log(rows[0].innerText)
                // arrayToPdf.push(rows[0].innerText)
                if(params  === 'quiz'){
                    standPointTable.value.forEach((val,index)=>{
                        let question = val.oldData.question                    
                        let join_question = question.replace(/,/g, '')
                        arrayToPdf.push(`${index+1},${join_question},${val.durationMinute},${val.oldData.iteration}`)
                    })
                }
                

                // Download CSV
                download_csv(arrayToPdf.join("\n"), params);
            }
      

            function download_csv(csv, filename) {
                var csvFile;
                var downloadLink;

                // CSV FILE
                csvFile = new Blob([csv], {type: "text/csv"});

                // Download link
                downloadLink = document.createElement("a");

                // File name
                downloadLink.download = filename;

                // We have to create a link to the file
                downloadLink.href = window.URL.createObjectURL(csvFile);

                // Make sure that the link is not displayed
                downloadLink.style.display = "none";

                // Add the link to your DOM
                document.body.appendChild(downloadLink);

                // Lanzamos
                downloadLink.click();
            }

            // STANDPOINT STATE
             function fmtMSS(s){
                let second = Math.floor(s)
                return(second-(second%=60))/60+(9<second?' : ':' : 0')+second
            }

         

            const sortFuncStandpoint=(getAllStandPointTable)=>{

                if(standPointSortActive.value === 'sp_nomor_asc'){
                    getAllStandPointTable.sort(function(a,b){
                        return a.id-b.id})
                }else if (standPointSortActive.value === 'sp_nomor_desc'){
                     getAllStandPointTable.sort(function(a,b){
                        return b.id-a.id})
                }else if(standPointSortActive.value === 'sp_durasi_asc'){
                    getAllStandPointTable.sort(function(a,b){
                        return a.oldData.duration-b.oldData.duration})
                }else if(standPointSortActive.value === 'sp_durasi_desc'){
                    getAllStandPointTable.sort(function(a,b){
                        return b.oldData.duration-a.oldData.duration})
                }else if (standPointSortActive.value === 'sp_pengulangan_asc'){
                    getAllStandPointTable.sort(function(a,b){
                        return a.oldData.iteration - b.oldData.iteration})
                }else if (standPointSortActive.value === 'sp_pengulangan_desc'){
                    getAllStandPointTable.sort(function(a,b){
                        return b.oldData.iteration - a.oldData.iteration})
                }else if (standPointSortActive.value === 'sp_pertanyaan_asc'){
                    getAllStandPointTable.sort(function(a,b){
                        return a.oldData.question.localeCompare(b.oldData.question)})
                }else if (standPointSortActive.value === 'sp_pertanyaan_desc'){
                    getAllStandPointTable.sort(function(a,b){
                        return b.oldData.question.localeCompare(a.oldData.question)})
                }
                standPointTable.value = getAllStandPointTable
                return getAllStandPointTable

                // set up standpoint chart
               
            }
            const fetching_class=async()=>{ // fetching class scene student

                // filter find class
                let allClassValue = []
              
                if(allDataOverview.value !== undefined || allDataOverview.value.length > 0 ){
                    allDataOverview.value.forEach((val)=>{
                        allClassValue.push(val.name)
                    })
                    optionClass.value = allClassValue
                    // optionClass.value = ['Test', 'Hello']
                    
                }

            }
            const fetching_interaction=async()=>{
                isLoadingFetching.value = true
                let owner_uid = localStorage.getItem('idStorage')
                console.log(owner_uid,sceneUidActive.value,classUidActive.value,studentUidActive.value)
                await Axios.post(`https://us-central1-millea-lab.cloudfunctions.net/web/api/getQuizData`,{
                    "owner_uid":uidCustomer.value,
                    "classroom_uid":classUidActive.value,
                    "scene_uid":sceneUidActive.value,
                    "viewer_uid":studentUidActive.value
                }).then((res)=>{
                    console.log(uidCustomer.value,'uid customer')
                    console.log(classUidActive.value, 'class uid')
                    console.log(sceneUidActive.value,'scene')
                    console.log(studentUidActive.value,' student')
                    console.log(res.data)
                    let newAllStandPointTable = []
                     res.data.forEach((val,id)=>{
                        let duration = val.duration
                        newAllStandPointTable.push({
                            oldData:val,
                            durationMinute:fmtMSS(duration),
                            durationSecond:Math.floor(duration),
                            id:(id+1)
                        })     
                    })
                    standPointTable.value = newAllStandPointTable      
                    total_page_table.value = Math.ceil((newAllStandPointTable.length / 5) )
                    total_page_chart.value = Math.ceil((newAllStandPointTable.length / 10))
                    total_item_table.value = newAllStandPointTable.length
                    // sortFuncStandpoint(standPointTable.value)
                    findDataForTableChart(paginate_chart_standpoint.value,'standpoint')
                }).catch((err)=>{
                    console.log(err.message)
                    standPointTable.value = []
                    findDataForTableChart(paginate_chart_standpoint.value,'standpoint')
                })
                isLoadingFetching.value = false

            }

            const fetch=async()=>{
                // get state for standpoint 
                // let getAllStandPointTable = store.getters.getAllStandPointTable.standPointTable
                optionStandpoint.value= store.getters.getAllStandPointTable.optionStandPointSort
                optionStandpointquizSort.value = await store.getters.getAllStandPointTable.optionStandPointquiz


                uidCustomer.value = await store.getters.getValueUidCustomer
                allDataOverview.value = await store.getters.getAllOverview 
                if(allDataOverview.value !== undefined || allDataOverview.value.length > 0){
                    classValue.value = allDataOverview.value[0].name
                    classUidActive.value = allDataOverview.value[0].id
                    let allClassValue = []
                    allDataOverview.value.forEach((val)=>{
                        allClassValue.push(val.name)
                    })
                    optionClass.value = allClassValue
                    if(allDataOverview.value[0].sceneList.length > 0 ){
                        sceneValue.value = allDataOverview.value[0].sceneList[0].name
                        optionScene.value = allDataOverview.value[0].sceneList
                        sceneUidActive.value = allDataOverview.value[0].sceneList[0].id
                    }else {
                        optionScene.value = []
                        sceneValue.value = 'Scene'
                        sceneUidActive.value = ''
                    }
                    if(allDataOverview.value[0].viewerList.length > 0 ){
                        studentValue.value = allDataOverview.value[0].viewerList[0].name
                        optionStudent.value = allDataOverview.value[0].viewerList
                        studentUidActive.value = allDataOverview.value[0].viewerList[0].name
                    }else {
                        optionStudent.value = []
                        studentValue.value = 'Student'
                        studentUidActive.value = ''
                    }

                    if(classValue.value !== 'Class' && sceneValue.value !== 'Scene' && studentValue.value !== 'Student'){
                        await fetching_class()
                        await fetching_interaction()
                    }
                }else {
                    classValue.value = 'Class'
                    sceneValue.value = 'Scene'
                    studentValue.value = 'Student'
                    classUidActive.value = ''
                    sceneUidActive.value = ''
                    studentUidActive.value = ''
                }
                isLoginGlobal.value = store.getters.getIsLogin
                
             

                

                isLoginGlobal.value = false
                isLoadingFetching.value = false
            }
            // console.log(allDataFromApi.value)

            onMounted(()=>{
                })
            onBeforeMount(()=>{
                fetch()
                
            })

    

            const clickCallback=(pageNum)=>{
                //  console.log(pageNum)
                let new_end = pageNum * 5
                let new_start = new_end - 5 
    
                start_page_standpoint.value =  new_start
                end_page_standpoint.value = new_end
            }
            const toquiz=()=>{
                // router.push('/users/eduardo')

            }
             return {
                path,
                optionMenu,
                optionLeftName,
                optionRightName,
                VueSelect,
                // Treeselect,
                value,
                options,
                toquiz,
                pathQuizAct,
                fetch,
                pageStatus,
                valueClass,
                optionSort,
                sort_by_active,
                studentChartValue,
                statusChartStandpoint,
                standpointValueChart,
                standpointValueOptions,
                optionStandpoint,
                standPointTable,
                open_sort_standpoint,
                isSortActiveSp,
                close_sort_standpoint,
                change_sort_standpoint,
                page_paginate,
                clickCallback,
                standPointSortActive,
                optionStandpointquizSort,
                log,
                paginate_standpoint,
                start_page_standpoint,
                end_page_standpoint,
                changePageStandpoint,
                total_page_table,
                total_item_table,
                changeChartStandpoint,
                start_page_chart_sp,
                end_page_chart_sp,
                paginate_chart_standpoint,
                total_page_chart,
                updateStandpointValueOptions,
                standpointValueOptionsPengulangan,
                findDataForTableChart,
                export_to_csv,
                download_csv,
                classValue,
                sceneValue,
                studentValue,
                onresize,
                isMobilePageActive,
                optionClass,
                optionScene,
                optionStudent,
                video_percentage,
                allDataOverview,
                uidCustomer,
                isLoginGlobal,
                fetching_class,
                fetching_interaction,
                sceneUidActive,
                studentUidActive,
                classUidActive,
                isLoadingFetching,
                fmtMSS
                
            }


           
        }


     
    }

</script>

<style lang="scss">
    @import '../../scss/AnalyticsQuizAct.scss';
    @import '../../scss/Mobile/AnalyticsQuizAct.scss';
</style>
<style src="@vueform/multiselect/themes/default.css"></style>
