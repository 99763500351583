<template>
    <div class="container-footer-register">
        <div class="box-option-header-left">
            <p>© 2022 MilleaLab. All rights reserved</p>
        </div>
        <div class="box-option-header-right">
            <p>About Us</p>
            <p> | </p>
            <p>Features</p>
            <p> | </p>
            <p>Pricing</p>
            <p> | </p>
            <p>Ambassador</p>
        </div>
    </div>
</template>


<script>
    export default {
        name:'footerRegister'
    }
</script>

<style lang="scss">
    @import '../../scss/footerRegister.scss';
</style>
