<template>
  <div class="container-reset">
      <TopHeader/>
      <div class="box-reset">
          <div class="main-reset-box">
              <img src="../assets/Authentication/resetIcon.svg" alt="">
              <div class="box-reset-password">
                  <p>Masukan email untuk reset password</p>
                   <div class="register-input-detail">
                      <img src="../assets/Authentication/email.svg" class="icon-register" alt="">
                      <input type="text" class="input-searching" placeholder="Email" v-model="customerEmail" @change="onResetEmail">
                      <img src="../assets/Authentication/padlock.svg" style="visibility:hidden" alt="">
                  </div>
                  <div class="btn-reset" @click="onSendReset">
                    <p>Send</p>
                  </div>
              </div>
          </div>
      </div>
      <FooterRegister/>
  </div>
</template>

<script>
// @ is an alias to /src
import TopHeader from '@/components/Header/TopHeader.vue'
import FooterRegister from '@/components/Footer/footerRegister.vue'
import { ref } from '@vue/reactivity'
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'


export default {
  name: 'ResetView',
  components: {
    TopHeader,
    FooterRegister
  },
  setup(){
      let customerEmail = ref('')
      let isEmailCorrect = ref(false)
       function validateEmail(email) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        }

      const onResetEmail=()=>{
        let checking_email = validateEmail(customerEmail.value)
         if(checking_email){
              isEmailCorrect.value = true
          }else {
              isEmailCorrect.value = false
              if(customerEmail.value.length === 0){
                  // console.log('masuk ke if')
              }else {
                createToast({
                     title: 'Error',
                     description: 'ada kesalahan pada Format Email'
                     },
                     {
                     // timeout: 3000,
                     type: 'danger',
                     transition: 'bounce',
                 })
              }
          }
      }

      const onSendReset=()=>{
        alert('function jalan')
      }
      return {
          customerEmail,
          onResetEmail,
          validateEmail,
          isEmailCorrect,
          onSendReset
      }
  }
}
</script>

<style lang="scss">
    @import '../scss/Reset.scss';
</style>
