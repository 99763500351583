<template>
    <div class="container-header">
        <div class="main-header ">
            <div class="box-icon-header">
                <img src="../../assets/Header/Millealablogo.svg" alt="">
                <img src="../../assets/Header/Millealabname.svg" alt="">
            </div>
            <div class="box-option-header">
                <p>About Us</p>
                <p>Features</p>
                <p>Pricing</p>
                <p>Ambassador</p>
                <p>Partner</p>
                <p>Blog</p>
            </div>
        </div>
        
    </div>
</template>


<script>
    export default {
        name:'TopHeader'
    }
</script>

<style lang="scss">
    @import '../../scss/TopHeader.scss';
</style>
