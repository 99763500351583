<template>
  <Suspense>
    <template #default >
      <div v-if="isLoading">
        <LoaderPage/>
      </div>
      <router-view v-else/>

    </template>
  </Suspense>
</template>
<script>
import {ref,onMounted,watch } from 'vue'
import {useStore} from 'vuex'
import LoaderPage from '@/components/Loading/Loading.vue'

export default {
  name:'app',
  components:{
    LoaderPage
  },
  setup() {
    
    const store = useStore()
    let isLoading = ref(true)
    
    onMounted(()=>{
        isLoading.value = store.getters.getIsLoading
    })
    watch(()=>store.getters.getIsLoading,function() {
      // console.log('watch jalan checking isLoading', store.getters.getIsLoading,)
      isLoading.value = store.getters.getIsLoading
    })
  
    return {
      isLoading,
      LoaderPage,
      
    }



  },
}
</script>


<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
