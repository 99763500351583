<template>
    <div class="container-breadcrumb">
        <div class="box-main-breadcrumb">
            <div class="bc-left">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <!-- <li class="bc-detail">
                            <a href="#"> 
                                <img src="../../assets/Header/HomeBC.svg" alt="">
                            </a>
                        </li> -->
                        <li class="active-id bc-detail" aria-current="page">Dashboard</li>
                        <img src="../../assets/Header/new-moon.png" alt="">
                        <li class="breadcrumb-item bc-detail" aria-current="page" @click="onClickEmit()">{{pathPage}}</li>
                    </ol>
                </nav>
            </div>
             <!-- v-if="optionMenu === true" -->
            <div class="bc-right" v-if="withbg == true " :load="log(withbg)">
                <!-- <div class="btn-new">
                    {{optionLeftName}}
                </div>
                <div class="btn-filters">
                    {{optionRightName}}
                </div> -->
            </div>
        </div>
    </div>
</template>


<script>
// import Vue from 'Vue' 
// import { BBreadcrxumb } from 'bootstrap-vue'
// import { BBreadcrumb } from 'bootstrap-vue'
// Vue.component('b-breadcrumb', BBreadcrumb)
// import {emit} from 'vue'


export default {
    name:'BreadCrumbHeader',
     props:{
        pathPage:String,
        optionMenu:Boolean,
        optionLeftName:String,
        optionRightName:String,
        withbg:Boolean
        
    },
    setup(_props,{emit}){

        const log=(params)=>{
            console.log(params)
        }
        const onClickEmit=()=>{
            emit('testing-emit',{status:'okeee emit jalan'})
        }
        return {
            onClickEmit,
            log
        }
    }
   

}
</script>
    

<style lang="scss">
@import '../../scss/BreadCrumbHeader.scss';
</style>