<template>
    <!-- side bar open start -->
    <div class="container-sideHeader-resize" v-if="isOpen === true">
        <div class="box-icon-sh-1">
            <img src="../../assets/Header/millealogoside.svg" alt="">
            <img src="../../assets/Header/sidenavside.svg" alt="" class="icon-nav" @click="onSideBar()">
        </div>
        <router-link to="/" class="box-icon-sh-2">
            <div class="box-separate-icon">
                <img src="../../assets/Header/new_home_icon.png" alt="" class="icon-sidebar">
                <p>Overview</p>
            </div>
            <img src="../../assets/Header/arrowRight.svg" alt="" class="icon-arrow">
        </router-link>
        <router-link to="/assessment" class="box-icon-sh-2" v-if="isLoginGlobal === true">
            <div class="box-separate-icon">
                <img src="../../assets/Header/new_assessment_icon.png" alt="" class="icon-sidebar">
                <p>Assessment</p>
            </div>
            <img src="../../assets/Header/arrowRight.svg" alt="" class="icon-arrow">
        </router-link>
        <router-link to="/analytics-interaction" class="box-icon-sh-2" v-if="isLoginGlobal === true">
            <div class="box-separate-icon">
                <img src="../../assets/Header/new_analytics_icon.png" alt="" class="icon-sidebar">
                <p>Analytics</p>
            </div>
            <img src="../../assets/Header/arrowRight.svg" alt="" class="icon-arrow">
        </router-link>
        <img src="../../assets/Header/Separator.svg" alt="" class="icon-sidebar" id="separator_id"> 
         <div class="box-icon-sh-2">
            <div class="box-separate-icon" v-if="isLoginGlobal === true" @click="onLogout">
                <img src="../../assets/Header/new_logout_icon.png" alt="" class="icon-sidebar-resize">
                <p>Logout</p>
            </div>
            <router-link to="/login"  class="box-separate-icon" v-if="isLoginGlobal === false" >
                <img src="../../assets/Header/log-in-navy.svg" alt="" class="icon-sidebar-resize">
                <p>Login</p>
            </router-link >
        </div>
    </div>
    <!-- side bar open end -->
    <!-- side bar close start -->
    <div class="container-sideHeader"  v-else >
        <div class="box-icon-sh-1">
            <img src="../../assets/Header/SideNavTogglerLines.svg" alt="" class="icon-nav" @click="onSideBar()">
        </div>
        <router-link to="/" class="box-icon-sh-2">
            <img src="../../assets/Header/new_home_icon.png" alt="" class="icon-sidebar">
        </router-link>
        <router-link to="/assessment" class="box-icon-sh-2" v-if="isLoginGlobal === true">
            <img src="../../assets/Header/new_assessment_icon.png" alt="" class="icon-sidebar">
        </router-link>
        <router-link to="/analytics-interaction" class="box-icon-sh-2" v-if="isLoginGlobal === true">
            <img src="../../assets/Header/new_analytics_icon.png" alt="" class="icon-sidebar">
        </router-link>
   
        <img src="../../assets/Header/Separator.svg" alt="" class="icon-sidebar" id="separator_id"> 
         <!-- logout -->
        <div class="box-icon-sh-2" v-if="isLoginGlobal === true" @click="onLogout" >
            <img src="../../assets/Header/new_logout_icon.png" alt="" class="icon-sidebar">
        </div>
        <!-- logout -->
        <!-- login -->
        <router-link to="/login" class="box-icon-sh-2" v-if="isLoginGlobal === false">
            <img src="../../assets/Header/log-in-navy.svg" alt="" class="icon-sidebar">
        </router-link >
        <!-- login -->
    </div>
    <!-- side bar close end -->
</template>


<script>
    import {ref,onBeforeMount,} from 'vue'
    import {useStore} from 'vuex'
    // import {useRouter} from 'vue-router'
    import {signOut} from 'firebase/auth'
    import {auth} from '../../../firebase-config'

export default {
    name:'sideHeader',
    setup(){
        let isOpen = ref(false)
        let isLoginGlobal = ref(false)
        const store = useStore()

        const onSideBar=()=>{
            isOpen.value = !isOpen.value
            
        }

        const fetch=()=>{
            isLoginGlobal.value = store.getters.getIsLogin
        }

        const onLogout=async()=>{
            store.dispatch('getValueLogout',false)
            // eslint-disable-next-line no-unused-vars
            let result = await signOut(auth)
            signOut(auth).then(()=>{
                
                // console.log(result)
                setTimeout(()=>{
                    window.location.href = '/';
    
                },2000)
            })
        }
        onBeforeMount(()=>{
            fetch()
        })
        return {
            onSideBar,
            isOpen,
            isLoginGlobal,
            fetch,
            onLogout
        }
    }
}

</script>

<style lang="scss">
    @import '../../scss/SideHeader.scss';
    @import '../../scss/Mobile/SideHeader.scss';
</style>