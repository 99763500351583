<template>
    <div class="top-option-activities">
        <div class="btn-direction-analytics " :class="{'active-interaction' : status === 'analyticsInteraction'}">
            <router-link :to="{name:'analyticsInteraction'}" >Interaction</router-link>
        </div>
        <!-- <div class="btn-direction-analytics" :class="{'active-interaction' : status === 'analyticsJourneyAct'}">
            <router-link :to="{name:'analyticsJourneyAct'}"  class="a-direction-analytics">Journey Activities</router-link>
        </div> -->
        <div class="btn-direction-analytics"  :class="{'active-interaction' : status === 'analyticsQuizAct'}">
            <router-link :to="{name:'analyticsQuizAct'}">Quiz Activities</router-link>
        </div>
    </div>
</template>


<script>
import {ref,onMounted} from 'vue'
export default {
    name:'navigation-analytics',
     props: {
        pageStatusParams: String,

    },
    setup(_props){
        let status = ref(_props.pageStatusParams)

        console.log(status.value,'ini dari props')
        onMounted(()=>{
            status.value = _props.pageStatusParams
            console.log(status.value)
        })
        return {
            status
        }
    }
}


</script>

<style lang="scss">
$bg-gray:#f2f6fa;
$font-gray:#8898AA;
$font-green:#2DCE89;
$font-darkgray:#32325D;
$font-blue:#11CDEF;
$font-red:#F5365C;
$line-brown:linear-gradient(360deg, #D88144 -4.89%, #FFC357 105.5%);
$gradient-navy:linear-gradient(360deg, #004F98 -4.89%, #007FFF 105.5%);;
$font-black: #525f7F;
$bg-table:#F6F9FC;
  .top-option-activities{
        width:100%;
        height:80px;
        min-height:80px;
        display: flex;
        flex-direction: row;
        align-items: center;
        // border-bottom:1px solid #dedede;
        .btn-direction-analytics{
            width:160px;
            height:40px;
            background: white;
            padding:5px;
            /* Shadow/Default */
            box-shadow: 0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08);
            border-radius: 4px;
            margin-right:10px;
            display: flex;
            justify-content: center;
            align-items: center;
            a{
                font-weight: 600;
                font-size: 14px;
                line-height: 19px;
                font-family:'Open Sans';
                letter-spacing:-0.3px;
                text-decoration:none;
                color:#004F98;
                height:100%;
                width:100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            
            
        }
        .active-interaction{
            background:#004F98;
            box-shadow: 0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08);
            border-radius: 4px;
            a{
                color:white !important;
            }
        }
    }

</style>