// import Vue from 'vue'
import Axios from 'axios'
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'
import {doc,getDoc} from 'firebase/firestore'
import {db} from '../../firebase-config'


function secondToMinutesAndSeconds(seconds) {
    var minutes = Math.floor(seconds / 60);
    // var seconds = ((seconds % 60000) / 1000).toFixed(0);
    let hours = Math.floor(minutes / 60);
    let second = Math.floor((seconds % 60).toFixed(0))
    return hours + 'h ' +  minutes + "m "  + second +  "s"
}

const state = {

    allProduct:[],
    allProducts:[],
    isLoading:false,
    isLogin:false,
    interactionStandPoint:
        {
            standPointTable:[
               
            ],
            optionStandPointSort:['Durasi Interaksi','Pengulangan'],
            optionStandPointInteraction:[  {
                value:'Nomor',
                img:require('../assets/InteractionAssets/down_arrow.svg'),
                id:"sp_nomor_asc"
            },
             {
                value:'Nomor',
                img:require('../assets/InteractionAssets/gray_top_arrow.svg'),
                id:"sp_nomor_desc"
            },
            {
                value:'Durasi Interaksi',
                
                img:require('../assets/InteractionAssets/down_arrow.svg'),
                id:"sp_durasi_asc"
            },
             {
                value:'Durasi Interaksi',
                img:require('../assets/InteractionAssets/gray_top_arrow.svg'),
                id:"sp_durasi_desc"
            },
             {
                value:'Pengulangan',
                img:require('../assets/InteractionAssets/down_arrow.svg'),
                
                id:"sp_pengulangan_asc"
            },
             {
                value:'Pengulangan',
                img:require('../assets/InteractionAssets/gray_top_arrow.svg'),
                id:"sp_pengulangan_desc"
            },],

            optionStandPointquiz:[  {
                value:'Nomor',
                img:require('../assets/InteractionAssets/down_arrow.svg'),
                id:"sp_nomor_asc"
            },
             {
                value:'Nomor',
                img:require('../assets/InteractionAssets/gray_top_arrow.svg'),
                id:"sp_nomor_desc"
            },
            {
                value:'Pertanyaan',
                img:require('../assets/InteractionAssets/down_arrow.svg'),
                id:"sp_pertanyaan_asc"
            },
             {
                value:'Pertanyaan',
                img:require('../assets/InteractionAssets/gray_top_arrow.svg'),
                id:"sp_pertanyaan_desc"
            },
             {
                value:'Durasi Interaksi',
                img:require('../assets/InteractionAssets/down_arrow.svg'),
                
                id:"sp_durasi_asc"
            },
             {
                value:'Durasi Interaksi',
                img:require('../assets/InteractionAssets/gray_top_arrow.svg'),
                id:"sp_durasi_desc"
            },
            {
                value:'Pengulangan',
                img:require('../assets/InteractionAssets/down_arrow.svg'),
                id:"sp_pengulangan_asc"
            },
             {
                value:'Pengulangan',
                img:require('../assets/InteractionAssets/gray_top_arrow.svg'),
                id:"sp_pengulangan_desc"
            },]
    },
    interactionStandPointTable:[],

    newInteractionStandPoint:[],
    // user:{
    //     name:'',
    //     firstName:'',
    //     lastName:'',
    //     phoneNumber:'',
    //     email:'',
    //     referral_code:'',
    //     points:0,
    //     totalReferral:0,
    //     address:'',
    //     zipCode:'',
    //     gender:'',
    //     company:'',
    //     jobTitle:'',
    //     placeOfBirth:'',
    //     dateOfBirth:'',
    //     individualLicense:0,
    //     vrCardboard:0,
    //     level:'',
    //     referralHistory:[],
    //     profilePicture:''
    // },
    user:undefined,
    allDataFromOverview:undefined, // data for overview and interaction page. class,subject,student name
    allDataInteractionPage:[],
    allTopStudent:undefined,
    allTeacherReport:undefined,
    uidCustomer:undefined
}

const mutations = {
    setAllProduct(state,{name,value}){
        state[name] = value
    },
    setValueLogout(state,{name,value}){
        state[name]= value
    },
    setValueLogin(state,{name,value}){
        // state[name] = value
        state[name] = value
        // console.log(state[name])
    },
    setValueLoading(state,{name,value}){
        state[name] = value
    },
    setValueOverview(state,{name,value}){
        state[name] = value
    },
    setValueinteraction(state,{name,value}){
        state[name] = value 
    },
    setValueTopStudent(state,{name,value}){
        state[name] = value
    },
    setValueTeacherReport(state,{name,value}){
        state[name] = value
    },
    setValueUid(state,{name,value}){
        state[name] = value
    }
    
}

const actions = {
    getValueLoginWithGoogle:({commit},valueParams)=>{
        commit('setValueLogin',{name:'isLogin',value:true})
        commit('setValueLogin',{name:'user',value:valueParams})
        // console.log(valueParams,' ini value params')
    },
    getValueOverview:async({commit},idStorage)=>{
    
       await Axios.post(`https://us-central1-millea-lab.cloudfunctions.net/web/api/getClassroomListByOwnerUID`,{
            "owner_uid":idStorage
        }).then((res)=>{
            commit('setValueOverview',{name:'allDataFromOverview',value:res.data})
        }).catch((err)=>{
            console.log(err.message)
            commit('setValueOverview',{name:'allDataFromOverview',value:[]})
        })
    },
    getValueLogin:async({commit})=>{
        let idStorage  = localStorage.getItem('idStorage')
        
        commit('setValueUid',{name:'uidCustomer',value:idStorage})
        if(idStorage !== null && idStorage !== undefined){
            // berhasil login
            let collectionID = 'users'
            let docRef =  doc(db, collectionID, idStorage);
            let docSnapshot = await getDoc(docRef);
            let user = docSnapshot.data()
            if(user !== undefined){
                if(user.userDetails !== undefined){
                    let sendToFirebase = {
                        id: user.id,
                        name: user.name,
                        email: user.email,
                        phone: user.phone,
                        address:user.userDetails.address,
                        zipCode:user.userDetails.zip_code,
                        gender:user.userDetails.gender,
                        company:user.userDetails.company,
                        dateOfBirth:user.userDetails.date_of_birth,
                        jobTitle:user.userDetails.job_title,
                        placeOfBirth:user.userDetails.place_of_birth,
                        profilePicture:user.profile_picture !== undefined ? user.profile_picture : '',
                        // school:user.school !== undefined ? user.school : 'school',
                        // subject:user.subject !== undefined ? user.subject : 'subject',
                        // country:user.country !== undefined ? user.country : 'country',
                        school:user.userDetails.school,
                        subject:user.userDetails.subject,
                        country:user.userDetails.country,
                        province:user.userDetails.province,
                    }
                    commit('setValueLogin',{name:'user',value:sendToFirebase})
                    commit('setValueLogin',{name:'isLogin',value:true})
                }else {
                    let sendToFirebase = {
                        id: user.id,
                        name: user.name,
                        email: user.email,
                        phone: user.phone,
                        address:user.userDetails.address,
                        zipCode:user.userDetails.zip_code,
                        gender:user.userDetails.gender,
                        company:user.userDetails.company,
                        dateOfBirth:user.userDetails.date_of_birth,
                        jobTitle:user.userDetails.job_title,
                        placeOfBirth:user.userDetails.place_of_birth,
                        profilePicture:user.profile_picture !== undefined ? user.profile_picture : '',
                        // school:user.school !== undefined ? user.school : 'testing schoole name',
                        // subject:user.subject !== undefined ? user.subject : 'testing subject',
                        // country:user.country !== undefined ? user.country : 'testing Country',
                        school:user.userDetails.school,
                        subject:user.userDetails.subject,
                        country:user.userDetails.country,
                        province:user.userDetails.province,
                    }
                    commit('setValueLogin',{name:'user',value:sendToFirebase})
                    commit('setValueLogin',{name:'isLogin',value:true})
                }
                
                return true
            }else {
                localStorage.removeItem('idStorage')     
                return false
            }
        }else {
            // gagal login, id storage tidak ada atau eamng blm login
            // console.log('masuk ke else 157 auth js')
            
            commit('setValueLogin',{name:'isLogin',value:false})
            return false
        }

    },
    getValueLogout:({commit})=>{
    localStorage.removeItem('idStorage')
    commit('setValueLogout',{name:'isLogin',value:false})
    createToast({
        title: 'Success',
        description: 'Berhasil Logout'
        },
        {
        timeout: 2000,
        type: 'success',
        transition: 'bounce',
        position:'top-center'
    })
    },
    getValueLoading:({commit},valueParams)=>{
        // console.log(valueParams)
        // commit('setValueLogin',{name:'isLogin',value:false})
        commit('setValueLoading',{name:'isLoading',value:valueParams.value})
        return true
    },
    getValueIsLogin:async({commit},valueParams)=>{
        commit('setValueLogin',{name:'isLogin',value:valueParams.value})
    },
    getNewAllProducts:({commit},{success,fail})=>{
        Axios.post(`https://products.sold.co.id/get-product-details`)
        .then((res)=>{
            commit('setAllProduct',{name:'allProducts',value:res.data})
            success && success(res.data)
        }).catch((err)=>{
            // console.log(err)
            fail && fail(err)
        })
    },
    // eslint-disable-next-line no-unused-vars
    getAllValueInteractionPage:async({commit})=>{
        // console.log(commit)
        let idStorage = localStorage.getItem('idStorage')
        let allDataInteraction=[

        ]

        // eslint-disable-next-line no-unused-vars
        let response = await  Promise.all([
            // fetching standpoint table    
            Axios.post(`https://us-central1-millea-lab.cloudfunctions.net/web/api/getStandpointData`,{
                "classroom_uid": idStorage,
            }).then((res)=>{
                allDataInteraction.push({
                    "standPoint":res.data
                })
                return true
            }).catch((err)=>{
                console.log(err)
                return false
            }),
            // fetching standpoint table


            // Object  table
            Axios.post(`https://us-central1-millea-lab.cloudfunctions.net/web/api/getObjectData`,{
                "classroom_uid":idStorage
            }).then((res)=>{
                allDataInteraction.push({
                    "object":res.data
                })
                return true
            }).catch((err)=>{
                console.log(err)
                return false
            }),
            // Object  table

            // video table
            Axios.post(`https://us-central1-millea-lab.cloudfunctions.net/web/api/getVideoData`,{
                "classroom_uid": idStorage
            }).then((res)=>{
                // console.log(res.data)
                allDataInteraction.push({
                    "video":res.data
                })
                return true
            }).catch((err)=>{
                console.log(err)
                return false
            }),
            // video table

            // video360 table
            Axios.post(`https://us-central1-millea-lab.cloudfunctions.net/web/api/getVideo360Data`,{
                "classroom_uid": idStorage
            }).then((res)=>{
                // console.log(res.data)
                allDataInteraction.push({
                    "video360":res.data
                })
                return true
            }).catch((err)=>{
                console.log(err)
                return false
            })
            // video360 end table
        ])

        
        // console.log(allDataInteraction)
    },
    getAllValueTopStudent:async({commit},idStorage)=>{
        
        await Axios.post(`https://us-central1-millea-lab.cloudfunctions.net/web/api/getTopStudentsByOwnerUID`,{
            "owner_uid":idStorage
        }).then((res)=>{
            commit('setValueTopStudent',{name:'allTopStudent',value:res.data})
        }).catch((err)=>{
            console.log(err)
            commit('setValueTopStudent',{name:'allTopStudent',value:[]})
        })
    },
    getValueTeacherReport:async({commit},idStorage)=>{
        const month = (new Date().getMonth() + 1)
        const year = new Date().getFullYear();
        console.log(month,year,idStorage)
        // console.log(month,year,' month + year', typeof month, typeof year, idStorage)
        await Axios.post(`https://us-central1-millea-lab.cloudfunctions.net/web/api/getTeacherReports`,{
            "owner_uid": idStorage,
            "month": month,
            "year": year
        },{
            validateStatus: false
        }).then((res)=>{
            // console.log(res.data)
            let allDataTeacherReport = []
            if(res.data){
                if(!res.data.message){
                    allDataTeacherReport =  [{
                         name:'STANDPOINT',
                         img:require('../assets/overview/icon_standpoint.svg'),
                         value:res.data.standpoints,
                         lastUpdate:'Sejak bulan lalu',
                         percentage:`${res.data.standpointsGrowthPercentage !== 'NaN' ? res.data.standpointsGrowthPercentage : 0} %`
                     },
                      {
                         name:'OBJECT',
                         img:require('../assets/overview/icon_object.svg'),
                         value:res.data.objects,
                         lastUpdate:'Sejak bulan lalu',
                         percentage:`${res.data.objectsGrowthPercentage !== 'NaN' ? res.data.objectsGrowthPercentage : 0} %`
                     },
                      {
                         name:'VIDEO',
                         img:require('../assets/overview/icon_video.svg'),
                         value:secondToMinutesAndSeconds(res.data.videos),
                         lastUpdate:'Sejak bulan lalu',
                         percentage: `${res.data.videosGrowthPercentage !== 'NaN'  ?  res.data.videosGrowthPercentage : 0 } %`
                     }
                     ,
                      {
                         name:'VIDEO 360',
                         img:require('../assets/overview/icon_360.svg'),
                         value:`${secondToMinutesAndSeconds(res.data.video360s)}`,
                         lastUpdate:'Sejak bulan lalu',
                         percentage:`${res.data.video360sGrowthPercentage !== 'NaN' ? res.data.video360sGrowthPercentage : 0} %`
                     }]
                }else {
                    allDataTeacherReport =  [{
                        name:'STANDPOINT',
                        img:require('../assets/overview/icon_standpoint.svg'),
                        value:'0',
                        lastUpdate:'Sejak bulan lalu',
                        percentage:'0%'
                    },
                     {
                        name:'OBJECT',
                        img:require('../assets/overview/icon_object.svg'),
                        value:'0',
                        lastUpdate:'Sejak bulan lalu',
                        percentage:'0%'
                    },
                     {
                        name:'VIDEO',
                        img:require('../assets/overview/icon_video.svg'),
                        value:'0',
                        lastUpdate:'Sejak bulan lalu',
                        percentage:'0%'
                    }
                    ,
                     {
                        name:'VIDEO 360',
                        img:require('../assets/overview/icon_360.svg'),
                        value:'0',
                        lastUpdate:'Sejak bulan lalu',
                        percentage:'0%'
                    }]
                }
            }
            commit('setValueTeacherReport',{name:'allTeacherReport',value:allDataTeacherReport})
        }).catch((err)=>{
            console.log(err.message)
            let allDataTeacherReport = []
            allDataTeacherReport =  [{
                name:'STANDPOINT',
                img:require('../assets/overview/icon_standpoint.svg'),
                value:'0',
                lastUpdate:'Sejak bulan lalu',
                percentage:'0%'
            },
             {
                name:'OBJECT',
                img:require('../assets/overview/icon_object.svg'),
                value:'0',
                lastUpdate:'Sejak bulan lalu',
                percentage:'0%'
            },
             {
                name:'VIDEO',
                img:require('../assets/overview/icon_video.svg'),
                value:'0',
                lastUpdate:'Sejak bulan lalu',
                percentage:'0%'
            }
            ,
             {
                name:'VIDEO 360',
                img:require('../assets/overview/icon_360.svg'),
                value:'0',
                lastUpdate:'Sejak bulan lalu',
                percentage:'0%'
            }]
            commit('setValueTeacherReport',{name:'allTeacherReport',value:allDataTeacherReport})
        })
    }
}

const getters = {
    allProduct:(state)=>{
        return state.allProduct
    },

    allProducts:(state)=> state.allProducts,

    getAllStandPointTable:(state)=>{
        return state.interactionStandPoint
    },
    getIsLoading:(state)=>{
        return state.isLoading
    },
    getIsLogin:(state)=>{
        return state.isLogin
    },
    getCustomerDetail:(state)=>{
        return state.user
    },
    getAllOverview:(state)=>{
        return state.allDataFromOverview
    },
    getTopStudent:(state)=>{
        return state.allTopStudent
    },
    getTeacherReport:(state)=>{
        return state.allTeacherReport
    },
    getValueUidCustomer:(state)=>{
        return state.uidCustomer
    }

    
}



const namespaced = false 

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced,
    
}