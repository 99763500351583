import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import RegisterView from '../views/RegisterView.vue'
import ResetView from '../views/ResetView.vue'
import OverView from '../views/Overview.vue'
import AnalyticsInteraction from '../views/Analytics/AnalyticsInteraction.vue'
import AnalyticsQuizAct from '../views/Analytics/AnalyticsQuizAct.vue'
import Assesment from '../views/AssessmentView.vue'
import Profile from '../views/ProfileView.vue'
import store from '@/store/index'
import { getAuth, onAuthStateChanged} from 'firebase/auth'

const checkUserLogin=async(to,from,next)=>{
  // console.log(to,from,next)
  let idStorage = localStorage.getItem('idStorage')
  // console.log(idStorage,'id storage')
  await store.dispatch('getValueLoading',{value:true}) // start loading page 
  // console.log(to)

  const auth = getAuth()
  onAuthStateChanged(auth,async(user)=>{
    if(user){ // kalo ini ada berarti user udh login
      // console.log(user)
      if(to.path === '/'){
        // console.log('berhasil login')
        
        let getAllOverview = await store.getters.getAllOverview
        let getTopStudent = await store.getters.getTopStudent
        let getTeacherReport = await store.getters.getTeacherReport
        let getCustomerDetail = await store.getters.getCustomerDetail
        if(!getCustomerDetail){ 
            await store.dispatch('getValueLogin') // fetching data customer
            getCustomerDetail = await store.getters.getCustomerDetail
        }
        if(!getTeacherReport){
           await store.dispatch('getValueTeacherReport',idStorage)
           getTeacherReport = await store.getters.getTeacherReport
        }
        if(!getTopStudent){
          // 
          await store.dispatch('getAllValueTopStudent',idStorage) 
          getTopStudent =  await store.getters.getTopStudent
        }
  
        if(!getAllOverview){
          // console.log('masuk ke if.  fetch api lagi')
          await store.dispatch('getValueOverview',idStorage) // fetching overview page data 
          getAllOverview = await store.getters.getAllOverview
        }
      
      }else if (to.path === '/profile'){
        
        await store.dispatch('getValueLogin') // fetching data customer
      }else if (to.path === '/assessment'){
        await store.dispatch('getValueLogin') // fetching data customer
        let allOverview = await store.getters.getAllOverview
        if(allOverview === undefined){
          await store.dispatch('getValueOverview',idStorage) // fetching overview page data 
        }
      }else if (to.path === '/analytics-interaction'){
        let allOverview = await store.getters.getAllOverview
        if(allOverview === undefined){
          await store.dispatch('getValueOverview',idStorage) // fetching overview page data 
        }
        await store.dispatch('getValueLogin') // fetching data customer
      }else if (to.path === '/analytics-quiz-act'){
        await store.dispatch('getValueLogin') // fetching data customer
        let allOverview = await store.getters.getAllOverview
        if(allOverview === undefined){
          await store.dispatch('getValueOverview',idStorage) // fetching overview page data 
        }
      }
      next()
      await store.dispatch('getValueLoading',{value:false}) // stop loading page
    }else {
      localStorage.removeItem('idStorage')
      await store.dispatch('getValueLoading',{value:false}) // stop loading
      await store.dispatch('getValueIsLogin',{value:false}) 
      if(to.path === '/assessment'){
        window.location.href="/"
      }else if (to.path === '/analytics-interaction'){
        window.location.href="/"
      }else if (to.path === '/analytics-quiz-act'){
        window.location.href="/"
      }else if (to.path === '/profile'){
        window.location.href="/"
      }
      next()
      // console.log('else, blm login')
    }
  })
}
const routes = [
  {
    path: '/',
    name: 'overview',
    component: OverView,
    beforeEnter(to,from,next){
      checkUserLogin(to,from,next)
    }
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView,
    beforeEnter(to,from,next){
      checkUserLogin(to,from,next)
    }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    beforeEnter(to,from,next){
      checkUserLogin(to,from,next)
    }
  },
  {
    path: '/reset',
    name: 'reset',
    component: ResetView,
    beforeEnter(to,from,next){
      checkUserLogin(to,from,next)
    }
  },
  {
    path: '/assessment',
    name: 'assessment',
    component: Assesment,
    beforeEnter(to,from,next){
      checkUserLogin(to,from,next)
    }
  },
  {
    path: '/analytics-interaction',
    name: 'analyticsInteraction',
    component: AnalyticsInteraction,
    beforeEnter(to,from,next){
      checkUserLogin(to,from,next)
    }
  },
  {
    path: '/analytics-quiz-act',
    name: 'analyticsQuizAct',
    component: AnalyticsQuizAct,
    beforeEnter(to,from,next){
      checkUserLogin(to,from,next)
    }
  },

  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    beforeEnter(to,from,next){
      checkUserLogin(to,from,next)
    }
  },
  {
    
    path: '/about',
    name: 'about',
    beforeEnter(to,from,next){
      checkUserLogin(to,from,next)
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

export const router = createRouter({
  history: createWebHistory(),
  routes
})


