// import Vue from 'vue'
// import Vuex from 'vuex'
import Auth from './auth'
import { createStore } from 'vuex'

// Vue.use(Vuex)
export default createStore({
  modules:{
    Auth
  }
})

// export default new Vuex.Store({
//   modules:{
//     // Auth
//   }
// })



// export default createStore({
//   state: {
//     count:0
//   },
//   getters: {
//     getCounter: state => {
// 			return state.count
// 		}
//   },
//   mutations: {
//     increment (state) {
// 			state.count++	
// 		},
// 		decrement (state) {
// 			if(state.count > 0) {
// 				state.count--
// 			}
// 		}
//   },
//   actions: {
    

//   },
//   modules: {
//   }
// })
