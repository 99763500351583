<template>
    <div class="container-login">
        <div class="box-login">
            <div class="login-logo">
                <img src="../assets/Header/millealogoside.svg" alt="">
            </div>
            <div class="login-header">
                <h1>Login</h1>
            </div>
            <div class="login-detail-box">
                <div class="column-100">
                    <p>Email</p>
                    <div class="box-input-login" :class="[(isEmailCorrect === true ? 'correct-input' : customerEmail === '' ? '' :  'wrong-input' )]">
                        <input type="text" class="input-100" v-model="customerEmail" @change="onCustEmail">
                        <span v-if="isEmailCorrect === true">
                            <i class="fa-solid fa-circle-check"></i>
                        </span>
                    </div>
                    <Transition>
                        <p class="error-notification" v-if="isEmailCorrect === false && customerEmail !== ''">Contoh:email@millealab.com</p>
                    </Transition>
                </div>
                <div class="column-100">
                    <p>Password</p>
                    <div class="box-input-login" :class="[(isPasswordCorrect === true ? 'correct-input' : customerPassword === '' ? '' :  'wrong-input' )]">
                        <input :type="typePassword" class="input-100" v-model="customerPassword" @change="onCustPassword">
                        <span v-if="isSeen === true" v-on:click="onChangeSeen" >
                            <i class="fa-solid fa-eye icon-eyes"></i>
                        </span>
                        <span v-else v-on:click="onChangeSeen">
                            <i class="fa-solid fa-eye-slash icon-eyes"></i>
                        </span>
                    </div>
                    <Transition>
                        <p class="error-notification" v-if="isPasswordCorrect === false && customerPassword !== ''">Password salah</p>
                    </Transition>
                </div>
                <div class="login-to-forgot">
                    <a href="">
                        <p>Forgot password?</p>
                    </a>
                </div>
            </div>
            <div class="btn-login-box">
                <div class="btn-login" v-if="allDataIsReady" @click="onLogIn">
                    <p>LOGIN</p>
                </div>
                <div class="btn-failed" v-else>
                    <p>LOGIN</p>
                </div>
            </div>
            <div class="box-login-with">
                <div class="login-with">
                    <p>Or login with</p>
                </div>
            </div>
            <div class="box-other-login">
                <div class="other-login">
                    <div class="btn-other-login" @click="loginWithGoogle">
                        <img src="../assets/google.svg" alt="">
                        <!-- <p>Sign in with google</p> -->
                    </div>
                </div>
                <div class="login-to-register">
                    <p>Don't have an account yet?</p> <a href="/register">Register</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {ref} from 'vue'
import {signInWithPopup,signInWithEmailAndPassword ,getAuth} from 'firebase/auth';
import {auth,provider} from '../../firebase-config'
import {useLoading} from 'vue-loading-overlay'
import {doc,setDoc,getDoc,Timestamp} from 'firebase/firestore'
import {db} from '../../firebase-config'
import {useStore} from 'vuex'
import { createToast } from 'mosha-vue-toastify';




export default {
  name: 'LoginView',
  components: {
  },
  setup(){
    // const store = useStore()
    const $loading = useLoading()
    const store = useStore()
    let isSeen = ref(false)
    let customerEmail = ref('')
    let isEmailCorrect = ref(false)
    let typePassword = ref('password')
    let customerPassword = ref('')
    let isPasswordCorrect = ref(false)
    let allDataIsReady = ref(false)

    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    function validatePassword(password) {
        const re = /^(?=.{7,}$)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?\W).*$/
        return re.test(String(password))
    }

    const onChangeSeen =()=>{
      isSeen.value = !isSeen.value
      if (typePassword.value === 'text'){
        isSeen.value = true
        typePassword.value = 'password'
      }else {
        isSeen.value = false
        typePassword.value = 'text' 
      }        
    }
    const checkAllData=()=>{
        if(isEmailCorrect.value && isPasswordCorrect.value){
          allDataIsReady.value = true
        }else {
          allDataIsReady.value = false
        }
        console.log(isEmailCorrect.value,isPasswordCorrect.value)
    }
     const onCustEmail=()=>{
        var checking_email = validateEmail(customerEmail.value)
            if(checking_email){
                isEmailCorrect.value = true
                checkAllData()
            }else {
                isEmailCorrect.value = false
            }
    }
    const onCustPassword=()=>{
        var checking_password = validatePassword(customerPassword.value)
            if(checking_password){
                console.log(checking_password)
                isPasswordCorrect.value = true
                checkAllData()
            }else {
                isPasswordCorrect.value = false
        }
    }
    const onLogIn=async()=>{
        console.log(customerEmail.value,customerPassword.value)
        if(isEmailCorrect.value){
            const loader = $loading.show({
            // Optional parameters
            });
            const auth = getAuth()
            await signInWithEmailAndPassword(auth,customerEmail.value,customerPassword.value)
            .then(async(result)=>{
                store.dispatch('getValueLoginWithGoogle',result.user.uid)
                JSON.stringify(localStorage.setItem('idStorage',result.user.uid))
                window.location.href = '/';
                loader.hide()
            }).catch((err)=>{
                console.log(err)
                  let split_cust_email = customerEmail.value.split('@')
                    console.log(split_cust_email)
                    if(split_cust_email[1] === 'gmail.com'){ // makesure email is gmail
                    
                        if(err.message === 'Firebase: Error (auth/user-not-found).'){
                            console.log('masuk ke if 173')
                             createToast({
                                title: 'Error',
                                description: 'Email unregistered, Login with google'
                                },
                                {
                                // timeout: 3000,
                                type: 'danger',
                                transition: 'bounce',
                                position:'top-center'
                            })
                        }else if (err.message === 'Firebase: Error (auth/wrong-password).'){
                            console.log('masuk ke if 185')
                             createToast({
                                title: 'Error',
                                description: 'Wrong Password,Login with google'
                                },
                                {
                                // timeout: 3000,
                                type: 'danger',
                                transition: 'bounce',
                                position:'top-center'
                            })
                        }else if (err.message === 'EMAIL_NOT_FOUND'){
                            createToast({
                                title: 'Error',
                                description: 'Email Not Found, please Register'
                                },
                                {
                                // timeout: 3000,
                                type: 'danger',
                                transition: 'bounce',
                                position:'top-center'
                            })
                        }
                    }else if(split_cust_email[1] !== 'gmail.com') { // email isnt gmail
                        if(err.message === 'Firebase: Error (auth/wrong-password).'){
                            console.log('masuk ke if 199')
                            createToast({
                                title: 'Error',
                                description: 'Wrong Password,Check your password'
                                },
                                {
                                // timeout: 3000,
                                type: 'danger',
                                transition: 'bounce',
                                position:'top-center'
                            })
                        }
                    }else if (err.message === 'EMAIL_NOT_FOUND'){
                            createToast({
                                title: 'Error',
                                description: 'Email Not Found, please Register'
                                },
                                {
                                // timeout: 3000,
                                type: 'danger',
                                transition: 'bounce',
                                position:'top-center'
                            })
                    }else {
                        createToast({
                            title: 'Error',
                            description: 'Something\'s Wrong,Please Contact Our Team'
                            },
                            {
                            // timeout: 3000,
                            type: 'danger',
                            transition: 'bounce',
                            position:'top-center'
                        })
                    }
                    loader.hide()
            })

        }else {
            checkAllData()
        }
    }

    const loginWithGoogle=async()=>{
        // const googleUser = await  $gAuth.signIn()
        // console.log('google user', googleUser)

       
        const loader = $loading.show({
            // Optional parameters
        });
        try
        {
            // sign in google with pop up
            let result = await signInWithPopup(auth, provider);
            console.log(result,' sign in with popup')
            let user = result.user;
            console.log(user.uid)
            console.log(user)

            // define user data
            let collectionID = 'users';
            let docRef = doc(db, collectionID, user.uid);
            console.log(docRef, 'docRef')

            // get user data
            let userDocSnap = await getDoc(docRef);
            console.log(userDocSnap, 'docSnap')
            console.log(userDocSnap.data())

            // if user does not exists in firestore, create default new user
            if(!userDocSnap.exists())
            {
                await setDoc(docRef, {
                    id: user.uid,
                    name: user.displayName,
                    email: user.email,
                    phone: user.phoneNumber,
                    created_at: Timestamp.fromDate(new Date(user.metadata.creationTime)),
                    last_signed_in: Timestamp.fromDate(new Date(user.metadata.lastSignInTime)),
                    is_admin: false,
                    profile_picture:user.photoURL,
                    userDetails: []
                }, { merge: true });
                loader.hide()
            }
            // else, update the last signed in data
            else
            {
                let userDetails = []
                // let profile_number = 0
                if(userDocSnap.data().userDetails){
                    userDetails = userDocSnap.data().userDetails
                }
                // if(userDocSnap.data().profileNumber === undefined){
                //     profile_number = 2
                // }else {
                //     profile_number = userDocSnap.data().profile_number
                // }
                await setDoc(docRef, {
                    last_signed_in: Timestamp.fromDate(new Date(user.metadata.lastSignInTime)),
                    userDetails:userDetails,
                    // profile_number : profile_number
                }, { merge: true });
                loader.hide()
            }
            store.dispatch('getValueLoginWithGoogle',user.uid)
            JSON.stringify(localStorage.setItem('idStorage',user.uid))
            window.location.href = '/';
            loader.hide()
        }
        catch(err)
        {
            console.log(err);
            console.log(err.message)
            loader.hide()
        } 
    }
    function getWidth() {
            return Math.max(
                document.body.scrollWidth,
                document.documentElement.scrollWidth,
                document.body.offsetWidth,
                document.documentElement.offsetWidth,
                document.documentElement.clientWidth
            );
        }

        function getHeight() {
            return Math.max(
                document.body.scrollHeight,
                document.documentElement.scrollHeight,
                document.body.offsetHeight,
                document.documentElement.offsetHeight,
                document.documentElement.clientHeight
            );
        }

        console.log('Width:  ' +  getWidth() );
        console.log('Height: ' + getHeight() );
    return{
        isSeen,
        customerEmail,
        isEmailCorrect,
        typePassword,
        customerPassword,
        isPasswordCorrect,
        allDataIsReady,
        validateEmail,
        validatePassword,
        onChangeSeen,
        checkAllData,
        onCustEmail,
        onCustPassword,
        onLogIn,
        loginWithGoogle,
        signInWithPopup,
        getWidth,
        getHeight
    }
  }
}
</script>

<style lang="scss">
    @import '../scss/Login.scss';
    @import '../scss/Mobile/Login.scss';
</style>
