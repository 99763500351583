<template>
  <!-- MODALS ADD A Download Invoice -->
  <div
    class="modal modal-downlad-invoice fade"
    id="modalDownloadInvoice"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    @click.self="closeModal()"
  >
    <div class="modal-dialog modal-dialog-centered .modal-lg">
      <div class="modal-content modal-content-download">
        <div class="modal-header">
          <div class="header-left">
            <p>Assessment Report</p>
            <p>Class: {{ dataDownloadAssessment.class }}</p>
            <p>Name: {{ dataDownloadAssessment.name }}</p>
          </div>
          <div class="header-right">
            <img src="../assets/new_logo_millealab.png" alt="" />
          </div>
        </div>
        <div class="modal-body">
          <p>Subject: {{ dataDownloadAssessment.subject }}</p>
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>Weight</th>
                <th>Answer Key</th>
                <th>Chosen Answer</th>
                <th>Score</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, id) in dataDownloadAssessment.correct"
                :key="id"
                @load="log(item)"
                class="tr_answer"
              >
                <td>{{ id + 1 }}</td>
                <td>{{ item[0].weight }}</td>
                <!-- ANSWER KEY -->
                <td v-if="item.length > 1" >
                  <div className="answer_class">
                    <p v-for="(data,index) in item" :key="index">
                      {{data.answer_key}},
                    </p>
                  </div>
                </td>
                <td v-else>
                  <div className="answer_class">
                    <p>{{item[0].answer_key}}</p>
                  </div>
                </td>
                <!-- ANSWER KEY -->
                <!-- pilihan jawaban -->
                <td v-if="item.length > 1" >
                  <div className="answer_class">
                    <p v-for="(data,index) in item" :key="index">
                      {{data.chosen_answer}},
                    </p>
                  </div>
                </td>
                <td v-else>
                  <div className="answer_class">
                    <p>{{item[0].chosen_answer}}</p>
                  </div>
                </td>
                <!-- Pilihan Jawaban -->
                <!-- HASIL NILAI -->
                <td v-if="item[0].answer_key === item[0].chosen_answer">
                  {{ item[0].weight }}
                </td>
                <td v-else>0</td>
              </tr>

              <tr class="td_total_score">
                <td colspan="4">Total Score</td>
                <td>{{ dataDownloadAssessment.total_score }}</td>
              </tr>
              <tr class="td_kkm">
                <td colspan="4">Minimum Mastery Criterion (KKM)</td>
                <td>{{ dataDownloadAssessment.minimum_kkm }}</td>
              </tr>
              <tr class="td_grade">
                <td colspan="4">Grade</td>
                <td>{{ dataDownloadAssessment.grade }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <!-- MODALS ADD A Download Invoice -->

  <div
    class="modal-backdrop fade show"
    id="backdrop"
    style="display: none"
  ></div>

  <div class="container-assesment">
    <SideHeader />
    <div class="container-right-assesment">
      <TopHeaderLogin />
      <div class="main-assesment">
        <BreadCrumbHeader
          :pathPage="path"
          :optionMenu="optionMenu"
          :optionLeftName="optionLeftName"
          :optionRightName="optionRightName"
          :withbg="optionBG"
        />
        <div class="box-dropdown-assessment">
          <div class="box-input-reward">
            <Multiselect
              class="multiSelectClass"
              v-model="valueClass"
              :options="optionClass"
              placeholder="Class"
              deselect-label="Can't remove this value"
            />
            <Multiselect
              class="multiSelectClass"
              v-model="valueScene"
              :options="optionScene"
              placeholder="Scene"
              :hideSelected="true"
            />
          </div>
        </div>
        <div class="box-small-card-assessment">
          <smallCard
            v-for="item in dashboardResult"
            :key="item.id"
            :img="`${item.img}`"
            :name="item.name"
            :score="item.value"
            :lastUpdate="item.lastUpdate"
            :percentage="item.percentage"
          />
        </div>
        <div class="box-assesment-datatable">
          <h3>Assessment</h3>
          <!-- <p>This is an example of datatable using the well known datatables.net.plugin. This is a minimal setup in order to getstarted fast</p> -->

          <div class="box-assesment-input">
            <p>Daftar Siswa</p>
            <div class="box-right-assessment">
              <div class="assesment-input-search">
                <p>Search :</p>
                <input
                  class="search-list"
                  placeholder="Search"
                  type="text"
                  v-model="search_student"
                  @change="(e) => onSearch(e.target.value)"
                />
              </div>
              <div class="box-new-sort">
                <img
                  src="../assets/sort.svg"
                  alt=""
                  id="sort_active_img"
                  @click="open_sort_standpoint('sort_sp')"
                  v-if="!isSortActiveAssessment"
                />
                <!-- isSortActive.value = true -->
                <img
                  src="../assets/sort.svg"
                  alt=""
                  id="sort_active_img"
                  @click="close_sort_standpoint('sort_sp')"
                  v-else
                />
                <ul class="options-sort" id="sort_sp">
                  <li
                    class="sort_sp_class"
                    v-for="(items, id) in optionAssessmentSort"
                    :key="id"
                    @click="change_sort_standpoint(items.id, 'sort_sp')"
                  >
                    <img
                      src="../assets/active_icon_dot.svg"
                      alt=""
                      v-if="assessmentSortActive === items.id"
                    />
                    <img
                      src="../assets/active_icon_dot_white.svg"
                      alt=""
                      v-else
                    />
                    <p>{{ items.value }}</p>
                    <img :src="items.img" alt="" class="icon_arrow" />
                  </li>
                </ul>
              </div>
              <div class="btn-export-all" @click="export_to_csv('assessment')">
                <img src="../assets/icon_download_new.svg" alt="" />
                <!-- <p>Export All</p> -->
              </div>
            </div>
          </div>

          <div class="box-assesment-table">
            <table
              class="assesment-table-detail"
              v-if="dataAssessment.length > 0 && isLoadingAssessment === false"
              id="assessment_id_table"
            >
              <tr>
                <th>
                  <div class="box-item-detail">
                    <p>Nomor</p>
                  </div>
                </th>
                <th>
                  <div class="box-item-detail">
                    <p>Nama</p>
                  </div>
                </th>
                <th>
                  <div class="box-item-detail">
                    <p>KKM</p>
                  </div>
                </th>
                <th>
                  <div class="box-item-detail">
                    <p>Score</p>
                  </div>
                </th>
                <th>
                  <div class="box-item-detail">
                    <p>Grade</p>
                  </div>
                </th>
                <th>
                  <div class="box-item-detail">
                    <p>Download</p>
                    <!-- <img src="../assets/AssesmentAssets/sort.svg" alt=""> -->
                  </div>
                  <!-- <div type="button" v-if="isLoadingDownload === true" class="btn-download-history" @click="changeIdActive(dataRef.external_id)" >
                                    <div class="spinner-border text-primary" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div> -->
                </th>
              </tr>
              <tr
                v-for="(item, id) in dataAssessment"
                :key="id"
                @load="log(item)"
              >
                <td v-if="id >= start_pagination && id < end_pagination">
                  <p
                    v-if="
                      (assessmentSortActive === 'sp_nomor_asc' &&
                        isOnSearchActive === false) ||
                      (id >= start_pagination &&
                        id < end_pagination &&
                        assessmentSortActive === 'sp_nomor_desc' &&
                        isOnSearchActive === false)
                    "
                  >
                    {{ item.id }}
                  </p>
                  <p
                    v-else-if="
                      (assessmentSortActive === 'sp_nomor_asc' &&
                        isOnSearchActive === true) ||
                      (id >= start_pagination &&
                        id < end_pagination &&
                        assessmentSortActive === 'sp_nomor_desc' &&
                        isOnSearchActive === true)
                    "
                  >
                    {{ id + 1 }}
                  </p>
                  <p v-else>{{ id + 1 }}.</p>
                </td>
                <td v-if="id >= start_pagination && id < end_pagination">
                  {{ item.oldData.name }}
                </td>
                <td v-if="id >= start_pagination && id < end_pagination">
                  {{ item.oldData.report_list[0].passing_grade }}
                </td>
                <td v-if="id >= start_pagination && id < end_pagination">
                  {{
                    item.oldData.report_list[0].score !== -1
                      ? item.oldData.report_list[0].score
                      : "-"
                  }}
                </td>
                <td v-if="id >= start_pagination && id < end_pagination">
                  {{
                    item.oldData.report_list[0].grade !== "Z"
                      ? item.oldData.report_list[0].grade
                      : "-"
                  }}
                </td>
                <td v-if="id >= start_pagination && id < end_pagination">
                  <!-- <div type="button" v-if="isLoadingDownload === true" class="btn-download-history" @click="changeIdActive(dataRef.external_id)" >
                                    <div class="spinner-border text-primary" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>  -->
                  <div
                    class="btn-download"
                    @click="open_modal(id)"
                    v-if="
                      item.oldData.report_list[0].key_answers.length > 0 &&
                      item.oldData.report_list[0].viewer_answers.length > 0 &&
                      item.oldData.report_list[0].score_answers.length > 0
                    "
                  >
                    Download
                  </div>
                  <div v-else class="btn-download-disabled">Download</div>
                </td>
              </tr>
            </table>
            <div class="empty-card" v-else-if="isLoadingAssessment === true">
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div class="empty-card" v-else>
              <p>No data to display</p>
              <p>
                Try adjusting your search of filter to find what your're looking
                for
              </p>
            </div>
          </div>
          <div class="box-pagination" v-if="dataAssessment.length > 0">
            <p>
              Showing
              <span>
                {{ start_pagination + 1 }}
              </span>
              to
              <span>
                {{ end_pagination }}
              </span>
              of
              <span>
                {{ total_item_table }}
              </span>
              entries
            </p>
            <paginate
              v-model="page_paginate"
              :page-count="total_page_table"
              :page-range="3"
              :margin-pages="2"
              :click-handler="clickCallback"
              :prev-text="'<'"
              :next-text="'>'"
              :container-class="'pagination'"
              :page-class="'page-item'"
            >
            </paginate>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import SideHeader from "@/components/Header/SideHeader.vue";
import BreadCrumbHeader from "@/components/Header/BreadCrumbHeader.vue";
import TopHeaderLogin from "@/components/Header/TopHeaderLogin.vue";
import { ref, onBeforeMount, watch } from "vue";
import Multiselect from "@vueform/multiselect";
import smallCard from "../components/Card/smallCard.vue";
import Paginate from "vuejs-paginate-next";
import { useStore } from "vuex";
import Axios from "axios";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
export default {
  name: "AssessmentView",
  components: {
    SideHeader,
    BreadCrumbHeader,
    TopHeaderLogin,
    Multiselect,
    smallCard,
    Paginate,
    // FooterOverview
  },
  setup() {
    const store = useStore();
    let isLoadingAssessment = ref(true);
    let path = ref("Assessment");
    let isOnSearchActive = ref(false);
    let isLoadingDownload = ref(false);
    let classList = ref([
      {
        kelas: "Virtual Kelas A",
      },
      {
        kelas: "Virtual Kelas B",
      },
    ]);
    let entry = ref("10");
    let search_student = ref("");
    let optionMenu = ref(true);
    let optionLeftName = ref("New");
    let optionRightName = ref("Filters");
    let optionBG = ref(false);
    let valueClass = ref("Nama Siswa");
    let valueScene = ref("Scene");
    let optionClass = ref();
    let optionScene = ref();

    let uidClassActive = ref("");
    let classNameActive = ref("");
    let uidSceneActive = ref("");
    let classActive = ref("Class");
    let sort_by_active = ref("Sort By");
    let start_pagination = ref(0);
    let end_pagination = ref(5);
    let total_page_table = ref(2);
    let total_item_table = ref(10);
    let allDataOverview = ref();
    let defaultDataAssessment = ref([]);
    let dataAssessment = ref([]);

    // let a = ['a','b']
    // let b = [true,true,false,false,true]

    // console.log(b.sort())
    // let c = JSON.stringify([true,false])
    // let d = JSON.stringify([true,false])

    // console.log(JSON.stringify(c) === JSON.stringify(d) )

    let dataDownloadAssessment = ref({
      wrong: [],
      correct: [],
      answer: [
        {
          weight: 50,
          answer_key: "1",
          chosen_answer: "1",
          score: 50,
        },
        {
          weight: 50,
          answer_key: "3",
          chosen_answer: "3",
          score: 50,
        },
        {
          weight: 50,
          answer_key: "3",
          chosen_answer: "3",
          score: 50,
        },
        {
          weight: 50,
          answer_key: "3",
          chosen_answer: "3",
          score: 50,
        },
      ],
      name: "Bayu Darmawan",
      subject: "seni karawaitan",
      class: "Karawitan",
      total_score: 100,
      minimum_kkm: 70,
      grade: "A",
    });

    // SORT
    let optionAssessmentSort = ref([
      {
        value: "Nomor",
        img: require("../assets/InteractionAssets/down_arrow.svg"),
        id: "sp_nomor_asc",
      },
      {
        value: "Nomor",
        img: require("../assets/InteractionAssets/gray_top_arrow.svg"),
        id: "sp_nomor_desc",
      },
      {
        value: "Nama",

        img: require("../assets/InteractionAssets/down_arrow.svg"),
        id: "sp_nama_asc",
      },
      {
        value: "Nama",
        img: require("../assets/InteractionAssets/gray_top_arrow.svg"),
        id: "sp_nama_desc",
      },
      {
        value: "Score",
        img: require("../assets/InteractionAssets/down_arrow.svg"),

        id: "sp_score_asc",
      },
      {
        value: "Score",
        img: require("../assets/InteractionAssets/gray_top_arrow.svg"),
        id: "sp_score_desc",
      },
      {
        value: "Grade",
        img: require("../assets/InteractionAssets/down_arrow.svg"),

        id: "sp_grade_asc",
      },
      {
        value: "Grade",
        img: require("../assets/InteractionAssets/gray_top_arrow.svg"),
        id: "sp_grade_desc",
      },
    ]);
    let assessmentSortActive = ref("sp_nomor_asc"); // sort yang lagi active
    let isSortActiveAssessment = ref(false);
    const open_sort_standpoint = (params) => {
      if (params === "sort_sp") {
        isSortActiveAssessment.value = true;
      }
      document.getElementById(params).style.display = "block";
    };
    const close_sort_standpoint = (params) => {
      if (params === "sort_sp") {
        isSortActiveAssessment.value = false;
      }
      document.getElementById(`${params}`).style.display = "none";
    };
    const change_sort_standpoint = (sort_parent, sort_child) => {
      if (sort_child === "sort_sp") {
        assessmentSortActive.value = sort_parent;
        sortFuncStandpoint(dataAssessment.value);
      }

      close_sort_standpoint(sort_child);
      // document.getElementsByClassName('sort_sp_class').classList.remove('sort_active_id')
      // document.getElementById(id).addClasslist('sort_active_id')
    };
    const sortFuncStandpoint = (getAllStandPointTable) => {
      // console.log(getAllStandPointTable,'valu 2179')
      if (assessmentSortActive.value === "sp_nomor_asc") {
        getAllStandPointTable.sort(function (a, b) {
          return a.id - b.id;
        });
      } else if (assessmentSortActive.value === "sp_nomor_desc") {
        getAllStandPointTable.sort(function (a, b) {
          return b.id - a.id;
        });
      } else if (assessmentSortActive.value === "sp_nama_asc") {
        getAllStandPointTable.sort(function (a, b) {
          return a.oldData.name.localeCompare(b.oldData.name);
        });
      } else if (assessmentSortActive.value === "sp_nama_desc") {
        getAllStandPointTable.sort(function (a, b) {
          return b.oldData.name.localeCompare(a.oldData.name);
        });
      } else if (assessmentSortActive.value === "sp_score_asc") {
        getAllStandPointTable.sort(function (a, b) {
          if (a.oldData.report_list[0].score === "-") {
            a.oldData.report_list[0].score = -1;
          }
          if (b.oldData.report_list[0].score === "-") {
            b.oldData.report_list[0].score = -1;
          }
          return (
            a.oldData.report_list[0].score - b.oldData.report_list[0].score
          );
        });
      } else if (assessmentSortActive.value === "sp_score_desc") {
        getAllStandPointTable.sort(function (a, b) {
          if (a.oldData.report_list[0].score === "-") {
            a.oldData.report_list[0].score = -1;
          }
          if (b.oldData.report_list[0].score === "-") {
            b.oldData.report_list[0].score = -1;
          }
          return (
            b.oldData.report_list[0].score - a.oldData.report_list[0].score
          );
        });
      } else if (assessmentSortActive.value === "sp_grade_asc") {
        getAllStandPointTable.sort(function (a, b) {
          if (a.oldData.report_list[0].grade === "-") {
            a.oldData.report_list[0].grade = "Z";
          }
          if (b.oldData.report_list[0].grade === "-") {
            b.oldData.report_list[0].grade = "Z";
          }
          return a.oldData.report_list[0].grade.localeCompare(
            b.oldData.report_list[0].grade
          );
        });
      } else if (assessmentSortActive.value === "sp_grade_desc") {
        getAllStandPointTable.sort(function (a, b) {
          if (a.oldData.report_list[0].grade === "-") {
            a.oldData.report_list[0].grade = "Z";
          }
          if (b.oldData.report_list[0].grade === "-") {
            b.oldData.report_list[0].grade = "Z";
          }
          return b.oldData.report_list[0].grade.localeCompare(
            a.oldData.report_list[0].grade
          );
        });
      }
      dataAssessment.value = getAllStandPointTable;
      defaultDataAssessment.value = getAllStandPointTable;
      return getAllStandPointTable;

      // set up standpoint chart
    };

    // SORT
    let dashboardResult = ref([
      {
        name: "AVERAGE SCORE",
        img: require("../assets/icon_average_score.svg"),
        value: "0",
        lastUpdate: "Sejak bulan lalu",
        percentage: `0 %`,
      },
      {
        name: "ACHIEVED STUDENTS",
        img: require("../assets/icon_achieved_student.svg"),
        value: "0",
        lastUpdate: "Sejak bulan lalu",
        percentage: `0 %`,
      },
      {
        name: "TOTAL STUDENTS",
        img: require("../assets/icon_total_students.svg"),
        value: "0",
        lastUpdate: "Sejak bulan lalu",
        percentage: `0 %`,
      },
    ]);
    let page_paginate = ref(1);
    const clickCallback = (pageNum) => {
      // console.log(pageNum)
      let new_end = pageNum * 5;
      let new_start = new_end - 5;

      start_pagination.value = new_start;
      end_pagination.value = new_end;
    };

    watch(
      async () => valueClass.value,
      function () {
        // console.log(valueClass.value)
        if (valueClass.value !== "Class" && valueClass.value !== null) {
          // console.log('masuk if 517')
          let filter = allDataOverview.value.filter((val) => {
            if (val.name === valueClass.value) {
              uidClassActive.value = val.id;
              classNameActive.value = val.name;
              return val;
            }
          });
          // console.log(filter)
          if (filter) {
            let allScene = [];
            filter[0].sceneList.forEach((val) => {
              allScene.push(val.name);
            });
            if (filter[0].sceneList.length > 0) {
              optionScene.value = allScene;
              valueScene.value = optionScene.value[0];
              uidSceneActive.value = filter[0].sceneList[0].id;
              // console.log('value scene jalan 409')
              fetching_with_scene();
              // console.log(filter[0].sceneList)
              // console.log(uidSceneActive.value,' uid scene active')
            } else {
              // get student data without scene

              valueScene.value = "Scene";
              optionScene.value = allScene;
              // valueScene.value = null
            }
          } else {
            dataAssessment.value = [];
            // console.log('masuk ke else')
          }
        } else {
          valueClass.value = "Class";
          valueScene.value = "Scene";
          uidClassActive.value = "";
          uidSceneActive.value = "";
          // optionClass.value = []
          optionScene.value = [];
          dataAssessment.value = [];
          dashboardResult.value[0].value = "0";
          dashboardResult.value[1].value = "0";
          dashboardResult.value[2].value = "0";
        }
      }
    );

    watch(
      async () => valueScene.value,
      function () {
        // console.log(valueScene.value,' ini value scene', valueScene.value !== 'Scene')
        if (valueScene.value !== "Scene" && valueScene.value !== null) {
          // console.log('masuk ke if 559')

          let filter = allDataOverview.value.filter((val) => {
            if (val.name === valueClass.value) {
              return val;
            }
          });
          // console.log(filter)
          if (filter.length > 0) {
            // eslint-disable-next-line no-unused-vars
            let scene_filter = filter[0].sceneList.filter((val) => {
              if (valueScene.value === val.name) {
                uidSceneActive.value = val.id;
              }
            });
            // console.log('value scene jalan 441')
            fetching_with_scene();
          }
        } else {
          // console.log('masuk ke else')
          // valueClass.value = 'Class'
          valueScene.value = "Scene";
          uidSceneActive.value = "";
          dataAssessment.value = [];
        }
      }
    );

    // FETCHING DATA ONLY WITH CLASSROOM UID & SCENE UID
    const fetching_with_scene = () => {
      // console.log(uidClassActive.value,' uid class active')
      // console.log(uidSceneActive.value , ' uid scene active')
      isLoadingAssessment.value = true;
      Axios.post(
        `https://us-central1-millea-lab.cloudfunctions.net/app/api/creatorGetViewerDataFromClassroomIDForAssessment`,
        {
          classroom_uid: uidClassActive.value,
          scene_uid: uidSceneActive.value,
        }
      )
        .then((res) => {
          let allAssessment = [];
          res.data.viewer_list.forEach((val, id) => {
            allAssessment.push({
              oldData: val,
              id: id + 1,
            });
          });

          dataAssessment.value = allAssessment;
          defaultDataAssessment.value = allAssessment;
          // console.log(dataAssessment.value);

          dashboardResult.value[0].value =
            res.data.average_score === null || isNaN(res.data.average_score)
              ? 0
              : `${res.data.average_score}`;
          dashboardResult.value[1].value = isNaN(res.data.achieved_students)
            ? 0
            : `${res.data.achieved_students}`;
          dashboardResult.value[2].value = isNaN(res.data.total_students)
            ? 0
            : `${res.data.total_students}`;
          // console.log(dashboardResult.value)
          total_page_table.value = Math.ceil(res.data.viewer_list.length / 5);
          total_item_table.value = res.data.viewer_list.length;
          isLoadingAssessment.value = false;
        })
        .catch((err) => {
          console.log(err.message);
        });
    };

    // FETCHING DATA ONLY WITH CLASSROOM UID & SCENE UID

    // DOWNLOAD CSV
    const export_to_csv = (params) => {
      // console.log(params)

      let arrayToPdf = [
        // 'Nomor,Durasi Interaksi,Pengulangan',
      ];
      var rows = document.querySelectorAll("#assessment_id_table tr");
      let row = [];
      rows[0].querySelectorAll("td,th").forEach((val) => {
        // console.log(val.innerText)
        row.push(val.innerText);
      });
      // console.log(row)
      arrayToPdf.push(row.join(","));
      // console.log(arrayToPdf)
      // console.log(rows[0].querySelectorAll('td,th'))
      // console.log(rows[0].innerText)
      // arrayToPdf.push(rows[0].innerText)
      if (params === "assessment") {
        // console.log(dataAssessment.value,' stand point table')
        dataAssessment.value.forEach((val, index) => {
          arrayToPdf.push(
            `${index + 1},${val.oldData.name},${
              val.oldData.report_list[0].passing_grade
            },${val.oldData.report_list[0].score},${
              val.oldData.report_list[0].grade
            }`
          );
        });
      }

      // Download CSV
      download_csv(arrayToPdf.join("\n"), params);
    };
    function download_csv(csv, filename) {
      var csvFile;
      var downloadLink;

      // CSV FILE
      csvFile = new Blob([csv], { type: "text/csv" });

      // Download link
      downloadLink = document.createElement("a");

      // File name
      downloadLink.download = filename;

      // We have to create a link to the file
      downloadLink.href = window.URL.createObjectURL(csvFile);

      // Make sure that the link is not displayed
      downloadLink.style.display = "none";

      // Add the link to your DOM
      document.body.appendChild(downloadLink);

      // Lanzamos
      downloadLink.click();
    }
    // DOWNLOAD CSV

    watch(
      async () => search_student.value,
      () => {
        if (search_student.value.length > 2) {
          onSearch(search_student.value);
        } else if (search_student.value === "") {
          onSearch(search_student.value);
        }
      }
    );

    const onSearch = (value) => {
      // console.log(value)

      if (value !== "") {
        isOnSearchActive.value = true;

        // console.log(value.toUpperCase())
        let filter = dataAssessment.value.filter((val, index) => {
          if (val.oldData.name.toUpperCase().includes(value.toUpperCase())) {
            console.log(val, index);
            return val;
          }
        });
        if (filter) {
          dataAssessment.value = filter;
          // total_page_table.value = Math.ceil((dataAssessment.value.length / 5))
          // total_item_table.value = dataAssessment.value.length
          // console.log(dataAssessment.value)
        } else {
          dataAssessment.value = defaultDataAssessment.value;
        }
        // console.log(total_page_table.value,total_item_table.value)

        // console.log(filter)
        page_paginate.value = 1;
        clickCallback(page_paginate.value);
      } else {
        isOnSearchActive.value = false;
        dataAssessment.value = defaultDataAssessment.value;
      }
      total_page_table.value = Math.ceil(dataAssessment.value.length / 5);
      total_item_table.value = dataAssessment.value.length;
    };
    const fetch = async () => {
      allDataOverview.value = await store.getters.getAllOverview;
      // console.log(dashboardResult.value)
      // console.log(allDataOverview.value)
      if (allDataOverview.value !== undefined) {
        if (allDataOverview.value.length > 0) {
          let allClassNameOverview = [];

          allDataOverview.value.forEach((val) => {
            allClassNameOverview.push(val.name);
          });
          optionClass.value = allClassNameOverview; // define all class name for dropdown class
          valueClass.value = allClassNameOverview[0]; // find active for fetching firstime class
          uidClassActive.value = allDataOverview.value[0].id;
          classNameActive.value = allDataOverview.value[0].name;
          if (allDataOverview.value[0].sceneList.length > 0) {
            // find first subject class
            // console.log(allDataOverview.value[0].sceneList)
            optionScene.value = allDataOverview.value[0].sceneList;
            valueScene.value = optionScene.value[0];
            uidSceneActive.value = optionScene.value[0].id;
          } else {
            // console.log(allDataOverview.value[0].sceneList)
            optionScene.value = allDataOverview.value[0].sceneList;
            valueScene.value = "Subject";
          }
        }
      }
      isLoadingAssessment.value = false;
    };
    onBeforeMount(() => {
      fetch();
    });

    const log = (params) => {
      console.log('log jalan')
      console.log(params, "log");
    };

    // DOWNLOAD PDF

    const open_modal = (id) => {
      console.log(id)
      // isLoadingDownload.value = true

      // eslint-disable-next-line no-unused-vars
      let new_data = {
        answer: [],
        correct: [],
        wrong: [],
        total_score: dataAssessment.value[id].oldData.report_list[0].score,
        minimum_kkm:dataAssessment.value[id].oldData.report_list[0].passing_grade,
        grade: dataAssessment.value[id].oldData.report_list[0].grade,
        subject: dataAssessment.value[id].oldData.report_list[0].scene_name,
        class: classNameActive.value,
        name: dataAssessment.value[id].oldData.name,
      };
      // console.log(dataAssessment.value[id].oldData.report_list[0])
      dataAssessment.value[id].oldData.report_list[0].key_answers.forEach(
        (val, index) => {
          if (val.length > 1) {
            let answer_key = JSON.stringify(val.split(",").sort());
            let student_answer = JSON.stringify(
              dataAssessment.value[id].oldData.report_list[0].viewer_answers[index].split(",").sort());
            if (answer_key === student_answer) { // jawaban berarti bener
              let numberToCharac = JSON.parse(answer_key);
              // console.log(numberToCharac)
              let final_answer = [];
              numberToCharac.map((val, index) => {
                if (val === "0") {
                  final_answer.push({
                  weight:dataAssessment.value[id].oldData.report_list[0].score_answers[index],
                  answer_key:'A',
                  chosen_answer:'A',
                  score: dataAssessment.value[id].oldData.report_list[0].score,
                });
                  // final_answer.push("A");
                } else if (val === "1") {
                  final_answer.push({
                  weight:dataAssessment.value[id].oldData.report_list[0].score_answers[index],
                  answer_key:'B',
                  chosen_answer:'B',
                  score: dataAssessment.value[id].oldData.report_list[0].score,
                });
                  // final_answer.push("B");
                } else if (val === "2") {
                  final_answer.push({
                  weight:dataAssessment.value[id].oldData.report_list[0].score_answers[index],
                  answer_key:'C',
                  chosen_answer:'C',
                  score: dataAssessment.value[id].oldData.report_list[0].score,
                });
                } else if (val === "3") {
                  final_answer.push({
                  weight:dataAssessment.value[id].oldData.report_list[0].score_answers[index],
                  answer_key:'D',
                  chosen_answer:'D',
                  score: dataAssessment.value[id].oldData.report_list[0].score,
                });
                } else {
                  final_answer.push({
                  weight:dataAssessment.value[id].oldData.report_list[0].score_answers[index],
                  answer_key:'E',
                  chosen_answer:'E',
                  score: dataAssessment.value[id].oldData.report_list[0].score,
                });
                }
              });
              // console.log(final_answer)
              new_data.correct.push(final_answer);
            }
          } else { // jawaban yang cuma 1
            // tinggal compare karna length cuma 1
            let final_answer = [];
            if (val ===dataAssessment.value[id].oldData.report_list[0].viewer_answers[index]) { // jawaban bener sama kunci jawaban
              if (val === "0") {
                final_answer.push({
                  weight:dataAssessment.value[id].oldData.report_list[0].score_answers[index],
                  answer_key:'A',
                  chosen_answer:'A',
                  score: dataAssessment.value[id].oldData.report_list[0].score,
                });
              } else if (val === "1") {
                final_answer.push({
                  weight:dataAssessment.value[id].oldData.report_list[0].score_answers[index],
                  answer_key:'B',
                  chosen_answer:'B',
                  score: dataAssessment.value[id].oldData.report_list[0].score,
                });
              } else if (val === "2") {
                final_answer.push({
                  weight:dataAssessment.value[id].oldData.report_list[0].score_answers[index],
                  answer_key:'C',
                  chosen_answer:'C',
                  score: dataAssessment.value[id].oldData.report_list[0].score,
                });
              } else if (val === "3") {
                final_answer.push({
                  weight:dataAssessment.value[id].oldData.report_list[0].score_answers[index],
                  answer_key:'D',
                  chosen_answer:'D',
                  score: dataAssessment.value[id].oldData.report_list[0].score,
                });
              } else {
                final_answer.push({
                  weight:dataAssessment.value[id].oldData.report_list[0].score_answers[index],
                  answer_key:'E',
                  chosen_answer:'E',
                  score: dataAssessment.value[id].oldData.report_list[0].score,
                });
              }
              new_data.correct.push(final_answer);
            } else { // jawaban salah
              let answer = dataAssessment.value[id].oldData.report_list[0].viewer_answers[index]
              console.log(answer,'answer')
              if (val === "0") {
                final_answer.push({
                  weight:dataAssessment.value[id].oldData.report_list[0].score_answers[index],
                  answer_key:'A',
                  chosen_answer:answer === '0' ? 'A' : answer === '1' ? 'B' : answer === '2'? 'C' : answer === '3'? 'D': answer === '4' ? 'E' : '',
                  score:0,
                });
              } else if (val === "1") {
                final_answer.push({
                  weight:dataAssessment.value[id].oldData.report_list[0].score_answers[index],
                  answer_key:'B',
                  chosen_answer:answer === '0' ? 'A' : answer === '1' ? 'B' : answer === '2'? 'C' : answer === '3'? 'D': answer === '4' ? 'E' : '',
                  score: 0,
                });
              } else if (val === "2") {
                final_answer.push({
                  weight:dataAssessment.value[id].oldData.report_list[0].score_answers[index],
                  answer_key:'C',
                  chosen_answer:answer === '0' ? 'A' : answer === '1' ? 'B' : answer === '2'? 'C' : answer === '3'? 'D': answer === '4' ? 'E' : '',
                  score: 0,
                });
              } else if (val === "3") {
                final_answer.push({
                  weight:dataAssessment.value[id].oldData.report_list[0].score_answers[index],
                  answer_key:'D',
                  chosen_answer:answer === '0' ? 'A' : answer === '1' ? 'B' : answer === '2'? 'C' : answer === '3'? 'D': answer === '4' ? 'E' : '',
                  score: 0,
                });
              } else {
                final_answer.push({
                  weight:dataAssessment.value[id].oldData.report_list[0].score_answers[index],
                  answer_key:'E',
                  chosen_answer:answer === '0' ? 'A' : answer === '1' ? 'B' : answer === '2'? 'C' : answer === '3'? 'D': answer === '4' ? 'E' : '',
                  score: 0,
                });
              }
              new_data.correct.push(final_answer);
            }
          }
          console.log(new_data);
        }
      );
      // console.log(new_data)

      dataAssessment.value[id].oldData.report_list[0].viewer_answers.forEach(
        (val, index) => {
          new_data.answer.push({
            weight:
              dataAssessment.value[id].oldData.report_list[0].score_answers[
                index
              ],
            answer_key:
              dataAssessment.value[id].oldData.report_list[0].key_answers[
                index
              ],
            chosen_answer:
              dataAssessment.value[id].oldData.report_list[0].viewer_answers[
                index
              ],
            score: 50,
          });
        }
      );
      dataDownloadAssessment.value = new_data;

      document.getElementById("backdrop").style.display = "block";
      document.getElementById("modalDownloadInvoice").style.display = "block";
      document.getElementById("modalDownloadInvoice").className += "show";
      onDownloadPdf("123123");
    };
    const onDownloadPdf = () => {
      // console.log(external_id,'jalan')
      window.html2canvas = html2canvas;
      var doc = new jsPDF();
      doc.html(document.querySelector(".modal-downlad-invoice"), {
        callback: function (pdf) {
          pdf.save("Assessment Report.pdf");
        },
        x: 10,
        y: 10,
        width: 180,
        autoPaging: true,
        windowWidth: 750,
      });
      // isLoadingDownload.value = false
    };
    function closeModal() {
      // console.log('close modal jalanlet ')
      document.getElementById("backdrop").style.display = "none";
      document.getElementById("modalDownloadInvoice").style.display = "none";
      document.getElementById("modalDownloadInvoice").className += document
        .getElementById("modalDownloadInvoice")
        .className.replace("show", "");
    }
    // Get the modal
    var modal = document.getElementById("modalDownloadInvoice");

    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function (event) {
      if (event.target == modal) {
        closeModal();
      }
    };
    // DOWNLOAD PDF

    return {
      onDownloadPdf,
      isLoadingDownload,
      closeModal,
      path,
      classList,
      entry,
      search_student,
      optionMenu,
      optionRightName,
      optionLeftName,
      dataAssessment,
      optionBG,
      valueClass,
      valueScene,
      optionClass,
      optionScene,
      classActive,
      dashboardResult,
      sort_by_active,
      clickCallback,
      page_paginate,
      start_pagination,
      end_pagination,
      total_page_table,
      onSearch,
      open_modal,
      // start_page_assessment,
      // end_page_assessment,
      total_item_table,
      defaultDataAssessment,
      allDataOverview,
      uidClassActive,
      uidSceneActive,
      log,
      fetching_with_scene,
      optionAssessmentSort,
      assessmentSortActive,
      open_sort_standpoint,
      close_sort_standpoint,
      isSortActiveAssessment,
      change_sort_standpoint,
      sortFuncStandpoint,
      export_to_csv,
      download_csv,
      dataDownloadAssessment,
      classNameActive,
      isLoadingAssessment,
      isOnSearchActive,
    };
  },
};
</script>

<style lang="scss">
@import "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css";
@import "../scss/Assessment.scss";
@import "../scss/Mobile/Assessment.scss";
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
