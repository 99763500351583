<template>

<div class="container-profile">
    <SideHeader/>
    <div class="container-right-profile">
        <TopHeaderLogin/>
        <div class="main-profile">
            <BreadCrumbHeader 
            :pathPage="path"
            :optionMenu="optionMenu"
            :optionLeftName="optionLeftName"
            :optionRightName="optionRightName"
            />
            <div class="mid-main-profile">
                <div class="profile-detail">
                    <div class="box-detail-left">
                        <div class="left-header">
                            <h1>Profile</h1>
                            <div class="edit-btn" v-if="editStatus==false" @click="onEdit">
                                <p>Edit Profile</p>
                            </div>
                        </div>
                        <div class="line-box"></div>
                        <div class="user-information">
                            <h1>USER INFORMATION</h1>
                            <div class="profile-input-box" v-if="editStatus == true">
                                <div class="row">
                                    <div class="input-detail-50">
                                        <p>Name</p>
                                        <!-- <input type="text" name="" id="" v-model="nameProfile"> -->
                                        <div class="box-input-profile" :class="[(isNameCorrect === true ? 'correct-input' : detailProfile.name !== '' && detailProfile.name === '' ? '' :  'wrong-input' )]">
                                            <input type="text" class="input-100" v-model="detailProfile.name" @change="onName" autofocus>
                                            <span v-if="isNameCorrect === true">
                                                <i class="fa-solid fa-circle-check"></i>
                                            </span>
                                        </div>
                                        <Transition>
                                            <p class="error-notification" v-if="isNameCorrect === false">Minimum 3 Character</p>
                                        </Transition> 
                                    </div>
                                    <div class="input-detail-50">
                                        <p>Email Address</p>
                                        <input type="text" name="" id="" v-model="detailProfile.email" disabled>
                                    </div>
                                    
                                </div>
                                <div class="row">
                                    <div class="input-detail-50">
                                        <p>Date of Birth</p>
                                        <!-- <input type="date" name="" id="" v-model="dobProfile" @change="onDob"> -->
                                        <div class="box-input-profile" :class="[(isDobCorrect === true ? 'correct-input' : detailProfile.dateOfBirth !== '' && detailProfile.dateOfBirth === '' ? '' :  'wrong-input' )]">
                                            <input type="date" class="input-100" v-model="detailProfile.dateOfBirth" @change="onDob">
                                            <span v-if="isDobCorrect === true">
                                                <i class="fa-solid fa-circle-check"></i>
                                            </span>
                                        </div>
                                        <Transition>
                                            <p class="error-notification" v-if="isDobCorrect === false">Minimum 3 Character</p>
                                        </Transition> 
                                    </div>
                                    <div class="input-detail-50">
                                        <p>Gender</p>
                                        <!-- <select name="" id="" v-model="genderProfile">
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                        </select> -->
                                        <div class="box-input-profile" :class="[(isGenderCorrect === true ? 'correct-input' : detailProfile.gender !== '' && detailProfile.gender === '' ? '' :  'wrong-input' )]">
                                            <select name="gender" id="gender" class="input-100" v-model="detailProfile.gender " @change="onGender">
                                                <option value="male">Male</option>
                                                <option value="female">Female</option>
                                            </select>
                                        </div>
                                        <Transition>
                                            <p class="error-notification" v-if="isGenderCorrect === false">Gender harus diisi</p>
                                        </Transition>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="input-detail-50">
                                        <p>School Name</p>
                                        <!-- <input type="text" name="" id="" v-model="schoolProfile"> -->
                                        <div class="box-input-profile" :class="[(isSchoolCorrect === true ? 'correct-input' : detailProfile.company !== '' && detailProfile.company === '' ? '' :  'wrong-input' )]">
                                            <input type="text" class="input-100" v-model="detailProfile.company" @change="onSchool">
                                            <span v-if="isSchoolCorrect === true">
                                                <i class="fa-solid fa-circle-check"></i>
                                            </span>
                                        </div>
                                        <Transition>
                                            <p class="error-notification" v-if="isSchoolCorrect === false">Minimum 3 Character</p>
                                        </Transition> 
                                    </div>
                                    <!-- <div class="input-detail-50">
                                        <p>Subject</p>
                                        <div class="box-input-profile" :class="[(isSubjectCorrect === true ? 'correct-input' : detailProfile.subject === '' && detailProfile.subject === '' ? '' :  'wrong-input' )]"> 
                                            <input type="text" class="input-100" v-model="detailProfile.subject" @change="onSubject">
                                            <span v-if="isSubjectCorrect === true">
                                                <i class="fa-solid fa-circle-check"></i>
                                            </span>
                                        </div>
                                        <Transition>
                                            <p class="error-notification" v-if="isSubjectCorrect === false">Minimum 3 Character</p>
                                        </Transition> 
                                    </div> -->
                                </div>
                            </div>

                            <div class="profile-input-box" v-if="editStatus == false">
                                <div class="row">
                                    <div class="input-detail-50">
                                        <p>Name</p>
                                        <input type="text" name="" id="" v-model="detailProfile.name" disabled>
                                    </div>
                                    <div class="input-detail-50">
                                        <p>Email Address</p>
                                        <input type="text" name="" id="" v-model="detailProfile.email" disabled>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="input-detail-50">
                                        <p>Date of Birth</p>
                                        <input type="date" name="" id="" v-model="detailProfile.dateOfBirth" disabled>
                                    </div>
                                    <div class="input-detail-50">
                                        <p>Gender</p>
                                        <input type="text" v-model="detailProfile.gender" disabled>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="input-detail-50">
                                        <p>School Name</p>
                                        <input type="text" name="" id="" v-model="detailProfile.company" disabled>
                                    </div>
                                    <!-- <div class="input-detail-50">
                                        <p>Subject</p>
                                        <input type="text" name="" id="" v-model="detailProfile.subject" disabled>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <div class="line-box"></div>
                        <div class="contact-information">
                            <h1>CONTACT INFORMATION</h1>
                            <div class="profile-input-box" v-if="editStatus == true">
                                <div class="row">
                                    <div class="input-detail-50">
                                        <p>Province</p>
                                        <!-- <input type="text" name="" id="" v-model="addressProfile"> -->
                                        <div class="box-input-profile" :class="[(isProvinceCorrect === true ? 'correct-input' : detailProfile.province !== '' && detailProfile.province === '' ? '' :  'wrong-input' )]">
                                            <input type="text" class="input-100" v-model="detailProfile.province" @change="onProvince">
                                            <span v-if="isProvinceCorrect === true">
                                                <i class="fa-solid fa-circle-check"></i>
                                            </span>
                                        </div>
                                        <Transition>
                                            <p class="error-notification" v-if="isProvinceCorrect === false">Minimum 3 Character</p>
                                        </Transition>
                                    </div>
                                     <div class="input-detail-50">
                                        <p>City</p>
                                        <!-- <input type="text" name="" id="" v-model="cityProfile"> -->
                                        <div class="box-input-profile" :class="[(isCityCorrect === true ? 'correct-input' : detailProfile.placeOfBirth !== '' && detailProfile.placeOfBirth === '' ? '' :  'wrong-input' )]">
                                            <input type="text" class="input-100" v-model="detailProfile.placeOfBirth" @change="onCity" @load="log(detailProfile.country)">
                                            <span v-if="isCityCorrect === true">
                                                <i class="fa-solid fa-circle-check"></i>
                                            </span>
                                        </div>
                                        <Transition>
                                            <p class="error-notification" v-if="isCityCorrect === false">Minimum 3 Character</p>
                                        </Transition>
                                    </div>
                                </div>
                                <div class="row">                    
                                    <div class="input-detail-50">
                                        <p>Country</p>
                                        <!-- <input type="text" name="" id="" v-model="countryProfile"> -->
                                        <div class="box-input-profile" :class="[(isCountryCorrect === true ? 'correct-input' : detailProfile.country !== '' && detailProfile.country === '' ? '' :  'wrong-input' )]">
                                            <input type="text" class="input-100" v-model="detailProfile.country" @change="onCountry"> 
                                            <span v-if="isCountryCorrect === true">
                                                <i class="fa-solid fa-circle-check"></i>
                                            </span>
                                        </div>
                                        <Transition>
                                            <p class="error-notification" v-if="isCountryCorrect === false">Minimum 3 Character</p>
                                        </Transition>
                                    </div>
                                    <!-- <div class="input-detail-50">
                                        <p>Postal Code</p>
                                        <div class="box-input-profile" :class="[(isPostalCorrect === true ? 'correct-input' : detailProfile.zipCode === '' && detailProfile.zipCode === '' ? '' :  'wrong-input' )]">
                                            <input type="text" class="input-100" v-model="detailProfile.zipCode" @change="onPostal" >   
                                            <span v-if="isPostalCorrect === true">
                                                <i class="fa-solid fa-circle-check"></i>
                                            </span>
                                        </div>
                                        <Transition>
                                            <p class="error-notification" v-if="isPostalCorrect === false">Minimum 3 Character</p>
                                        </Transition>
                                    </div> -->
                                </div>
                            </div>

                            <div class="profile-input-box" v-if="editStatus == false">
                                <div class="row">
                                    <div class="input-detail-50">
                                        <p>Province</p>
                                        <input type="text" name="" id="" v-model="detailProfile.province" disabled>
                                    </div>
                                    <div class="input-detail-50">
                                        <p>City</p>
                                        <input type="text" name="" id="" v-model="detailProfile.placeOfBirth" disabled>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="input-detail-50">
                                        <p>Country</p>
                                        <input type="text" name="" id="" v-model="detailProfile.country" disabled>
                                    </div>
                                    <!-- <div class="input-detail-50">
                                        <p>Postal Code</p>
                                        <input type="text" name="" id="" v-model="detailProfile.zipCode" disabled>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="box-detail-right">
                        <div class="photo-box">
                            <div class="profile-photo">
                                <!-- <img :src="detailProfile.profilePicture" alt=""> -->
                                <img :src="detailProfile.profilePicture" v-if="detailProfile.profilePicture !== ''" alt="" id="profile_id">
                                <img src="../assets/dp-otis-1.png" alt="" v-else>
                            </div>
                            <label for="input=picture" class="profile-camera"></label>
                            <input id="input=picture"  :ref="value_picture"  type="file" accept=".jpg,.jpeg,.png" @change="change_picture"/>
                        </div>
                        <div class="profile-list">
                            <div class="name-age">
                                <p>{{detailProfile.name}}</p>
                                <p v-if="detailProfile.name != '' && age != ''">,&nbsp;</p>
                                <p>{{age}}</p>
                            </div>
                            <div class="city-country">
                                <p>{{detailProfile.placeOfBirth}}</p>
                                <p v-if="detailProfile.placeOfBirth != '' && detailProfile.country != ''">,&nbsp;</p>
                                <p>{{detailProfile.country}}</p>
                            </div>
                            <!-- <div class="subject">
                                <p>{{detailProfile.subject}}</p>
                            </div> -->
                            <div class="school">
                                <p>{{detailProfile.company}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="edit-profile-btn-box" v-if="editStatus == true">
                    <div class="save-btn" @click="onSave" v-if="saveReady"> <p>SAVE CHANGES</p> </div>
                    <div class="save-failed" v-else><p>SAVE CHANGES</p></div>
                    <div class="cancel-btn" @click="onCancel"> <p>CANCEL</p> </div>
                </div>
            </div>
        </div>
        <!-- <FooterOverview/> -->
    </div>
</div>
</template>

<script>
// @ is an alias to /src
    // import FooterOverview from '@/components/Footer/FooterOverview.vue' 
    import SideHeader from '@/components/Header/SideHeader.vue'
    import {ref,onBeforeMount} from 'vue'
    import BreadCrumbHeader from '@/components/Header/BreadCrumbHeader.vue'
    import TopHeaderLogin from '@/components/Header/TopHeaderLogin.vue'
    import {useStore} from 'vuex'
    // eslint-disable-next-line no-unused-vars
    // import { getStorage,ref as reff,uploadBytes } from "firebase/storage";
    import Axios from 'axios'
    import {db} from '../../firebase-config'
    import { getStorage, ref as stRef, uploadBytes } from "firebase/storage";
    // eslint-disable-next-line no-unused-vars
    import {doc,setDoc,getDoc,Timestamp} from 'firebase/firestore'
    // import {auth,provider,db} froms '../../firebase-config'


    


    export default {
        name: 'AssessmentView',
        components: {
            SideHeader,
            BreadCrumbHeader,
            TopHeaderLogin,
            // FooterOverview
    },
    setup(){
        // const firebaseApp = getApp();

        const storage = getStorage();
        // const database = getDatabase();
       
        
        

        // const pathReference = ref(storage, 'images/stars.jpg');
        const store = useStore()
        // console.log(storage)
        let path = ref('Profile')
        let optionMenu = ref(true)
        let uidCustomer = ref('')
        let optionLeftName = ref ('New')
        let optionRightName = ref ('Filter')
        let uploadPicture = ref('')
        let editStatus = ref(false)
        let test_image = ref('')
        let detailProfile = ref('')
        let age = ref('')

        let tempName = ('')
        let tempDob = ('')
        let tempGender = ('')
        let tempSchool = ('')
        let tempAge = ('')
        let tempAddress = ('')
        let tempCity = ('')
        let tempCountry = ('')
        let tempProvince = ('')

        let isNameCorrect = ref(false)
        let isDobCorrect = ref(false)
        let isGenderCorrect = ref(false)
        let isSchoolCorrect = ref(false)
        let isAgeCorrect = ref(false)
        let isAddressCorrect = ref(false)
        let isCityCorrect = ref(false)
        let isCountryCorrect = ref(false)
        let isProvinceCorrect = ref(false)
        let value_picture = ref()
        let saveReady = ref(false)

        let fileUrl = ref('')
        let myFile = ref()

        const checkSaveData=()=>{
            if(isNameCorrect.value && isDobCorrect.value && isGenderCorrect.value && isSchoolCorrect.value && isCityCorrect.value && isCountryCorrect.value && isProvinceCorrect.value){
                saveReady.value = true
            }else{
                saveReady.value = false
            }
        }

        const onEdit=()=>{
            editStatus.value = true
            onName()
            onDob()
            onGender()
            onSchool()
            onCity()
            onCountry()
            onProvince()
            tempName = detailProfile.value.name
            tempDob = detailProfile.value.dateOfBirth
            tempGender = detailProfile.value.gender
            tempSchool = detailProfile.value.company
            tempAge = detailProfile.value.dateOfBirth
            tempAddress = detailProfile.value.address
            tempCity = detailProfile.value.placeOfBirth
            tempCountry = detailProfile.value.country
            tempProvince = detailProfile.value.province
        }
        const onSave=()=>{
            editStatus.value = false
            
            if(isNameCorrect.value && isDobCorrect.value && isGenderCorrect.value && isSchoolCorrect.value && isCityCorrect.value && isCountryCorrect.value && isProvinceCorrect.value){
                let uid = localStorage.getItem('idStorage')
                var dataToSend = JSON.stringify({
                    "uid": uid,
                    "name":detailProfile.value.name,
                    "dob": detailProfile.value.dateOfBirth,
                    "gender": detailProfile.value.gender,
                    "company": detailProfile.value.company,
                    "address": detailProfile.value.address,
                    "pob": detailProfile.value.placeOfBirth,
                    "country": detailProfile.value.country,
                    "province": detailProfile.value.province
                    
                    
                });

                var config ={
                    method: 'post',
                    url: 'https://us-central1-millea-lab.cloudfunctions.net/web/api/updateUserData',
                    headers: { 
                    'Content-Type': 'application/json'
                    },
                    data : dataToSend
                };

                Axios(config)
                // console.log(detailProfile.value.province)
            }else{
                checkSaveData()
            }
        }
        const onCancel=()=>{
            editStatus.value = false
            detailProfile.value.name = tempName
            detailProfile.value.dateOfBirth = tempDob
            detailProfile.value.gender = tempGender
            detailProfile.value.company = tempSchool
            detailProfile.value.dateOfBirth = tempAge
            detailProfile.value.address = tempAddress
            detailProfile.value.placeOfBirth = tempCity
            detailProfile.value.country = tempCountry
            detailProfile.value.province = tempProvince
        }
        const onName=()=>{
            if(detailProfile.value.name !== undefined ){
                if(detailProfile.value.name.length >3){
                    isNameCorrect.value = true
                    checkSaveData()
                }else {
                    isNameCorrect.value = false
                    checkSaveData()
                }
            }else {
                isNameCorrect.value = false
                checkSaveData()
            }
        }
        const onDob=()=>{
            if(detailProfile.value.dateOfBirth !== undefined ){
                if(detailProfile.value.dateOfBirth != null){
                    isDobCorrect.value = true
                    if(detailProfile.value.dateOfBirth){
                        let yearCustomer = parseInt(detailProfile.value.dateOfBirth.split('-')[0])
                        
                        let year = new Date().getFullYear();
                        let countAge = year -  yearCustomer
                        age.value = countAge
                        // console.log(countAge)
                        // console.log(yearCustomer)
                    }else {
                        age.value = ''
                    }
                    checkSaveData()
                }else {
                    isDobCorrect.value = false
                    checkSaveData()
                }
            }else {
                isDobCorrect.value = false
                checkSaveData()
            }
        }
        const onGender=()=>{
            if(detailProfile.value.gender !== undefined ){
                if(detailProfile.value.gender != null){
                    isGenderCorrect.value = true
                    checkSaveData()
                }else {
                    isGenderCorrect.value = false
                    checkSaveData()
                }
            }else {
                isGenderCorrect.value = false
                checkSaveData()
            }
        }
        const onSchool=()=>{
            if(detailProfile.value.company !== undefined ){
                if(detailProfile.value.company.length >3){
                    isSchoolCorrect.value = true
                    checkSaveData()
                }else {
                    isSchoolCorrect.value = false
                    checkSaveData()
                }
            }else {
                isSchoolCorrect.value = false
                checkSaveData()
            }
        }
        const onAddress=()=>{
            if(detailProfile.value.address !== undefined ){
                if(detailProfile.value.address.length >3){
                    isAddressCorrect.value = true
                    checkSaveData()
                }else {
                    isAddressCorrect.value = false
                    checkSaveData()
                }
            }else {
                isAddressCorrect.value = false
                checkSaveData()
            }
        }
        const onCity=()=>{
            if(detailProfile.value.placeOfBirth !== undefined ){
                if(detailProfile.value.placeOfBirth.length >3){
                    isCityCorrect.value = true
                    checkSaveData()
                }else {
                    isCityCorrect.value = false
                    checkSaveData()
                }
            }else {
                isCityCorrect.value = false
                checkSaveData()
            }
        }
        const onCountry=()=>{
            if(detailProfile.value.country !== undefined ){
                if(detailProfile.value.country.length >3){
                    isCountryCorrect.value = true
                    checkSaveData()
                }else {
                    isCountryCorrect.value = false
                    checkSaveData()
                }
            }else {
                isCountryCorrect.value = false
                checkSaveData()
            }
        }

        const onProvince=()=>{
            if(detailProfile.value.province !== undefined ){
                if(detailProfile.value.province.length >3){
                    isProvinceCorrect.value = true
                    checkSaveData()
                }else {
                    isProvinceCorrect.value = false
                    checkSaveData()
                }
            }else {
                isProvinceCorrect.value = false
                checkSaveData()
            }
        }

        const change_picture=(event)=>{

            // OLD WAY
            // console.log(event.target.files[0])
            // let file = event.target.files[0]
            // const fileReader = new FileReader();
            // fileReader.readAsText(file);



            // let imageAsBlob =  new Blob([fileReader.result])
            // console.log(imageAsBlob,'image as blob')

            // let image = new Image()
            // image.src = URL.createObjectURL(imageAsBlob);
            // console.log(image.src)

            // // new

            // var selectedFile = event.target.files[0];
         
            // console.log(reader)
            // OLD WAY

            // NEW TESTING MAS JO
            var selectedFile = event.target.files[0];
            if(!selectedFile){
                selectedFile = ''
            }
            // console.log(selectedFile)
            let encode_uri = encodeURIComponent(`ProfilePictures/${uidCustomer.value}/${selectedFile.name}`)
            // console.log(encode_uri)
            // console.log(database)
            let fullPath = `https://firebasestorage.googleapis.com/v0/b/millea-lab-cdn/o/${encode_uri}?alt=media`
            // const databaseReference = dbRef(database, "millea-lab-cdn/ProfilePictures");
            // let fullPathUri = encodeURIComponent(fullPath)
            // console.log(fullPathUri)
            const storageRef = stRef(storage, "ProfilePictures/" + `${uidCustomer.value}/` + selectedFile.name);
            // const storageRef = ref(storage,`uploads/${authId}/images/${Date.now()}-${filename}`
    
            // console.log(storage)
            // console.log(storageRef)
             let collectionID = 'users';
            let docRef = doc(db, collectionID, uidCustomer.value);
            // eslint-disable-next-line no-unused-vars
            uploadBytes(storageRef, selectedFile).then(function (snapshot) {
                // console.log(snapshot)
                setDoc(docRef, {    
                    profile_picture:fullPath
                }, { merge: true });
         
            detailProfile.value.profilePicture = fullPath
            test_image.value = fullPath

                // console.log('berhasil upload');
            });
         
             
        }

        const update_picture=()=>{
           
        }



        const fetch=()=>{
            detailProfile.value = store.getters.getCustomerDetail
            uidCustomer.value = store.getters.getValueUidCustomer
            if(detailProfile.value.dateOfBirth){
                let yearCustomer = parseInt(detailProfile.value.dateOfBirth.split('-')[0])
                
                let year = new Date().getFullYear();
                let countAge = year -  yearCustomer
                age.value = countAge
                // console.log(countAge)
                // console.log(yearCustomer)
            }else {
                age.value = ''
            }
            // let storageBucket = 'millea-lab-cdn';
            // const storage = getStorage(firebaseApp, storageBucket);
        
            // console.log(detailProfile.value)
            // console.log(detailProfile.value.placeOfBirth) 
           

        }

        const log=()=>{
            // console.log(params)
        }

        onBeforeMount(()=>{
            fetch()
        })

        return{
            path,
            optionMenu,
            optionLeftName,
            optionRightName,
            uploadPicture,
            editStatus,
            detailProfile,
            tempName,
            isNameCorrect,
            isDobCorrect,
            isGenderCorrect,
            isSchoolCorrect,
            isAgeCorrect,
            isAddressCorrect,
            isCityCorrect,
            isCountryCorrect,
            saveReady,
            checkSaveData,
            onEdit,
            onSave,
            onCancel,
            onName,
            onDob,
            onGender,
            onSchool,
            onAddress,
            onCity,
            onCountry,
            onProvince,
            change_picture,
            value_picture,
            fetch,
            test_image,
            fileUrl,
            myFile,
            age,
            log,
            isProvinceCorrect,
            update_picture,
            uidCustomer
        }
    }
    }
</script>

<style lang="scss">
    @import '../scss/Profile.scss';
    @import '../scss/Mobile/Profile.scss';
</style>