<template>
    <div class="container-topHeaderLogin">
        <!-- <div class="searching-box">
            <div class="searching-container">
                <img src="../../assets/Header/magnifier.svg" alt="">
                <input type="text" class="input-searching">
            </div>
        </div> -->
        <div class="profile-box">
            <img src="../../assets/Header/bell.svg" alt="" class="icon-header-right">
            <img src="../../assets/Header/Task.svg" alt="" class="icon-header-right">
            <div class="box-for-profile">
                <router-link to="/profile" class="round-profile"  v-if="isLoginGlobal === true">
                    <!-- <img src="../../assets/Header/testing_pp.jpeg" alt=""> -->
                    <img :src="detailProfile.profilePicture" alt="" id="profile_id">
                
                </router-link>
                <router-link to="/login"  class="btn-login-profile" v-if="isLoginGlobal === false">
                   <img src="../../assets/Header/log-in.svg" alt="">
                    LOGIN
                </router-link>  
            </div>
        </div>
    </div>
</template>


<script>
import {ref,onBeforeMount} from 'vue'
import {useStore} from 'vuex'
export default {
    name:'TopHeaderLogin',
    setup(){
        const store = useStore()
        let isLoginGlobal = ref(false)
        let detailProfile = ref('')


        const fetch=()=>{
            isLoginGlobal.value = store.getters.getIsLogin
            detailProfile.value = store.getters.getCustomerDetail
            // let dataCustomer = store.getters.getCustomerDetail
            
        }

        onBeforeMount(()=>{
            fetch()
        })

        return {
            isLoginGlobal,
            detailProfile
        }
    }
}

</script>

<style lang="scss">
    @import '../../scss/TopheaderLogin.scss';
</style>