<template>
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <Datepicker v-model="date"  />
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Save changes</button>
            </div>
            </div>
        </div>
    </div>
    <div class="container-overview">
        <SideHeader/>
        <div class="container-right-overview">
            <TopHeaderLogin/>
            <div class="main-overview">
                <BreadCrumbHeader
                 :pathPage="path"
                 :optionMenu="optionMenu"
                 :optionLeftName="optionLeftName"
                 :optionRightName="optionRightName"
                 :withbg=optionBG 
                 />
                <div class="mid-main-overview">
                    <div class="mid-left">
                        <!-- LOADING SKELETON -->
                        <div class="box-img-overview " v-if="isSkeletonLoading">
                            <span class="skeleton-box" style="width:100%;height:100%;"></span>
                        </div>
                        <!-- LOADING SKELETON END -->
                         <div class="box-img-overview" v-else >
                            <img src="../assets/slider.png" alt="" @load="img_loaded">
                            <p v-if="isLoginGlobal">Welcome Back, <span>{{customerDetail.name}}</span> </p>
                            <p v-else>Welcome Back, <span>Teman Millea!</span> </p>
                        </div>
                        <!-- LOADING SKELETON -->
                        <div class="box-tripple"  v-if="isSkeletonLoading"> 
                            <smallCard v-for="item in dashboardResult" :key="item.id"
                                :img="`${item.img}`"
                                :lastUpdate="item.lastUpdate"
                                :percentage="item.percentage"
                                :name="item.name"
                                :score="item.value"
                                :status="isSkeletonLoading" 
                            />
                        </div>
                        <!-- LOADING SKELETON END -->
                        <div class="box-tripple" v-else>
                            <smallCard v-for="item in dashboardResult" :key="item.id"
                                :img="`${item.img}`"
                                :lastUpdate="item.lastUpdate"
                                :percentage="item.percentage"
                                :name="item.name"
                                :score="item.value" 
                                :status="isSkeletonLoading" 
                            />
                        </div>
                        <div class="mid-card-chart">
                            <!-- LOADING SKELETON -->
                            <div class="box-chart" v-if="isSkeletonLoading">
                                <div class="box-title-chart">
                                    <div class="box-title-left">
                                        <p class="skeleton-box" style="width:100%; height:20px;"></p>
                                    </div>
                                    <div class="box-option-right">
                                        <div class="skeleton-box" style="height:30px;width:150px; margin-right:10px;">

                                        </div>
                                        <div class="skeleton-box" style="height:30px;width:150px;margin-right:10px;">

                                        </div>
                                        <div class="skeleton-box" style="height:30px;width:150px;margin-right:10px;">

                                        </div>
                                    </div>
                                </div>
                                <!-- <column-chart art  class="firstChart"  
                                    :download="false" 
                                    :dataset="{borderWidth: 1,Width:'100%'}"
                                    :colors="['#004F98']" 
                                    :data="studentChartValue" 
                                    v-if="statusOverview === 'Class'"
                                 >
                                 </column-chart> -->
                                <div class="skeleton-box" style="height:250px;width:100%;margin-right:10px;">

                                </div>
                                 
                            </div>
                            <!-- LOADING SKELETON END -->

                            <div class="box-chart" v-else>
                                <div class="box-title-chart">
                                    <div class="box-title-left">
                                        <p>Pemetaan Seluruh Interaksi Siswa</p>
                                    </div>
                                    <div class="box-option-right">
                                        <Multiselect
                                            class="multiSelectClass"
                                            v-model="valueClass"
                                            :options="optionClass"
                                            :placeholder="valueClass"
                                        />
                                         <Multiselect
                                            class="multiSelectClass"
                                            v-model="valueScene"
                                            :options="optionScene"
                                            :placeholder="valueScene"
                                        />
                                         <Multiselect
                                            class="multiSelectClass"
                                            v-model="valueStudent"
                                            :options="optionStudent"
                                            :placeholder="valueStudent"
                                            @change="onSelectStudentChart()"
                                        />
                                    </div>
                                </div>
                                <!-- <column-chart art  class="firstChart"  
                                    :download="false" 
                                    :dataset="{borderWidth: 1,Width:'100%'}"
                                    :colors="['#004F98']" 
                                    :data="studentChartValue" 
                                    v-if="statusOverview === 'Class'"
                                 >
                                 </column-chart> -->
                                <apexchart
                                type="bar" 
                                height="350"  
                                id="standpoint_id_chart" 
                                :options="standpointValueOptions" 
                                :series="standpointValueChart"
                                v-if="isLoadingChart === false"
                                >
                                </apexchart>
                                <div class="empty-card"  v-else>
                                    <div class="spinner-border text-primary" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                            </div>

                            <!-- LOADING SKELETON START -->
                            <div class="card-top-student" v-if="isSkeletonLoading">
                                <div class="title-top-student">
                                    <div class="skeleton-box" style="width:150px;height:30px;">

                                    </div>
                                    <div class="skeleton-box" style="height:30px;width:150px;margin-right:10px;">

                                    </div>
                                </div>
                                <div class="box-card-top-student">
                                    <div class="card-item-student">
                                        <div class="item-student-left">
                                            <div class="skeleton-box" style="height:50px;width:50px;border-radius:50%;"> 

                                            </div>
                                        </div>
                                        <div class="item-student-right">
                                            <div class="skeleton-box" style="width:200px;height:30px; margin:5px;">

                                            </div>
                                            <div class="skeleton-box" style="width:200px;height:30px;margin:5px;">

                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-item-student">
                                        <div class="item-student-left">
                                            <div class="skeleton-box" style="height:50px;width:50px;border-radius:50%;"> 

                                            </div>
                                        </div>
                                        <div class="item-student-right">
                                            <div class="skeleton-box" style="width:200px;height:30px; margin:5px;">

                                            </div>
                                            <div class="skeleton-box" style="width:200px;height:30px;margin:5px;">

                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-item-student">
                                        <div class="item-student-left">
                                            <div class="skeleton-box" style="height:50px;width:50px;border-radius:50%;"> 

                                            </div>
                                        </div>
                                        <div class="item-student-right">
                                            <div class="skeleton-box" style="width:200px;height:30px; margin:5px;">

                                            </div>
                                            <div class="skeleton-box" style="width:200px;height:30px;margin:5px;">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- LOADING SKELETON END -->

                            <div class="card-top-student" v-else>
                                <div class="title-top-student">
                                    <p>Top Student</p>
                                    <Multiselect
                                        class="multiSelectClass"
                                        v-model="valueTopStudent"
                                        :options="optionTopStudent"
                                        :placeholder="valueTopStudent"
                                        @change="changeTopStudent"
                                    />
                                </div>
                                <div class="box-card-top-student">
                                    <div class="fill-card" v-if="topRankingStudent !== undefined && isLoadingTopStudent === false">
                                        <div class="card-item-student" v-for="(items,id) in topRankingStudent" :key="id">
                                            <div class="item-student-left" >
                                                <img src="../assets/overview/medal_1.svg" alt="" v-if="id === 0">
                                                <img src="../assets/overview/medal_2.svg" alt="" v-if="id === 1">
                                                <img src="../assets/overview/medal_3.svg" alt="" v-if="id === 2">
                                            </div>
                                            <div class="item-student-right" >
                                                <p>{{items.name}}</p>
                                                <p>Allover score: {{items.score}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="empty-card"  v-else-if="topRankingStudent!== undefined && isLoadingTopStudent === true">
                                        <div class="spinner-border text-primary" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                    <div class="empty-card" v-else>
                                        <p>No data to display</p>
                                        <p>Try adjusting your search of filter to find what you're looking for</p>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>  
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import SideHeader from '../components/Header/SideHeader.vue'
    import TopHeaderLogin from '../components/Header/TopHeaderLogin.vue'
    import BreadCrumbHeader from '../components/Header/BreadCrumbHeader.vue'
    import {ref,onBeforeMount,watch} from 'vue'
    // import FooterOverview from '../components/Footer/FooterOverview.vue'
    import Datepicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css'
    import Multiselect from '@vueform/multiselect'
    import smallCard from '../components/Card/smallCard.vue'
    import {useStore} from 'vuex'
    import Axios from 'axios'
    export default {
        name:'OverView',
        components:{
            SideHeader,
            TopHeaderLogin,
            BreadCrumbHeader,
            // FooterOverview,
            Multiselect,
            smallCard
        },
        setup(){
            const store = useStore()
            let isLoadingChart = ref(true)
            let isLoadingTopStudent = ref(true)
            let uidUser = ref(localStorage.getItem('idStorage'))
            let uidClassActive = ref('')
            let uidSceneActive = ref('')
            let uidStudentActive = ref('')
            let path = ref('Overview')
            let isLoginGlobal=ref(false)
            let optionMenu = ref(true)
            let customerName = ref('Tara Flexing')
            let isSkeletonLoading = ref(false)
            let optionLeftName = ref ('New')
            let optionRightName = ref ('Filter')
            let statusOverview = ref('Class')
            let allTopStudent = ref([])
            let allTeacherReport = ref([])
            let optionBG=ref(true)
            let dashboardResult = ref([])
            let date = ref()
            let customerDetail = ref({
                
            })
            let allDataOverview = ref()
            const img_loaded=()=>{
                // console.log('image are loaded')
                
            }

            // STAND POINT CHART OVERVIEW SETTING
            let standpointValueChart = ref([{
                name: 'Durasi Siswa',
                data: [0,0,0,0,0,0,0,0,0,0],
                color:'#004F98'
            }])

            let standpointValueOptions=ref(
                {
                    chart:{
                    type:'bar',
                    height:350
                        },plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '70%',
                        endingShape: 'rounded'
                    },
                    },dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    xaxis: {
                        categories: ['1','2','3','4','5','6','7','8','9','10'],
                        title:{
                            text:'Standpoint'
                        }
                    },
                    yaxis: {
                        title: {
                            text: 'Durasi Interaksi (second)'
                        }
                    },
                    fill: {
                        opacity: 1 ,
                    }, 
                    tooltip: {
                        y: {
                            formatter: function (val) {
                            return "" + val + " second"
                            }
                        }
                    }
                }
            )
            
            // STAND POINT CHART OVERVIEW SETTING

        
         

            // all array untuk di looping. nanti ini pindahin ke vuex
            let optionClass = ref()
            let optionScene = ref()
            let optionStudent = ref([ 'Bayu','Adella','queena','Qilla'])
            let optionTopStudent = ref([])
            let topRankingStudent = ref([])
            // value yang diambil dari dropdown
            let valueTopStudent = ref('Top Student')
            let valueClass = ref('Class')
            let valueScene = ref('Scene')
            let valueStudent = ref('Student')
            // data yang aktif untuk di tampilkan
            let classActive = ref('Tel-1') // ini seharusnya udh gak kepake
            let sceneActive = ref('')
            let studentActive = ref('Bayu')
            let studentChartValue = ref({'January':10,'February':20,'March':30,'April':20,'May':15,"June":'17',"July":21,"August":30,"September":12,"October":22,"November":88,"December":12})
            const changeStatusChart=(params)=>{

                statusOverview.value = params
            }


            const changeTopStudent=()=>{
                // console.log(valueClass.value)
            }

            // FIND CLASS 
            watch(async()=>valueClass.value,function(){
                // console.log('watch class jalan')
                isLoadingChart.value = true
                if(valueClass.value !==null && valueClass.value !== 'Class'){
                    
                    let filter = allDataOverview.value.filter((val)=>{
                        if(val.name === valueClass.value){
                            return val
                        }
                    })
                    
                    if(filter){
                        //
                        // console.log(filter)
                        uidClassActive.value = filter[0].id
                        let allScene = []
                        let allStudent = []
                        filter[0].sceneList.forEach((val)=>{
                        allScene.push(val.name)
                        })
                        filter[0].viewerList.forEach((val)=>{
                            allStudent.push(val.name)
                        })
                        if(filter[0].sceneList.length > 0){
                            
                            optionScene.value = allScene
                            valueScene.value = optionScene.value[0]
                            uidSceneActive.value = filter[0].sceneList[0].id
                            
                            // console.log(uidSceneActive.value,' uid scene active')
                        }else {
                            optionScene.value = allScene
                            valueScene.value = 'Subject'
                        }
    
                        if(filter[0].viewerList.length > 0){
                            optionStudent.value = allStudent
                            valueStudent.value = optionStudent.value[0]
                            uidStudentActive.value = filter[0].viewerList[0].id
                            // console.log(uidStudentActive.value,' uid student active')
                            if(uidClassActive.value !== '' && uidSceneActive.value !== '' && uidStudentActive.value !== ''){
                                fetching_interaction()
                            }
                                
                        }else {
                            optionStudent.value = allStudent
                            valueStudent.value = 'Student'
                            standpointValueChart.value = [{
                            name: 'Durasi Siswa',
                            data: [0,0,0,0,0,0,0,0,0,0],
                            color:'#004F98'
                        }]
                        isLoadingChart.value = false
                        }
                    }else {
                        //
                        valueScene.value = 'Subject'
                        valueStudent.value = 'Student'
                         standpointValueChart.value = [{
                            name: 'Durasi Siswa',
                            data: [0,0,0,0,0,0,0,0,0,0],
                            color:'#004F98'
                        }]
                        isLoadingChart.value = false
                    }
                
                }else {
                    valueClass.value = 'Class'
                    valueScene.value = 'Scene'
                    valueStudent.value = 'Student'
                    uidClassActive.value = ''
                    uidSceneActive.value = ''
                    uidStudentActive.value = ''
                    optionScene.value = []
                    optionStudent.value = []
                    standpointValueChart.value = [{
                        name: 'Durasi Siswa',
                        data: [0,0,0,0,0,0,0,0,0,0],
                        color:'#004F98'
                    }]
                    isLoadingChart.value = false
                }
             
            })
            // FIND CLASS END

            // FIND SCENE SUBJECT   
            watch(async()=>valueScene.value,function(){
                // console.log('watch scene jalan')
                isLoadingChart.value = true
                if(valueScene.value !== null && valueScene.value !== 'Scene'){
                    
                    // eslint-disable-next-line no-unused-vars
                    let filter = allDataOverview.value.filter((val)=>{                        
                        if(val.name === valueClass.value){
                            if(val.sceneList.length > 0){
                                val.sceneList.forEach((value)=>{ // value scene
                                    if(value.name === valueScene.value){
                                        uidSceneActive.value = value.id
                                        if(uidStudentActive.value !== '' && uidSceneActive.value !== '' && studentActive.value !== ''){
                                           fetching_interaction()
                                        }else {
                                            // console.log('masuk ke else')
                                            isLoadingChart.value = false
                                            valueStudent.value = 'Student'     
                                                standpointValueChart.value = [{
                                                name: 'Durasi Siswa',
                                                data: [0,0,0,0,0,0,0,0,0,0],
                                                color:'#004F98'
                                            }]
                                        }
                                    }
                                })
                                isLoadingChart.value = false
                            }else {
                                valueScene.value = 'Subject'
                                valueStudent.value = 'Student'
                                uidSceneActive.value = ''
                                uidStudentActive.value = ''
                                   
                                    standpointValueChart.value = [{
                                    name: 'Durasi Siswa',
                                    data: [0,0,0,0,0,0,0,0,0,0],
                                    color:'#004F98'
                                }]
                            }
                        }
                    })
                }else {
                    //
                    valueScene.value = 'Scene'
                    valueStudent.value = 'Student'
                    uidSceneActive.value = ''
                    uidStudentActive.value = ''
                    
                    standpointValueChart.value = [{
                        name: 'Durasi Siswa',
                        data: [0,0,0,0,0,0,0,0,0,0],
                        color:'#004F98'
                    }]
                }
                isLoadingChart.value = false
            })
                // eslint-disable-next-line no-unused-vars

            // FIND SCENE SUBJECT END

            // FIND STUDENT VALUE
            watch(()=>valueStudent.value,(params)=>{
                // console.log('watch student jalan')
                isLoadingChart.value = true
                // console.log(params, params !==null &&  params !== 'Student')
                if(params !== null && params !== 'Student'){
                    // console.log('masuk ke 561')
                    // eslint-disable-next-line no-unused-vars
                    let filter = allDataOverview.value.filter((val)=>{
                        if(val.name === valueClass.value){
                            if(val.viewerList.length > 0){
                                val.viewerList.forEach((value)=>{
                                    if(value.name === valueStudent.value){
                                        uidStudentActive.value = value.id
                                        if(uidClassActive.value !== '' && uidSceneActive.value !== '' && uidStudentActive.value !== ''){
                                            fetching_interaction()
                                        }
                                    }                          
                                })
                            }
                        }
                    })
                    
                    // if(filter.length)
                }else {
                    
                    valueStudent.value = 'Student'
                    uidStudentActive.value = ''
                        standpointValueChart.value = [{
                        name: 'Durasi Siswa',
                        data: [0,0,0,0,0,0,0,0,0,0],
                        color:'#004F98'
                    }]
                    isLoadingChart.value = false
                }
                
            })

            // FIND STUDENT VALUE END

            // FIND TOP STUDENT
            watch(async()=>valueTopStudent.value, function(){
                isLoadingTopStudent.value = true
                let filter = allTopStudent.value.filter((val)=>{
                    if(val.name === valueTopStudent.value){
                        return val
                    }
                })
                
                if(filter.length > 0){
                    if(filter[0].topStudents.length > 0 ){
                        topRankingStudent.value = filter[0].topStudents
                    }else {
                        topRankingStudent.value = undefined
                    }
                }else {
                    topRankingStudent.value = undefined
                } 
                isLoadingTopStudent.value = false
            })

            // FIND TOP STUDENT END
            function fmtMSS(s){
                let second = Math.floor(s)
                return(second-(second%=60))/60+(9<second?' : ':' : 0')+second
            }

            const fetching_interaction=()=>{
                isLoadingChart.value = true
                 Axios.post(`https://us-central1-millea-lab.cloudfunctions.net/web/api/getStandpointData`,{
                    "owner_uid": uidUser.value,
                    "classroom_uid":uidClassActive.value,
                    "scene_uid": uidSceneActive.value,
                    "viewer_uid": uidStudentActive.value
                }).then((res)=>{
                    // console.log(res.data)
                    let dataChart = []
                    res.data.forEach((val,index)=>{
                        if(index<10){
                            dataChart.push(Math.floor(val.duration))
                        }
                    })
                    // standpointValueChart.value.data = dataChart
                    standpointValueChart.value = [{
                        name: 'Durasi Siswa',
                        data: dataChart,
                        color:'#004F98'
                    }]
                    isLoadingChart.value = false
                }).catch((err)=>{
                    console.log(err.messages)
                    standpointValueChart.value = [{
                        name: 'Durasi Siswa',
                        data: [0,0,0,0,0,0,0,0,0,0],
                        color:'#004F98'
                    }]
                    isLoadingChart.value = false
                })
            }

            const openDatePicker=()=>{
                alert('datePicker active')
            }

            const onSelectStudentChart=()=>{
                // console.log('changed into ', valueStudent.value)
                // console.log(valueStudent.value)
            }


           

            const fetch=async()=>{
                // eslint-disable-next-line no-unused-vars
                isLoginGlobal.value =  store.getters.getIsLogin
                allDataOverview.value = await store.getters.getAllOverview
                // let allDataOverviewFromAPI = await store.getters.getAllOverview
                let allDataTopStudent = await store.getters.getTopStudent
                let allDataTeacherReport = await store.getters.getTeacherReport
                console.log(allDataTeacherReport)
                allTopStudent.value = allDataTopStudent
                // allTeacherReport.value = allDataTeacherReport
                if(isLoginGlobal.value){
                    dashboardResult.value = allDataTeacherReport
                }else {
                    dashboardResult.value = [{
                    name:'STANDPOINT',
                    img:require('../assets/overview/icon_standpoint.svg'),
                    value:'0',
                    lastUpdate:'Sejak bulan lalu',
                    percentage:`0 %`
                },
                 {
                    name:'OBJECT',
                    img:require('../assets/overview/icon_object.svg'),
                    value:'0',
                    lastUpdate:'Sejak bulan lalu',
                    percentage:`0 %`
                },
                 {
                    name:'VIDEO',
                    img:require('../assets/overview/icon_video.svg'),
                    value:'0',
                    lastUpdate:'Sejak bulan lalu',
                    percentage: `0 %`
                }
                ,
                 {
                    name:'VIDEO 360',
                    img:require('../assets/overview/icon_360.svg'),
                    value:`0`,
                    lastUpdate:'Sejak bulan lalu',
                    percentage:`0 %`
                }]
                
                }

                // find data for chart

                
                if(allDataOverview.value !== undefined){
                    
                    if(allDataOverview.value.length > 0 ){
                        let allClassNameOverview = []
                        // let allSubjectOverview = []
                        // let allStudentOverview = []
        
                        allDataOverview.value.forEach((val)=>{
                            allClassNameOverview.push(val.name)
                        })
    
                        optionClass.value = allClassNameOverview // define all class name for dropdown class
                        valueClass.value = allClassNameOverview[0] // find active for fetching firstime class
                        uidClassActive.value = allDataOverview.value[0].id
                        if(allDataOverview.value[0].sceneList.length >0){ // find first subject class
                            optionScene.value = allDataOverview.value[0].sceneList
                            valueScene.value = optionScene.value[0]
                            uidSceneActive.value = optionScene.value[0].id
                        }else {
                            optionScene.value = allDataOverview.value[0].sceneList
                            valueScene.value = 'Subject'
                        }
                        
                        if(allDataOverview.value[0].viewerList.length > 0 ){ // find first student
                            optionStudent.value = allDataOverview.value[0].viewerList
                            valueStudent.value = optionStudent.value[0]
                            uidStudentActive.value = optionStudent.value[0].id
                        }else {
                            optionStudent.value = allDataOverview.value[0].viewerList
                            valueStudent.value = 'Student'
                            standpointValueChart.value = [{
                                name: 'Durasi Siswa',
                                data: [0,0,0,0,0,0,0,0,0,0],
                                color:'#004F98'
                            }]
                        }
                        // find data for chart end
                    }
                }



                // find all class from allTopStudent
                
                if(allDataTopStudent !== undefined){
                    if(allDataTopStudent.length > 0){
                        let allClass = [] 
                        allDataTopStudent.forEach((val)=>{
                            allClass.push(val.name)
                        })
                        optionTopStudent.value = allClass // define all class for all dropdown top student
                        valueTopStudent.value = optionTopStudent.value[0] // define first array for dropdown active
                        if(allDataTopStudent[0].topStudents.length > 0){
                            topRankingStudent.value= allDataTopStudent[0].topStudents // define 3 top student from first array
                        }else {  
                            topRankingStudent.value = undefined
                        }
                        //find all class from allTopStudent end
                    }else {
                        valueTopStudent.value = 'Student'
                    }
                }else {
                    topRankingStudent.value = undefined
                }
                customerDetail.value =  store.getters.getCustomerDetail
                isLoadingChart.value = false
                isLoadingTopStudent.value = false
                console.log(dashboardResult.value,' dashboard result')   
            }

            onBeforeMount(()=>{
                fetch()
            })

            return {
                path,
                optionMenu,
                optionLeftName,
                optionRightName,
                statusOverview,
                changeStatusChart,
                dashboardResult,
                date,
                Datepicker,
                openDatePicker,
                optionClass,
                optionScene,
                optionStudent,
                valueClass,
                valueScene,
                valueStudent,
                onSelectStudentChart,
                studentChartValue,
                classActive,
                sceneActive,
                studentActive,
                optionBG,
                isSkeletonLoading,
                img_loaded,
                customerName,
                isLoginGlobal,
                customerDetail,
                standpointValueOptions,
                standpointValueChart,
                allTopStudent,
                topRankingStudent,
                changeTopStudent,
                allTeacherReport,
                allDataOverview,
                valueTopStudent,
                optionTopStudent,
                uidUser,
                uidClassActive,
                uidSceneActive,
                uidStudentActive,
                isLoadingChart,
                isLoadingTopStudent,
                fetching_interaction,
                fmtMSS
                
            }
        }
    }

</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss">
    @import '../scss/Overview.scss';
    @import '../scss/Mobile/Overview.scss';
    @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap');
</style>