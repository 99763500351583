<template>
    <!-- MODALS ADD A Download Invoice -->
     <div class="modal  modal-special-object fade" id="modalSpecialObject" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" @click.self="closeModal()" >
        <div class="modal-dialog modal-dialog-centered .modal-lg">
            <div class="modal-content modal-content-download">
                <div class="modal-header">
                    <p>Standpoint {{valueActiveModals.id}}</p>
                    <div  @click="new_close()">
                        <i class="fa-solid fa-xmark"></i>
                    </div>
                </div>
                <div class="modal-body">
                      <table v-if="valueActiveModals.oldData.child_items.length > 0">
                        <tbody>
                            <tr v-for="(user,id) in valueActiveModals.oldData.child_items" :key="id">
                                <td>{{id+1}}.</td>
                                <td>
                                    {{user}}
                                </td>
                            </tr>
                        </tbody>
                      </table>
                        <div class="empty-card" v-else>
                            <p>No data to display</p>
                            <p>Try adjusting your search of filter to find what your're looking for</p>
                        </div>
                      <p>Durasi Interaksi <span>{{valueActiveModals.durationMinute}}</span> </p>
                      <p>Pengulangan <span>{{valueActiveModals.oldData.iteration}}</span></p>
                </div>
            </div>
        </div>
    </div> 
    <!-- MODALS ADD A Download Invoice -->

    <Loading v-if="isLoginGlobal"/>
    <div class="container-interaction" v-else> 
        <SideHeader/>
        <div class="container-right-interaction">
            <TopHeaderLogin/>
            <div class="main-interaction">
                <BreadCrumbHeader
                :pathPage="path"
                :optionMenu="optionMenu"
                :optionLeftName="optionLeftName"
                :optionRightName="optionRightName"
                />
                <div class="box-select-class">
                    <Multiselect
                    v-model="classValue"
                    :options="optionClass"
                    class="multiSelectClass"
                    :placeholder="classValue"
                    @input="toggleSelected(value)"
                    >
                    </Multiselect>
                    <Multiselect
                    v-model="sceneValue"
                    :options="optionScene"
                    class="multiSelectClass"
                    :placeholder="sceneValue"
                    />
                    <Multiselect
                    v-model="studentValue"
                    :options="optionStudent"
                    class="multiSelectClass"
                    :placeholder="studentValue"
                    />
                </div>
                <div class="main-box-interaction">
                    <!-- STANDPOINT -->
                    <div class="card-navigation-interaction">
                        <NavigationAnalytics  :pageStatusParams="pageStatus"/>
                    </div>
                    <div class="main-datatable-top">
                        <div class="new-card-datatable-top">
                            <div class="box-show-list">
                                <p>Standpoint</p>
                                <div class="navigation" v-if="isMobilePageActive">
                                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link active" id="pills-reporting-tab" data-bs-toggle="pill" data-bs-target="#standpointReporting" type="button" role="tab" aria-controls="pills-reporting" aria-selected="true">
                                                <i class="fa-solid fa-file-lines"></i>
                                                Reporting
                                            </button>
                                        </li>
                                        <p class="d-flex justify-content-center align-items-center"> | </p>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link" id="pills-analytics-tab" data-bs-toggle="pill" data-bs-target="#standpointAnalytics" type="button" role="tab" aria-controls="pills-analytics" aria-selected="false">
                                                <i class="fa-solid fa-chart-column"></i>
                                                Analytics
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="tab-content box-interaction-student">
                                <div id="standpointReporting" class="tab-pane fade show active table-interaction-student">
                                    <div class="interaction-top">
                                        <p>Tabel interaksi siswa terhadap Standpoint</p>
                                        <div class="new-box-interaction-top">
                                            <div class="box-new-sort">
                                                <img src="../../assets/sort.svg" alt="" id="sort_active_img" @click="open_sort_standpoint('sort_sp')" v-if="!isSortActiveSp"> 
                                                <!-- isSortActive.value = true -->
                                                <img src="../../assets/sort.svg" alt="" id="sort_active_img" @click="close_sort_standpoint('sort_sp')" v-else> 
                                                <ul class="options-sort" id="sort_sp">
                                                    <li class="sort_sp_class" v-for="(items,id) in optionStandpointInteractionSort" :key="id" @click="change_sort_standpoint(items.id,'sort_sp')">
                                                        <img src="../../assets/active_icon_dot.svg" alt="" v-if="standPointSortActive === items.id">
                                                        <img src="../../assets/active_icon_dot_white.svg" alt="" v-else>
                                                        <p>{{items.value}}</p>
                                                        <img :src="items.img" alt="" class="icon_arrow">
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="btn-export-all" @click="export_to_csv('standpoint')">
                                                <img src="../../assets/icon_download_new.svg" alt="">
                                                <!-- <p>Export All</p> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="box-interaction-table">
                                        <table class="assesment-table-detail" id="standpoint_id_table" v-if="standPointTable.length > 0 && isLoadingInteraction === false">
                                            <tr>
                                                <th>
                                                    <div class="box-item-detail">
                                                        <p>Nomor</p>     
                                                    </div>
                                                </th>
                                                <th>
                                                    <div class="box-item-detail">
                                                        <p>Special Objects</p>     
                                                    </div>
                                                </th>
                                                <th>
                                                    <div class="box-item-detail">
                                                        <p>Durasi Interaksi</p>     
                                                    </div>
                                                </th>
                                                <th>
                                                    <div class="box-item-detail">
                                                        <p>Pengulangan</p>     
                                                    </div>
                                                </th>
                                            </tr>
                                            <tbody class="tbody-interaction">
                                                <tr v-for="(user,id) in standPointTable" :key="id" :load="log(user)">
                                                    <td v-if="id >= start_page_standpoint && id < end_page_standpoint" >
                                                        <p v-if="standPointSortActive === 'sp_nomor_asc' || standPointSortActive === 'sp_nomor_desc' ">{{user.oldData.id}}.</p>
                                                        <p v-else> {{id+1}}.</p>
                                                    </td>
                                                      <td v-if="id>=start_page_standpoint && id < end_page_standpoint" @click="open_modal(id)">
                                                        <div class="new-image-profile-card">
                                                            <p>{{user.oldData.child_items.length}} Objects</p>
                                                        </div>
                                                    </td>
                                                    <td v-if="id>=start_page_standpoint && id < end_page_standpoint">
                                                        <div class="new-image-profile-card">
                                                            <p>{{user.durationMinute}}</p>
                                                        </div>
                                                    </td>
                                                    <td v-if="id>=start_page_standpoint && id < end_page_standpoint">
                                                        {{user.oldData.iteration}}
                                                    </td>
                                                </tr> 
                                            </tbody>
                                        </table>
                                        <div class="empty-card"  v-else-if="isLoadingInteraction === true">
                                            <div class="spinner-border text-primary" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        </div>  
                                        <div class="empty-card" v-else>
                                            <p>No data to display</p>
                                            <p>Try adjusting your search of filter to find what your're looking for</p>
                                        </div>
                                    </div>
                                    <div class="paginate-left" v-if="standPointTable.length > 0 && isLoadingInteraction === false">
                                        <p>Showing {{start_page_standpoint+1}} to {{end_page_standpoint}} of {{total_item_table}} entries</p>
                                            <paginate
                                            v-model="paginate_standpoint"
                                            :page-count=total_page_table
                                            :page-range="3"
                                            :margin-pages="2"
                                            :click-handler="changePageStandpoint"
                                            :prev-text="'<'"
                                            :next-text="'>'"
                                            :container-class="'pagination'"
                                            :page-class="'page-item'">
                                        </paginate>
                                    </div>
                                </div>
                                <div id="standpointAnalytics" class="tab-pane fade  box-chart-student" :class="{'active show': isMobilePageActive === false }">
                                    <div class="card-chart-student">
                                        <p>Durasi Interaksi Siswa Terhadap Standpoint</p>
                                        <Multiselect
                                            v-model="statusChartStandpoint"
                                            :options="optionStandpoint"
                                            class="multiSelectClass"
                                            :placeholder="statusChartStandpoint" 
                                        />
                                    </div>
                                    <div id="chart" v-if="statusChartStandpoint === 'Durasi Interaksi'  && isLoadingInteraction === false">
                                        <apexchart type="bar" height="320"  id="standpoint_id_chart" :options="standpointValueOptions" :series="standpointValueChart"></apexchart>
                                    </div>
                                    <div id="chart" v-else-if="statusChartStandpoint === 'Pengulangan' && isLoadingInteraction === false">
                                        <apexchart type="bar" height="320"  id="standpoint_id_chart" :options="standpointValueOptionsPengulangan" :series="standpointValueChart"></apexchart>
                                    </div>
                                    <div class="empty-card"  v-else-if="isLoadingInteraction === true">
                                        <div class="spinner-border text-primary" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                    
                                    <div class="paginate-left" v-if="standPointTable.length > 0 && isLoadingInteraction === false">
                                        <p>Showing {{start_page_chart_sp+1}} to {{end_page_chart_sp}} of {{total_item_table}} entries</p>
                                            <paginate
                                            v-model="paginate_chart_standpoint"
                                            :page-count=total_page_chart
                                            :page-range="3"
                                            :margin-pages="2"
                                            :click-handler="changeChartStandpoint"
                                            :prev-text="'<'"
                                            :next-text="'>'"
                                            :container-class="'pagination'"
                                            :page-class="'page-item'">
                                        </paginate>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- STANDPOINT -->

                    <!-- OBJECT -->
                    <div class="main-datatable-top">
                        <!-- <NavigationAnalytics  :pageStatusParams="pageStatus"/> -->
                        <div class="new-card-datatable-top">
                            <div class="box-show-list">
                                <p>Objek</p>
                                <div class="navigation" v-if="isMobilePageActive">
                                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link active" id="pills-reporting-tab" data-bs-toggle="pill" data-bs-target="#objectReporting" type="button" role="tab" aria-controls="pills-reporting" aria-selected="true">
                                                <i class="fa-solid fa-file-lines"></i>
                                                Reporting
                                            </button>
                                        </li>
                                        <p class="d-flex justify-content-center align-items-center"> | </p>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link" id="pills-analytics-tab" data-bs-toggle="pill" data-bs-target="#objectAnalytics" type="button" role="tab" aria-controls="pills-analytics" aria-selected="false">
                                                <i class="fa-solid fa-chart-column"></i>
                                                Analytics
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                                <!-- <div class="box-searching-interaction-datatable">
                                    <p>Search:</p>
                                    <input type="text" class="inp-search-datatable" placeholder="Search">
                                </div> -->
                            </div>
                            <div class="tab-content box-interaction-student">
                                <div id="objectReporting" class="tab-pane fade show active table-interaction-student">
                                    <div class="interaction-top">
                                        <p>Tabel interaksi terhadap Objek</p>
                                            <div class="new-box-interaction-top">
                                                <div class="box-new-sort">
                                                    <img src="../../assets/sort.svg" alt="" id="sort_active_img" @click="open_sort_standpoint('sort_obj')" v-if="!isSortActiveObj"> 
                                                    <!-- isSortActive.value = true -->
                                                    <img src="../../assets/sort.svg" alt="" id="sort_active_img" @click="close_sort_standpoint('sort_obj')" v-else> 
                                                    <ul class="options-sort" id="sort_obj">
                                                        <li class="sort_sp_class" v-for="(items,id) in optionObjectInteractionSort" :key="id" @click="change_sort_standpoint(items.id,'sort_obj')" >
                                                            <img src="../../assets/active_icon_dot.svg" alt="" v-if="objSortActive === items.id">
                                                            <img src="../../assets/active_icon_dot_white.svg" alt="" v-else>
                                                            <p>{{items.value}}</p>
                                                            <img :src="items.img" alt="" class="icon_arrow">
                                                        </li>
                                                    </ul>
                                                </div>
                                            <div class="btn-export-all" @click="export_to_csv('object')">
                                                <img src="../../assets/icon_download_new.svg" alt="">
                                                <!-- <p>Export All</p> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="box-interaction-table">
                                        <table class="assesment-table-detail" v-if="objectTable.length > 0 && isLoadingObject === false">
                                            <tr>
                                                <th>
                                                    <div class="box-item-detail">
                                                        <p>Nomor</p>     
                                                    </div>
                                                </th>
                                                <th>
                                                    <div class="box-item-detail">
                                                        <p>Objek</p>     
                                                    </div>
                                                </th>
                                                
                                                <th>
                                                    <div class="box-item-detail">
                                                        <p>Total Interaksi</p>     
                                                    </div>
                                                </th>
                                                <th>
                                                    <div class="box-item-detail">
                                                        <p>Durasi Interaksi</p>     
                                                    </div>
                                                </th>
                                            </tr>
                                            <tbody > 
                                                <tr v-for="(user,id) in objectTable" :key="user.id" @load="log(user)">
                                                    <td v-if="id >= start_page_object && id < end_page_object " >
                                                        <p v-if="objSortActive === 'sp_nomor_asc' || objSortActive === 'sp_nomor_desc'">{{id+1}}</p>
                                                        <p v-else> {{id+1}}.</p>
                                                    </td>
                                                    <td  v-if="id >= start_page_object && id < end_page_object " >
                                                        <div class="new-image-profile-card">
                                                            <!-- <div class="profile-card">
                                                                <img :src="`${user.img}`" alt="">
                                                            </div> -->
                                                            <p>{{user.oldData.name}}</p>
                                                        </div>
                                                    </td>
                                                    <td  v-if="id >= start_page_object && id < end_page_object " >
                                                        {{user.oldData.iteration}}
                                                    </td>
                                                    <td  v-if="id >= start_page_object && id < end_page_object " >
                                                        {{user.durationMinute}}
                                                    </td>
                                                </tr> 
                                            </tbody>
                                        </table>
                                        <div class="empty-card"  v-else-if="isLoadingObject === true">
                                            <div class="spinner-border text-primary" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        </div> 
                                        <div class="empty-card" v-else>
                                            <p>No data to display</p>
                                            <p>Try adjusting your search of filter to find what your're looking for</p>
                                        </div>
                                    </div>
                                    <div class="paginate-left" v-if="objectTable.length > 0 && isLoadingInteraction === false">
                                         <p>Showing {{start_page_object+1}} to {{end_page_object}} of {{total_item_table_object}} entries</p>
                                            <paginate
                                            v-model="paginate_object"
                                            :page-count=total_page_table_object
                                            :page-range="3"
                                            :margin-pages="2"
                                            :click-handler="changePageObject"
                                            :prev-text="'<'"
                                            :next-text="'>'"
                                            :container-class="'pagination'"
                                            :page-class="'page-item'">
                                        </paginate>
                                    </div>
                                </div>
                                <div id="objectAnalytics" class="tab-pane fade  box-chart-student" :class="{'active show': isMobilePageActive === false }">
                                    <div class="card-chart-student">
                                        <p>Durasi Interaksi Terhadap Objek</p>
                                        <Multiselect
                                            v-model="statusChartObject"
                                            :options="optionObj"
                                            class="multiSelectClass"
                                            :placeholder="statusChartObject"
                                        />
                                    </div>
                                    <div id="chart" v-if="statusChartObject === 'Durasi Interaksi' && isLoadingObject === false" >
                                        <apexchart type="bar" height="320" :options="objectValueOptions" :series="objectValueChart"></apexchart>
                                    </div>
                                     <div id="chart" v-else-if="statusChartObject === 'Pengulangan' && isLoadingObject === false">
                                        <apexchart type="bar" height="320"  id="standpoint_id_chart" :options="objectValueOptionsPengulangan" :series="objectValueChart"></apexchart>
                                    </div>
                                    <div class="empty-card"  v-else-if="isLoadingObject === true">
                                        <div class="spinner-border text-primary" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                    <div class="paginate-left" v-if="objectTable.length > 0 && isLoadingInteraction === false">
                                        <p>Showing {{start_page_chart_obj+1}} to {{end_page_chart_obj}} of {{total_item_table_object}} entries</p>
                                            <paginate
                                            v-model="paginate_chart_object"
                                            :page-count=total_page_chart_object
                                            :page-range="3"
                                            :margin-pages="2"
                                            :click-handler="changeChartObject"
                                            :prev-text="'<'"
                                            :next-text="'>'"
                                            :container-class="'pagination'"
                                            :page-class="'page-item'">
                                        </paginate>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>         
                    <!-- OBJECT -->

                    <!-- Video -->
                    <div class="main-datatable-top">
                        <!-- <NavigationAnalytics  :pageStatusParams="pageStatus"/> -->
                        <div class="new-card-datatable-top">
                            <div class="box-show-list">
                                <p>Video</p>
                                <div class="navigation" v-if="isMobilePageActive">
                                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link active" id="pills-reporting-tab" data-bs-toggle="pill" data-bs-target="#videoReporting" type="button" role="tab" aria-controls="pills-reporting" aria-selected="true">
                                                <i class="fa-solid fa-file-lines"></i>
                                                Reporting
                                            </button>
                                        </li>
                                        <p class="d-flex justify-content-center align-items-center"> | </p>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link" id="pills-analytics-tab" data-bs-toggle="pill" data-bs-target="#videoAnalytics" type="button" role="tab" aria-controls="pills-analytics" aria-selected="false">
                                                <i class="fa-solid fa-chart-column"></i>
                                                Analytics
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="tab-content box-interaction-student">
                                <div id="videoReporting" class="tab-pane fade show active table-interaction-student">
                                    <div class="interaction-top">
                                        <p>Tabel interaksi siswa terhadap Video</p>
                                        <div class="new-box-interaction-top">
                                            <div class="box-new-sort">
                                                <img src="../../assets/sort.svg" alt="" id="sort_active_img" @click="open_sort_standpoint('sort_vid')" v-if="!isSortActiveVid"> 
                                                <!-- isSortActive.value = true -->
                                                <img src="../../assets/sort.svg" alt="" id="sort_active_img" @click="close_sort_standpoint('sort_vid')" v-else> 
                                                <ul class="options-sort" id="sort_vid">
                                                    <li class="sort_sp_class" v-for="(items,id) in optionVideoSort" :key="id" @click="change_sort_standpoint(items.id,'sort_vid')" >
                                                        <img src="../../assets/active_icon_dot.svg" alt="" v-if="videoSortActive === items.id">
                                                        <img src="../../assets/active_icon_dot_white.svg" alt="" v-else>
                                                        <p>{{items.value}}</p>
                                                        <img :src="items.img" alt="" class="icon_arrow">
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="btn-export-all" @click="export_to_csv('video')">
                                                <img src="../../assets/icon_download_new.svg" alt="">
                                                <!-- <p>Export All</p> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="box-interaction-table">
                                        <table class="assesment-table-detail" v-if="videoTable.length > 0 && isLoadingVideo === false">
                                            <tr>
                                                <th>
                                                    <div class="box-item-detail">
                                                        <p>Nomor</p>     
                                                    </div>
                                                </th>
                                                <th>
                                                    <div class="box-item-detail">
                                                        <p>Video</p>     
                                                    </div>
                                                </th>
                                                <th>
                                                    <div class="box-item-detail">
                                                        <p>Durasi Video</p>     
                                                    </div>
                                                </th>
                                                <th>
                                                    <div class="box-item-detail">
                                                        <p>Durasi Siswa Menonton</p>     
                                                    </div>
                                                </th>
                                            </tr>
                                            <tbody class="tbody-interaction" v-if="videoTable.length > 0"> 
                                                <tr v-for="(user,id) in videoTable" :key="user.id">
                                                    <td v-if="id >= start_page_video && id < end_page_video " >
                                                        <p v-if="videoSortActive === 'vid_sort_1' || videoSortActive === 'vid_sort_2'">{{id+1}}.</p>
                                                        <p v-else> {{id+1}}.</p>
                                                    </td>
                                                    <td v-if="id >= start_page_video && id < end_page_video ">
                                                        <div class="new-image-profile-card">
                                                            <p>{{user.oldData.title}}</p>
                                                        </div>
                                                    </td>
                                                    <td v-if="id >= start_page_video && id < end_page_video ">
                                                        {{user.durationMinute}}
                                                    </td>
                                                    <td v-if="id >= start_page_video && id < end_page_video ">
                                                        {{user.durationMinuteSeen}}
                                                    </td>
                                                </tr> 
                                            </tbody>
                                        </table>
                                        <div class="empty-card"  v-else-if="isLoadingVideo === true">
                                            <div class="spinner-border text-primary" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        </div> 
                                        <div class="empty-card" v-else>
                                            <p>No data to display</p>
                                            <p>Try adjusting your search of filter to find what your're looking for</p>
                                        </div>
                                    </div>
                                    <div class="paginate-left" v-if="videoTable.length > 0 && isLoadingInteraction === false">
                                        <p>Showing {{start_page_video+1}} to {{end_page_video}} of {{total_item_table_video}} entries</p>
                                            <paginate
                                            v-model="paginate_video"
                                            :page-count=total_page_table_video
                                            :page-range="3"
                                            :margin-pages="2"
                                            :click-handler="changePageVideo"
                                            :prev-text="'<'"
                                            :next-text="'>'"
                                            :container-class="'pagination'"
                                            :page-class="'page-item'">
                                        </paginate>
                                    </div>
                                </div>
                                <div id="videoAnalytics" class="tab-pane fade box-chart-student" :class="{'active show': isMobilePageActive === false }">
                                    <div class="card-chart-student">
                                        <p>Durasi Interaksi Siswa Terhadap Video</p>
                                        <Multiselect
                                            v-model="statusChartVideo"
                                            :options="videoObj"
                                            class="multiSelectClass"
                                            :placeholder="statusChartVideo"
                                        />
                                    </div>
                                    <div id="chart" v-if="statusChartVideo === 'Column' && isLoadingVideo === false">
                                        <apexchart type="bar" height="320" :options="videoValueOptions" :series="videoValueChart"></apexchart>
                                    </div>
                                    <div class="box-donut-chart" v-if="statusChartVideo === 'Donut' && isLoadingVideo === false">
                                        <pie-chart
                                            
                                            class="donut-chart" art     
                                            :donut="true"
                                            :data="video_percentage"
                                            :colors="['#004F98','#E8E8E8',]"
                                            borderWidth="10px"
                                            legend="bottom"
                                            
                                        ></pie-chart>
                                        <div class="box-donut-right">
                                             <div class="card-donut">
                                                <p>
                                                    <span>{{allDataVideo.percentage.toFixed(2)}}% </span>
                                                </p>
                                                <p>
                                                       
                                                    Video ditonton hingga selesai </p>
                                            </div>
                                            <div class="card-donut">
                                                <p>
                                                    <span>{{allDataVideo.total_seen_duration}} </span>
                                                </p>
                                                <p>
                                                        <span>
                                                        <img src="../../assets/icon_video_blue.svg" alt="">
                                                    </span>
                                                    Durasi Siswa Menonton</p>
                                            </div>
                                            <div class="card-donut">
                                                <p>
                                                    <span>{{allDataVideo.total_duration}} </span>
                                                </p>
                                                <p>
                                                    <span>
                                                        <img src="../../assets/icon_gray_video.svg" alt="">
                                                    </span>
                                                    Durasi Video</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="empty-card"  v-else-if="isLoadingVideo === true">
                                        <div class="spinner-border text-primary" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                    <div class="paginate-left" v-if=" videoTable.length > 0 && statusChartVideo === 'Column' && isLoadingInteraction === false">
                                         <p>Showing {{start_page_chart_video+1}} to {{end_page_chart_video}} of {{total_item_table_video}} entries</p>
                                            <paginate
                                            v-model="paginate_chart_video"
                                            :page-count=total_page_chart_video
                                            :page-range="3"
                                            :margin-pages="2"
                                            :click-handler="changeChartVideo"
                                            :prev-text="'<'"
                                            :next-text="'>'"
                                            :container-class="'pagination'"
                                            :page-class="'page-item'">
                                        </paginate>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>        
                    <!-- Video -->

                    <!-- Video 360 -->
                     <div class="main-datatable-top">
                        <!-- <NavigationAnalytics  :pageStatusParams="pageStatus"/> -->
                        <div class="new-card-datatable-top">
                            <div class="box-show-list">
                                <p>Video 360<span>&#176;</span></p>
                                <div class="navigation" v-if="isMobilePageActive">
                                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link active" id="pills-reporting-tab" data-bs-toggle="pill" data-bs-target="#vidReporting" type="button" role="tab" aria-controls="pills-reporting" aria-selected="true">
                                                <i class="fa-solid fa-file-lines"></i>
                                                Reporting
                                            </button>
                                        </li>
                                        <p class="d-flex justify-content-center align-items-center"> | </p>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link" id="pills-analytics-tab" data-bs-toggle="pill" data-bs-target="#vidAnalytics" type="button" role="tab" aria-controls="pills-analytics" aria-selected="false">
                                                <i class="fa-solid fa-chart-column"></i>
                                                Analytics
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                                     
                            </div>
                            <div class="tab-content box-interaction-student">
                                <div id="vidReporting" class="tab-pane fade show active table-interaction-student">
                                    <div class="interaction-top">
                                        <p>Tabel interaksi siswa terhadap Video</p>
                                        <div class="new-box-interaction-top">
                                            <div class="box-new-sort">
                                                <img src="../../assets/sort.svg" alt="" id="sort_active_img" @click="open_sort_standpoint('sort_vid360')" v-if="!isSortActiveVid360"> 
                                                <!-- isSortActive.value = true -->
                                                <img src="../../assets/sort.svg" alt="" id="sort_active_img" @click="close_sort_standpoint('sort_vid360')" v-else> 
                                                <ul class="options-sort" id="sort_vid360">
                                                    <li class="sort_sp_class" v-for="(items,id) in optionVideo360Sort" :key="id" @click="change_sort_standpoint(items.id,'sort_vid360')" >
                                                        <img src="../../assets/active_icon_dot.svg" alt="" v-if="video360SortActive === items.id">
                                                        <img src="../../assets/active_icon_dot_white.svg" alt="" v-else>
                                                        <p>{{items.value}}</p>
                                                        <img :src="items.img" alt="" class="icon_arrow">
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="btn-export-all" @click="export_to_csv('video360')">
                                                <img src="../../assets/icon_download_new.svg" alt="">
                                                <!-- <p>Export All</p> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="box-interaction-table">
                                        <table class="assesment-table-detail" v-if="videoTable360.length > 0 && isLoadingVideo360 === false">
                                            <tr>
                                                <th>
                                                    <div class="box-item-detail">
                                                        <p>Nomor</p>     
                                                    </div>
                                                </th>
                                                <th>
                                                    <div class="box-item-detail">
                                                        <p>Video 360<span>&#176;</span></p>
                                                              
                                                    </div>
                                                </th>
                                                <th>
                                                    <div class="box-item-detail">
                                                        <p>Durasi Video</p>     
                                                    </div>
                                                </th>
                                                <th>
                                                    <div class="box-item-detail">
                                                        <p>Durasi Siswa Menonton</p>     
                                                    </div>
                                                </th>
                                            </tr>
                                            <tbody class="tbody-interaction"> 
                                                <tr v-for="(user,id) in videoTable360" :key="user.id">
                                                    <td v-if="id >= start_page_video360 && id < end_page_video360 " >
                                                        <p v-if="video360SortActive === 'sp_nomor_asc' || video360SortActive === 'sp_nomor_desc'">{{id+1}}.</p>
                                                        <p v-else> {{id+1}}.</p>
                                                    </td>
                                                    <td  v-if="id >= start_page_video360 && id < end_page_video360 ">
                                                        <!-- <div class="new-image-profile-card"> -->
                                                            <p>{{user.oldData.title}}</p>
                                                        <!-- </div> -->
                                                    </td>
                                                    <td  v-if="id >= start_page_video360 && id < end_page_video360 ">
                                                        {{user.durationMinute}}
                                                    </td>
                                                    <td  v-if="id >= start_page_video360 && id < end_page_video360 ">
                                                        {{user.durationMinuteSeen}}
                                                    </td>
                                                </tr> 
                                            </tbody>
                                        </table>
                                        <div class="empty-card"  v-else-if="isLoadingVideo360 === true">
                                            <div class="spinner-border text-primary" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        </div> 
                                        <div class="empty-card" v-else>
                                            <p>No data to display</p>
                                            <p>Try adjusting your search of filter to find what your're looking for</p>
                                        </div>
                                    </div>
                                    <div class="paginate-left"  v-if="videoTable360.length > 0 && isLoadingInteraction === false">
                                        <p>Showing {{start_page_video360+1}} to {{end_page_video360}} of {{total_item_table_video360}} entries</p>
                                            <paginate
                                            v-model="paginate_video360"
                                            :page-count=total_page_table_video360
                                            :page-range="3"
                                            :margin-pages="2"
                                            :click-handler="changePageVideo"
                                            :prev-text="'<'"
                                            :next-text="'>'"
                                            :container-class="'pagination'"
                                            :page-class="'page-item'">
                                        </paginate>
                                    </div>
                                </div>
                                <div id="vidAnalytics" class="tab-pane fade box-chart-student" :class="{'active show': isMobilePageActive === false }">
                                    <div class="card-chart-student">
                                        <p>Durasi Interaksi Siswa Terhadap Video 360</p>
                                        <Multiselect
                                            v-model="statusChartVideo360"
                                            :options="videoObj360"
                                            class="multiSelectClass"
                                            :placeholder="statusChartVideo360"
                                        />
                                    </div>
                                    <div id="chart" v-if="statusChartVideo360 === 'Column' && isLoadingVideo360 === false">
                                        <apexchart type="bar" height="310" :options="video360ValueOptions" :series="video360ValueChart"></apexchart>
                                    </div>
                                    <div class="box-donut-chart" v-if="statusChartVideo360 === 'Donut' && isLoadingVideo360 === false">
                                        <pie-chart
                                            
                                            class="donut-chart" art     
                                            :donut="true"
                                            :data="video360_percentage"
                                            :colors="['#004F98','#E8E8E8']"
                                            borderWidth="10px"
                                            legend="bottom"
                                        ></pie-chart>
                                        <!-- <p id="video_id_watch" >
                                            <span>
                                                {{allDataVideo360.percentage.toFixed(2)}}%
                                            </span><br>
                                            Video ditonton hingga selesai</p> -->
                                        <div class="box-donut-right">
                                             <div class="card-donut">
                                                <p>
                                                    <span>{{allDataVideo360.percentage.toFixed(2)}}% </span> 
                                                </p>
                                                <p>
                                                   
                                                    Video ditonton hingga selesai</p>
                                            </div>
                                            <div class="card-donut">
                                                <p>
                                                    <span>{{allDataVideo360.total_seen_duration }} </span> 
                                                </p>
                                                <p>
                                                    <span>
                                                        <img src="../../assets/icon_video_blue.svg" alt="">
                                                    </span>
                                                    Durasi Siswa Menonton</p>
                                            </div>
                                                <div class="card-donut">
                                                <p>
                                                    <span>
                                                        {{allDataVideo360.total_duration}}
                                                    </span>
                                                </p>
                                                <p>
                                                    <span>
                                                        <img src="../../assets/icon_gray_video.svg" alt="">
                                                    </span>
                                                    Durasi Video</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="empty-card"  v-else-if="isLoadingVideo360 === true">
                                        <div class="spinner-border text-primary" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                    <div class="paginate-left" v-if=" videoTable360.length > 0 && statusChartVideo360 === 'Column' && isLoadingInteraction === false">
                                        <p>Showing {{start_page_chart_video360+1}} to {{end_page_chart_video360}} of {{total_item_table_video360}} entries</p>
                                            <paginate
                                            v-model="paginate_chart_video360"
                                            :page-count=total_page_chart_video360
                                            :page-range="3"
                                            :margin-pages="2"
                                            :click-handler="changeChartVideo360"
                                            :prev-text="'<'"
                                            :next-text="'>'"
                                            :container-class="'pagination'"
                                            :page-class="'page-item'">
                                        </paginate>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>            
                    <!-- Video 360 -->
                </div>
            </div>
        </div>
    </div>
    <div class="modal-backdrop fade show" id="backdrop"  style="display: none;" ></div>
</template>


<script>
    import SideHeader from '../../components/Header/SideHeader.vue'
    import TopHeaderLogin from '../../components/Header/TopHeaderLogin.vue'
    import BreadCrumbHeader from '../../components/Header/BreadCrumbHeader.vue'
    import {ref,onMounted, onBeforeMount,watch} from 'vue'
    import VueSelect from 'vue-next-select'
    import 'vue-next-select/dist/index.min.css'
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    import Multiselect from '@vueform/multiselect'
    import NavigationAnalytics from '@/components/Navigation/Navigation.vue'
    import Paginate from 'vuejs-paginate-next';
    import {useStore} from 'vuex'
    import Loading from '../../components/Loading/Loading.vue'
    import Axios from 'axios'
    


    export default {
        name:'AnalyticsInteraction',
        components:{
            SideHeader,
            TopHeaderLogin,
            BreadCrumbHeader,
            // FooterOverview,
            // TreeselecclassValuet,
            Multiselect,
            NavigationAnalytics,
            Paginate,
            Loading
            
        },

        
        setup(){
            const store = useStore()
            
            let uidCustomer = ref()
            let isLoginGlobal = ref(true)
            let path = ref('Analytics')
            let optionMenu = ref(false)
            let optionLeftName = ref ('New')
            let optionRightName = ref ('Filter')
            let value = ref (null)
            let classValue = ref('Class')
            let sceneValue = ref('Scene')
            let studentValue = ref('Student')
            let options = ref(['Batman','Robin','Joker',])
            let optionClass = ref()
            let optionScene = ref()
            let optionStudent = ref()
            let pathQuizAct = ref('/analyticsQuizAct') // direction untuk path. testing. gak jadi di pake
            let valueActiveModals= ref(
                
                {
                    id:1,
                    durationMinute:'123',
                    oldData:{
                    child_items:[],
                    iteration:'123'
                }}
            )
            console.log(valueActiveModals.value)
            // let allDataFromApi = ref([])
            let pageStatus = 'analyticsInteraction'
            let studentChartValue = ref({'1':10,'2':20,'3':30,'4':20,'May':15,"June":'17',"July":21,"August":30,"September":12,"October":22,"November":88,"December":12})
            let page_paginate=ref(0)
            let valueClass = ref('Class')
            let optionSort = ref([     
                { value: 'Nama Siswa', name: 'Nama Siswa', icon:require('../../assets/overview/arrow_navy.svg') },
                { value: 'Score', name: 'Score', icon:require('../../assets/overview/arrow_navy.svg') },
            ])
            let sort_by_active=ref('Sort By')
            let isMobilePageActive = ref(false)

            let isLoadingInteraction = ref(true)
            let isLoadingObject = ref(true)
            let isLoadingVideo = ref(true)
            let isLoadingVideo360 = ref(true)

            let classUidActive = ref('')
            let sceneUidActive = ref('')
            let studentUidActive = ref('')

            let allDataOverview = ref()

            watch(async()=>classValue.value,function(){
                // console.log(classValue.value)
                if(classValue.value !== null &&  classValue.value.length > 0) {
                    // console.log('masuk ke if')
                    let allSceneValue = []
                    let allSceneUid = []
                    allDataOverview.value.filter((val)=>{
                        if(val.name === classValue.value){
                            // console.log(val)
                            classUidActive.value = val.id
                            // console.log(val)
                            val.sceneList.forEach((value)=>{
                                allSceneValue.push(value.name)
                                allSceneUid.push(value.id)
                            })
                        }
                    })

                    if(allSceneValue.length > 0){
                        optionScene.value = allSceneValue
                        sceneValue.value = allSceneValue[0]
                        sceneUidActive.value = allSceneUid[0]
                        

                    }else {
                        sceneValue.value = 'Scene'
                        studentValue.value = 'Student'
                        sceneUidActive.value = ''
                        studentUidActive.value = ''
                        optionScene.value = []
                        optionStudent.value = []
                        standPointTable.value = []
                        videoTable.value = []
                        objectTable.value = []
                        videoTable360.value = []
                        // console.log('masuk ke 320 else. harusnya kosong scene sama student')
                        findDataForTableChart(paginate_chart_standpoint.value,'standpoint')
                        findDataForTableChart(paginate_chart_standpoint.value,'object')
                        findDataForTableChart(paginate_chart_standpoint.value,'video')
                        findDataForTableChart(paginate_chart_standpoint.value,'video360')
                    }
                    
                    console.log(optionScene.value)
                    // console.log(optionStudent.value)
                }else {
                    classValue.value = 'Class'
                    sceneValue.value = 'Scene'
                    studentValue.value = 'Student'
                    classUidActive.value = ''
                    studentUidActive.value = ''
                    sceneUidActive.value = ''
                    optionScene.value = []
                    studentValue.value = []
                    // console.log('masuk ke else berarti undefined ')
                }
                if(classUidActive.value !== '' && sceneUidActive.value !== '' && studentUidActive.value  !== ''){
                    fetching_interaction()
                    fetching_object()
                    fetching_video()
                    fetching_video360()
                }
            })
            watch(async()=>sceneValue.value,(()=>{
  
                if(sceneValue.value !== 'Scene' && sceneValue.value !== null){
                    let allStudentValue = []
                    let allStudentUid = []
                    allDataOverview.value.filter((val)=>{
                        if(val.name === classValue.value){
                            // console.log(val)
                            
                            val.viewerList.forEach((value)=>{
                                allStudentValue.push(value.name)
                                allStudentUid.push(value.id)
                            })
                            val.sceneList.forEach((value)=>{
                                if(value.name === sceneValue.value){
                                    sceneUidActive.value = value.id
                                }
                            })
                        }
                    })
                    optionStudent.value = allStudentValue
                    // console.log(allStudentValue,'student uid')
                    // console.log(allStudentUid)
                    if(optionStudent.value.length > 0){
                        studentValue.value = optionStudent.value[0]
                        studentUidActive.value = allStudentUid[0]
                        
                    }
                    // console.log(classUidActive.value,' class uid')
                    // console.log(sceneUidActive.value,'scene uid')
                    // console.log(studentUidActive.value,'student uid')
                    if(studentUidActive.value !== ''){
                        fetching_interaction()
                        fetching_object()
                        fetching_video()
                        fetching_video360()
                    }
                }else {
                    sceneValue.value= 'Scene'
                    studentValue.value = 'Student'
                    studentUidActive.value = ''
                    sceneUidActive.value = ''
                    optionStudent.value = []
                    // console.log('masuk ke else', optionStudent.value)
                }
                 if(classUidActive.value !== '' && sceneUidActive.value !== '' && studentUidActive.value  !== ''){
                    fetching_interaction()
                    fetching_object()
                    fetching_video()
                    fetching_video360()
                    // console.log(classUidActive.value,sceneUidActive.value,studentUidActive.value)
                }
            }))
            watch(async()=>studentValue.value, (()=>{
                // console.log(localStorage.getItem('idStorage'))
                //  console.log(classUidActive.value, 'class actiev')
                // console.log(sceneUidActive.value,' scene active')
                // console.log(studentUidActive.value,' student active')
                // console.log(studentValue.value,' student name', studentValue.value !== null,  typeof studentValue.value)
                if(studentValue.value !== 'Student' && studentValue.value !== null){
                    // console.log('masuk ke if student value ada')
                    let scene_id = []
                    let student_id = []
                    allDataOverview.value.filter((val)=>{
                        if(val.name === classValue.value){
                            //
                            if(val.sceneList.length > 0){
                                scene_id = val.sceneList.filter((value)=>{
                                    if(value.name === sceneValue.value){
                                        // console.log(value,' value scene')
                                        return value.id
                                    }
                                })
                            }
                            if(val.viewerList.length > 0 ){
                               student_id =  val.viewerList.filter((value)=>{
                                    if(value.name === studentValue.value){
                                        // console.log(value,' student value')
                                        return value.id
                                    }
                                })
                            }  
                        }
                    })
                    if(scene_id.length > 0 ){
                        sceneUidActive.value = scene_id[0].id
                        studentUidActive.value = student_id[0].id 
                    }
                    if(scene_id[0] !== undefined){
                        if(student_id[0] !== undefined){
                            fetching_interaction()
                            fetching_object()
                            fetching_video()
                            fetching_video360()
                        }else {
                            studentUidActive.value = ''
                        }
                    }else {
                        sceneUidActive.value = ''
                    }
                    // console.log(scene_id[0])
                    // console.log(student_id[0])

                }else {
                    studentValue.value = 'Student'
                    studentUidActive.value = ''
                    
                    standPointTable.value = []
                    videoTable.value = []
                    objectTable.value = []
                    videoTable360.value = []
                    findDataForTableChart(paginate_chart_standpoint.value,'standpoint')
                    findDataForTableChart(paginate_chart_standpoint.value,'object')
                    findDataForTableChart(paginate_chart_standpoint.value,'video')
                    findDataForTableChart(paginate_chart_standpoint.value,'video360')
                    // console.log(videoTable.value)
                    // console.log('masuk ke else student')
                }
                if(classUidActive.value !== '' && sceneUidActive.value !== '' && studentUidActive.value  !== ''){
                    fetching_interaction()
                    fetching_object()
                    fetching_video()
                    fetching_video360()
                    // console.log(classUidActive.value,sceneUidActive.value,studentUidActive.value)
                }
            }))


            // STANDPOINT STATE 
            let paginate_standpoint = ref(1)
            let start_page_standpoint = ref(0)
            let end_page_standpoint = ref(5)
            let total_page_table = ref(0)
            let total_page_chart = ref(0)
            let total_item_table = ref(0) // 
            let optionStandpointInteractionSort = ref([]) // state buat sort dropdown table
            // status chart => yg nentuin dia ngebuka column chart / donut chart
            let isSortActiveSp = ref(false) // Open or Close button sort
            
            let paginate_chart_standpoint=ref(1)
            let start_page_chart_sp = ref(0)
            let end_page_chart_sp = ref(10)
            
            let standPointSortActive = ref('sp_nomor_asc') // sort yang lagi active
            let statusChartObject = ref('Durasi Interaksi')
            let statusChartStandpoint=ref('Durasi Interaksi') // dropdown chart
            let optionStandpoint = ref() // option dropdown chart

            
            let standPointTable = ref([]) // ini untuk data table standpoint
            let standpointValueChart = ref()
            let updateStandpointValueOptions=ref()

            const log=()=>{
                // console.log(params)
            }
            let standpointValueOptions=ref(
                {
                    chart:{
                    type:'bar',
                    height:320
                        },plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '70%',
                        endingShape: 'rounded'
                    },
                    },dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    xaxis: {
                        categories: [],
                    },
                    yaxis: {
                        title: {
                            text: 'Durasi Interaksi (second)'
                        }
                    },
                    fill: {
                        opacity: 1 ,
                    }, 
                    tooltip: {
                        y: {
                            formatter: function (val) {
                            return "" + val + " second"
                            }
                        }
                    }
                }
            )
            let standpointValueOptionsPengulangan=ref(
                {
                    chart:{
                    type:'bar',
                    height:320
                        },
                    plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '70%',
                        endingShape: 'rounded'
                    },
                    },dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    xaxis: {
                        categories: [],
                        title:{
                            text:'Pengulangan'
                        }
                    },
                    yaxis: {
                        title: {
                            text: 'Durasi Pengulangan (second)'
                        }
                    },
                    fill: {
                        opacity: 1 ,
                    }, 
                    tooltip: {
                        y: {
                            formatter: function (val) {
                            
                            return ": " + val 
                            }
                        }
                    }
                }
            )
            const open_sort_standpoint=(params)=>{
                if(params === 'sort_sp'){
                    isSortActiveSp.value = true
                }else if (params === 'sort_obj'){
                    isSortActiveObj.value = true
                }else if (params === 'sort_vid'){
                    isSortActiveVid.value = true
                }else if (params === 'sort_vid360'){
                    isSortActiveVid360.value = true
                }
                    document.getElementById(params).style.display = 'block'
            }
            const close_sort_standpoint=(params)=>{
                if(params === 'sort_sp'){
                    isSortActiveSp.value = false
                }else if (params === 'sort_obj'){
                    isSortActiveObj.value = false
                }else if (params === 'sort_vid'){
                    isSortActiveVid.value = false
                }else if (params === 'sort_vid360'){
                    isSortActiveVid360.value = false
                }
                    document.getElementById(`${params}`).style.display = 'none'
            }
            const change_sort_standpoint=(sort_parent,sort_child)=>{
                if(sort_child === 'sort_sp'){
                    standPointSortActive.value= sort_parent
                    sortFuncStandpoint(standPointTable.value)
                }else if(sort_child === 'sort_obj'){
                    objSortActive.value = sort_parent
                    sortFuncObject(objectTable.value,)
                }else if (sort_child === 'sort_vid'){
                    videoSortActive.value = sort_parent
                    sortFuncVideo(videoTable.value)
                }else if (sort_child === 'sort_vid360'){
                    video360SortActive.value = sort_parent
                    sortFuncVideo360(videoTable360.value)
                }
                

                close_sort_standpoint(sort_child)

                // document.getElementsByClassName('sort_sp_class').classList.remove('sort_active_id')
                // document.getElementById(id).addClasslist('sort_active_id')         
            }

            const changePageStandpoint=(pageNum)=>{
                let new_end = pageNum * 5
                let new_start = new_end - 5 
    
                start_page_standpoint.value =  new_start
                end_page_standpoint.value = new_end
            }
            const changePageObject=(pageNum)=>{
                let new_end = pageNum * 5
                let new_start = new_end - 5 
    
                start_page_object.value =  new_start
                end_page_object.value = new_end
            }
            const changePageVideo=(pageNum)=>{
                let new_end = pageNum * 5
                let new_start = new_end - 5 
    
                start_page_video.value =  new_start
                end_page_video.value = new_end
            }

            const changeChartStandpoint=(pageNum)=>{
                let new_end = pageNum * 10
                let new_start = new_end - 10
    
                start_page_chart_sp.value =  new_start
                end_page_chart_sp.value = new_end
                paginate_chart_standpoint.value = pageNum

                findDataForTableChart(pageNum,'standpoint')
                
            }

            const changeChartObject=(pageNum)=>{
                let new_end = pageNum * 10
                let new_start = new_end - 10
    
                start_page_chart_obj.value =  new_start
                end_page_chart_obj.value = new_end
                paginate_chart_object.value = pageNum
                // console.log(start_page_chart_obj.value,'start page')
                // console.log(end_page_chart_obj.value,' end page')
                // console.log(paginate_chart_object.value, 'paginate chart')

                findDataForTableChart(pageNum,'object')
            }

            const changeChartVideo=(pageNum)=>{
                let new_end = pageNum * 10
                let new_start = new_end - 10
    
                start_page_chart_video.value =  new_start
                end_page_chart_video.value = new_end
                paginate_chart_video.value = pageNum

                findDataForTableChart(pageNum,'video')
            }


            const changeChartVideo360=(pageNum)=>{
                let new_end = pageNum * 10
                let new_start = new_end - 10
    
                start_page_chart_video360.value =  new_start
                end_page_chart_video360.value = new_end
                paginate_chart_video360.value = pageNum

                findDataForTableChart(pageNum,'video360')
            }
    
            const findDataForTableChart=(pageNum,params)=>{

                if(params === 'standpoint'){
                    
                    paginate_chart_standpoint.value = pageNum
                    let dataChartStandpoint = []
                    let idChartStandpoint = []
                    let max_item_per_page = pageNum * 10
                    
                    let start_item = max_item_per_page - 10 // index untuk filter item chart
                    if(statusChartStandpoint.value === 'Durasi Interaksi'){
                        standPointTable.value.map((val,index)=>{
                            if(index >= start_item && index < max_item_per_page){
                                let find_id = index+1
                                dataChartStandpoint.push(val.durationSecond)
                                idChartStandpoint.push(find_id)
                            }
                        })
                        standpointValueChart.value = [{
                            name: 'Durasi Siswa',
                            data: dataChartStandpoint,
                            color:'#004F98'
                        }]
                        standpointValueOptions.value = {
                            chart:{
                            type:'bar',
                            height:320
                                },plotOptions: {
                            bar: {
                                horizontal: false,
                                columnWidth: '70%',
                                endingShape: 'rounded'
                            },
                            },dataLabels: {
                                enabled: false
                            },
                            stroke: {
                                show: true,
                                width: 2,
                                colors: ['transparent']
                            },
                            xaxis: {
                                categories: idChartStandpoint,
                                title:{
                                    text:'Standpoint'
                                }
                            },
                            yaxis: {
                                title: {
                                    text: 'Durasi Interaksi (Second)'
                                }
                            },
                            fill: {
                                opacity: 1 ,
                            }, 
                            tooltip: {
                                y: {
                                    formatter: function (val) {
                                    return "" + val + " Second"
                                    }
                                }
                            }
                        }
                    }else if(statusChartStandpoint.value === 'Pengulangan'){
                        standPointTable.value.map((val,index)=>{
                            if(index >= start_item && index < max_item_per_page){
                                let find_id = index+1
                                dataChartStandpoint.push(val.oldData.iteration)
                                idChartStandpoint.push(find_id)
                            }
                        })
                        standpointValueChart.value = [{
                            name: 'Pengulangan',
                            data: dataChartStandpoint,
                            color:'#004F98'
                        }]
                        standpointValueOptionsPengulangan.value = {
                            chart:{
                            type:'bar',
                            height:320
                                },plotOptions: {
                            bar: {
                                horizontal: false,
                                columnWidth: '70%',
                                endingShape: 'rounded'
                            },
                            },dataLabels: {
                                enabled: false
                            },
                            stroke: {
                                show: true,
                                width: 2,
                                colors: ['transparent']
                            },
                            xaxis: {
                                categories: idChartStandpoint,
                                title:{
                                    text:'Standpoint'
                                }
                            },
                            yaxis: {
                                title: {
                                    text: 'Durasi Pengulangan'
                                }
                            },
                            fill: {
                                opacity: 1 ,
                            }, 
                            tooltip: {
                                y: {
                                    formatter: function (val) {
                                    return "" + val + " Pengulangan"
                                    }
                                }
                            }
                        }
                    }      
                    standpointValueOptions.value.xaxis.categories = idChartStandpoint
                    standpointValueOptionsPengulangan.value.xaxis.categories = idChartStandpoint        
                }else if (params === 'object'){
                    paginate_chart_object.value = pageNum
                    let dataChartObject = []
                    let idChartStandpoint = []
                    let max_item_per_page = pageNum * 10
                    
                    let start_item = max_item_per_page - 10 // index untuk filter item chart
                    if(statusChartObject.value === 'Durasi Interaksi'){
                        objectTable.value.map((val,index)=>{
                            if(index >= start_item && index < max_item_per_page){
                                let find_id = index+1
                                dataChartObject.push(val.durationSecond)
                                idChartStandpoint.push(find_id)
                            }
                        })
                        // console.log(objectTable.value,'1086')
                        objectValueChart.value = [{
                            name: 'Durasi Siswa',
                            data: dataChartObject,
                            color:'#004F98'
                        }]
                        objectValueOptions.value = {
                            chart:{
                            type:'bar',
                            height:320
                                },plotOptions: {
                            bar: {
                                horizontal: false,
                                columnWidth: '70%',
                                endingShape: 'rounded'
                            },
                            },dataLabels: {
                                enabled: false
                            },
                            stroke: {
                                show: true,
                                width: 2,
                                colors: ['transparent']
                            },
                            xaxis: {
                                categories: idChartStandpoint,
                                title:{
                                    text:'Standpoint'
                                }
                            },
                            yaxis: {
                                title: {
                                    text: 'Durasi Interaksi (Second)'
                                }
                            },
                            fill: {
                                opacity: 1 ,
                            }, 
                            tooltip: {
                                y: {
                                    formatter: function (val) {
                                    return "" + val + " Second"
                                    }
                                }
                            }
                        }
                    }else if(statusChartObject.value === 'Pengulangan'){
                        // console.log(objectTable.value)
                        objectTable.value.map((val,index)=>{
                            if(index >= start_item && index < max_item_per_page){
                                let find_id = index+1
                                dataChartObject.push(val.oldData.iteration)
                                idChartStandpoint.push(find_id)
                            }
                        })
                        // console.log(objectTable.value,'1138 penglangan')
                        objectValueChart.value = [{
                            name: 'Pengulangan',
                            data: dataChartObject,
                            color:'#004F98'
                        }]
                          objectValueOptionsPengulangan.value = {
                            chart:{
                            type:'bar',
                            height:320
                                },plotOptions: {
                            bar: {
                                horizontal: false,
                                columnWidth: '70%',
                                endingShape: 'rounded'
                            },
                            },dataLabels: {
                                enabled: false
                            },
                            stroke: {
                                show: true,
                                width: 2,
                                colors: ['transparent']
                            },
                            xaxis: {
                                categories: idChartStandpoint,
                                title:{
                                    text:'Standpoint'
                                }
                            },
                            yaxis: {
                                title: {
                                    text: 'Durasi Interaksi (Pengulangan)'
                                }
                            },
                            fill: {
                                opacity: 1 ,
                            }, 
                            tooltip: {
                                y: {
                                    formatter: function (val) {
                                    return "" + val + " Pengulangan"
                                    }
                                }
                            }
                        }
                    }      
                    objectValueOptions.value.xaxis.categories = idChartStandpoint
                    objectValueOptionsPengulangan.value.xaxis.categories = idChartStandpoint   

                }else if (params === 'video'){
                    paginate_chart_video.value = pageNum
                    let dataChartVideo = []
                    let idChartStandpoint = []
                    let max_item_per_page = pageNum * 10
                    
                    let start_item = max_item_per_page - 10 // index untuk filter item chart
                    if(statusChartVideo.value === 'Column'){
                        // console.log(videoTable.value)
                        videoTable.value.map((val,index)=>{
                            if(index >= start_item && index < max_item_per_page){
                                let find_id = index+1
                                dataChartVideo.push(val.durationSecond)
                                idChartStandpoint.push(find_id)
                            }
                        })
                        // console.log(dataChartVideo,'1136')
                        // eslint-disable-next-line no-unused-vars
                        let data = [
                            {
                                name: 'Net Profit',
                                data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
                            }, {
                                name: 'Revenue',
                                data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
                            }
                        ]
                       

                        videoValueChart.value = [
                            {
                            name: 'Durasi Siswa Menonton',
                            data: dataChartVideo,
                            color:'#004F98'
                            },
                            {
                            name: 'Durasi Video',
                            data: dataChartVideo,
                            color:'#F5F5F5'
                            }

                        ]
                        videoValueOptions.value = {
                            chart:{
                            type:'bar',
                            height:320
                                },plotOptions: {
                            bar: {
                                horizontal: false,
                                columnWidth: '70%',
                                endingShape: 'rounded'
                            },
                            },dataLabels: {
                                enabled: false,
                                title:{
                                    text:'testing'
                                }
                            },
                            stroke: {
                                show: true,
                                width: 2,
                                colors: ['transparent']
                            },
                            xaxis: {
                                categories: idChartStandpoint,
                                title:{
                                    text:'Standpoint'
                                }
                            },
                            yaxis: {
                                title: {
                                    text: 'Durasi Interaksi (menit)'
                                }
                            },
                            fill: {
                                opacity: 1 ,
                            }, 
                            tooltip: {
                                y: {
                                    formatter: function (val) {
                                    return "" + val + " second"
                                    }
                                }
                            }
                        }
                    }else if(statusChartVideo.value === 'Donut'){
                        // console.log('masuk ke else donut 1198')
                        videoTable.value.map((val,index)=>{
                            if(index >= start_item && index < max_item_per_page){
                                let find_id = index+1
                                dataChartVideo.push(val.oldData.iteration)
                                idChartStandpoint.push(find_id)
                            }
                        })
                        // console.log(videoTable.value,'1138 penglangan')
                        videoValueOptions.value = [{
                            name: 'Pengulangan',
                            data: dataChartVideo,
                            color:'#004F98'
                        }]
                    }    
                    // :data="{'Watch': 44,'Duration':56}"
 
                }else if (params === 'video360'){
                    paginate_chart_video.value = pageNum
                    let dataChartVideo = []
                    let idChartStandpoint = []
                    let max_item_per_page = pageNum * 10
                    
                    let start_item = max_item_per_page - 10 // index untuk filter item chart
                    if(statusChartVideo360.value === 'Column'){
                        // console.log(videoTable.value)
                        videoTable360.value.map((val,index)=>{
                            if(index >= start_item && index < max_item_per_page){
                                let find_id = index+1
                                dataChartVideo.push(val.durationSecond)
                                idChartStandpoint.push(find_id)
                            }
                        })

                        video360ValueChart.value = [{
                                name: 'Durasi Siswa Menonton',
                                data: dataChartVideo,
                                color:'#004F98'
                            },
                            {
                                name: 'Durasi Video',
                                data: dataChartVideo,
                                color:'#F5F5F5'
                            }
                        ]
                        video360ValueOptions.value = {
                            chart:{
                            type:'bar',
                            height:320
                                },plotOptions: {
                            bar: {
                                horizontal: false,
                                columnWidth: '70%',
                                endingShape: 'rounded'
                            },
                            },dataLabels: {
                                enabled: false
                            },
                            stroke: {
                                show: true,
                                width: 2,
                                colors: ['transparent']
                            },
                            xaxis: {
                                categories: idChartStandpoint,
                                title:{
                                    text:'Standpoint'
                                }
                            },
                            yaxis: {
                                title: {
                                    text: 'Durasi Interaksi (menit)'
                                }
                            },
                            fill: {
                                opacity: 1 ,
                            }, 
                            tooltip: {
                                y: {
                                    formatter: function (val) {
                                    return "" + val + " second"
                                    }
                                }
                            }
                        }
                    }else if(statusChartVideo360.value === 'Donut'){
                        // console.log('masuk ke else donut 1198')
                        videoTable360.value.map((val,index)=>{
                            if(index >= start_item && index < max_item_per_page){
                                let find_id = index+1
                                dataChartVideo.push(val.oldData.iteration)
                                idChartStandpoint.push(find_id)
                            }
                        })
                        videoValueOptions.value = [{
                            name: 'Pengulangan',
                            data: dataChartVideo,
                            color:'#004F98'
                        }]
                    }    
                    // :data="{'Watch': 44,'Duration':56}"
                }

            }

            watch(async()=>statusChartStandpoint.value,function(){
                findDataForTableChart(paginate_chart_standpoint.value,'standpoint')
            })

            watch(async()=>statusChartObject.value,function(){
                findDataForTableChart(paginate_chart_object.value,'object')
            })

            const export_to_csv=(params)=>{
                // console.log(params)

                let arrayToPdf = [
                    // 'Nomor,Durasi Interaksi,Pengulangan',
                ]
                var rows = document.querySelectorAll("#standpoint_id_table tr");
                let row = []
                rows[0].querySelectorAll('td,th').forEach((val)=>{
                    row.push(val.innerText)
                })
                arrayToPdf.push(row.join(','))
                if(params  === 'standpoint'){
                    standPointTable.value.forEach((val,index)=>{
                        arrayToPdf.push(`${index+1},${val.durationMinute},${val.oldData.iteration}`)
                    })
                }else if(params  === 'object'){
                    objectTable.value.forEach((val,index)=>{
                        arrayToPdf.push(`${index+1},${val.oldData.name},${val.oldData.iteration},${val.durationMinute}`)
                    })
                }
                 if(params  === 'video'){
                    videoTable.value.forEach((val,index)=>{
                         let question = val.oldData.title                    
                        let join_question = question.replace(/,/g, '')
                        arrayToPdf.push(`${index+1},${join_question},${val.durationMinute},${val.durationMinuteSeen}`)
                    })
                }
                 if(params  === 'video360'){
                    videoTable360.value.forEach((val,index)=>{
                        let question = val.oldData.title                    
                        let join_question = question.replace(/,/g, '')
                        arrayToPdf.push(`${index+1},${join_question },${val.durationMinute},${val.durationMinuteSeen}`)
                    })
                }
                

                // Download CSV
                download_csv(arrayToPdf.join("\n"), params);
            }
            function download_csv(csv, filename) {
                var csvFile;
                var downloadLink;

                // CSV FILE
                csvFile = new Blob([csv], {type: "text/csv"});

                // Download link
                downloadLink = document.createElement("a");

                // File name
                downloadLink.download = filename;

                // We have to create a link to the file
                downloadLink.href = window.URL.createObjectURL(csvFile);

                // Make sure that the link is not displayed
                downloadLink.style.display = "none";

                // Add the link to your DOM
                document.body.appendChild(downloadLink);

                // Lanzamos
                downloadLink.click();
            }

            // STANDPOINT STATE

            //  OBJECT STATE

                
                let optionObjectInteractionSort= ref('')
                let valueObjSort = ref('Durasi Interaksi')
                let statusObj=ref('Durasi Interaksi') // dropdown chart
                let isSortActiveObj=ref(false)
                let optionObj = ref(['Durasi Interaksi','Pengulangan',]) // option dropdown chart
                let objSortActive = ref('sp_nomor_asc') // sort yang lagi active
                
                let paginate_object = ref(1)
                let start_page_object = ref(0)
                let end_page_object = ref(5)
                let total_page_table_object = ref(0)
                let total_page_chart_object = ref(0)
                let total_item_table_object = ref(0)


                let paginate_chart_object=ref(1)
                let start_page_chart_obj = ref(0)
                let end_page_chart_obj = ref(10)
                let objectTable = ref([
                {
                    id:1,
                    name:'Bayu ',
                    standpoint:5,
                    duration:'01:21:40',
                    Pengulangan : 1,
                    // img:'../../assets/Header/testing_pp.jpeg'
                    img:[require("../../assets/Header/testing_pp.jpeg")]
                },
                 {
                    id:2,
                    name:' Darmawan',
                    standpoint:3,
                    duration:'01:21:40',
                    Pengulangan : 1,
                    img:[require("../../assets/Header/testing_pp.jpeg")]
                },
                 {
                    id:3,
                    name:'BD',
                    standpoint:5,
                    duration:'01:21:40',
                    Pengulangan : 1,
                    img:[require("../../assets/Header/testing_pp.jpeg")]
                },
                 {
                    id:4,
                    name:'Adella',
                    standpoint:2,
                    duration:'01:21:40',
                    Pengulangan : 1,
                    img:[require("../../assets/Header/testing_pp.jpeg")]
                },
                 {
                    id:5,
                    name:'Alifvia',
                    standpoint:6,
                    duration:'01:21:40',
                    Pengulangan : 1,
                    img:[require("../../assets/Header/testing_pp.jpeg")]
                },
                 {
                    id:6,
                    name:'Rizki',
                    standpoint:2,
                    duration:'01:21:40',
                    Pengulangan : 1,
                    img:[require("../../assets/Header/testing_pp.jpeg")]
                }
            ])
          
            
                let objectValueChart = ref([  {
                    name: 'Durasi Siswa',
                    data: [10, 55, 57, 56, 61, 58, 63, 60, 66,30],
                    color:'#004F98'
                }])
                let objectValueOptionsPengulangan= ref(
                 {
                    chart:{
                    type:'bar',
                    height:320
                        },
                    plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '70%',
                        endingShape: 'rounded'
                    },
                    },dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    xaxis: {
                        categories: [],
                        title:{
                            text:'Pengulangan'
                        }
                    },
                    yaxis: {
                        title: {
                            text: 'Durasi Pengulangan (second)'
                        }
                    },
                    fill: {
                        opacity: 1 ,
                    }, 
                    tooltip: {
                        y: {
                            formatter: function (val) {
                            
                            return ": " + val 
                            }
                        }
                    }
                }
            )
                let objectValueOptions=ref(
                {chart:{
                    type:'bar',
                    height:320
                        },plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '70%',
                        endingShape: 'rounded'
                    },
                    },dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    xaxis: {
                        categories: ['1', '2', '3', '4', '5', '6', '7', '8', '9','10'],
                    },
                    yaxis: {
                        title: {
                            text: 'Durasi Interaksi (menit)'
                        }
                    },
                    fill: {
                        opacity: 1 ,
                    }, 
                    tooltip: {
                        y: {
                            formatter: function (val) {
                            return "$ " + val + " thousands"
                            }
                        }
                    }
                        }
                 )
            
            //  OBJECT STATE
 
            //  VIDEO STATE
                let video_percentage = ref(0)
                let allDataVideo = ref()
                let video360_percentage = ref(0)
                let isSortActiveVid=ref(false)
                let statusChartVideo = ref('Column')
                let videoObj = ref(['Column','Donut',]) // option dropdown chart
                let videoSortActive = ref('vid_sort_1')
                let videoTable = ref([
                {
                    id:1,
                    name:'Bayu ',
                    standpoint:5,
                    duration:'01:21:40',
                    Pengulangan : 1,
                    // img:'../../assets/Header/testing_pp.jpeg'
                    img:[require("../../assets/Header/testing_pp.jpeg")]
                },
                 {
                    id:2,
                    name:' Darmawan',
                    standpoint:3,
                    duration:'01:21:40',
                    Pengulangan : 1,
                    img:[require("../../assets/Header/testing_pp.jpeg")]
                },
                 {
                    id:3,
                    name:'BD',
                    standpoint:5,
                    duration:'01:21:40',
                    Pengulangan : 1,
                    img:[require("../../assets/Header/testing_pp.jpeg")]
                },
                 {
                    id:4,
                    name:'Adella',
                    standpoint:2,
                    duration:'01:21:40',
                    Pengulangan : 1,
                    img:[require("../../assets/Header/testing_pp.jpeg")]
                },
                 {
                    id:5,
                    name:'Alifvia',
                    standpoint:6,
                    duration:'01:21:40',
                    Pengulangan : 1,
                    img:[require("../../assets/Header/testing_pp.jpeg")]
                },
                 {
                    id:6,
                    name:'Rizki',
                    standpoint:2,
                    duration:'01:21:40',
                    Pengulangan : 1,
                    img:[require("../../assets/Header/testing_pp.jpeg")]
                }
            ])
                let optionVideoSort = ref([
                     {
                    value:'Nomor',
                    img:require('../../assets/InteractionAssets/down_arrow.svg'),
                    id:"vid_sort_1"
                },
                {
                    value:'Nomor',
                    img:require('../../assets/InteractionAssets/gray_top_arrow.svg'),
                    id:"vid_sort_2"
                },
                {
                    value:'Video',
                    img:require('../../assets/InteractionAssets/down_arrow.svg'),
                    id:"vid_sort_3"
                },
                {
                    value:'Video',
                    img:require('../../assets/InteractionAssets/gray_top_arrow.svg'),
                    id:"vid_sort_4"
                },
                {
                    value:'Durasi Video',
                    img:require('../../assets/InteractionAssets/down_arrow.svg'),
                    id:"vid_sort_5"
                },
                 {
                    value:'Durasi Video',
                    img:require('../../assets/InteractionAssets/gray_top_arrow.svg'),
                    id:"vid_sort_6"
                },
                 {
                    value:'Durasi Siswa Menonton',
                    img:require('../../assets/InteractionAssets/down_arrow.svg'),
                    id:"vid_sort_7"
                },
                 {
                    value:'Durasi Siswa Menonton',
                    img:require('../../assets/InteractionAssets/gray_top_arrow.svg'),
                    id:"vid_sort_8"
                },
            ])
                let videoValueChart = ref([  {
                    name: 'Durasi Siswa',
                    data: [100, 55, 57, 56, 61, 58, 63, 60, 66,30],
                    color:'#004F98'
                }])
                let videoValueOptions=ref(
                {
                    chart:{
                    type:'bar',
                    height:320
                        },plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '70%',
                        endingShape: 'rounded'
                    },
                    },dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    xaxis: {
                        categories: ['1', '2', '3', '4', '5', '6', '7', '8', '9','10'],
                    },
                    yaxis: {
                        title: {
                            text: 'Durasi Interaksi (menit)'
                        }
                    },
                    fill: {
                        opacity: 1 ,
                    }, 
                    tooltip: {
                        y: {
                            formatter: function (val) {
                            return "$ " + val + " thousands"
                            }
                        }
                    }
                }
            )
                let videoDonutOptions=ref({
                    plotOptions: {
                        pie: {
                            donut: {
                                labels: {
                                show: true,
                                    total: {
                                        show: true,
                                        label: '',
                                        formatter: () => 'Text you want'
                                    }
                                }
                            }
                        }
                    }
                })
                let videoValueOptionsDonut=ref({
                    "Watch":44,
                    'Duration':56
                })
                let videoValueOptionsPengulangan=ref()
                
                let paginate_video = ref(1)
                let start_page_video = ref(0)
                let end_page_video = ref(5)


                let total_page_table_video = ref(0)
                let total_page_chart_video = ref(0)
                let total_item_table_video = ref(0)

                let paginate_chart_video = ref (1)
                let start_page_chart_video = ref(0)
                let end_page_chart_video =  ref(10)
                



             watch(async()=>statusChartVideo.value,function(){
                // console.log(statusChartVideo.value,'1610 jalan')
                    if(!allDataVideo.value){
                    
                    let find_percentage = {
                        "watch":0,
                        'Duration':100
                    }
                    video_percentage.value = find_percentage
                    allDataVideo.value = {
                        "percentage":0,
                        "total_seen_duration":0,
                        "total_duration":0
                    }
                    
                }
                findDataForTableChart(paginate_chart_video.value,'video')
            })
           //  VIDEO STATE

            // VIDEO 360 STATE        
                
                let allDataVideo360 = ref()
                let videoTable360= ref ([])
                let isSortActiveVid360=ref(false)
                let video360SortActive = ref('360_sort_1')
                let statusChartVideo360 = ref('Column')
                let optionVideo360Sort = ref([
                {
                    value:'Nomor',
                    img:require('../../assets/InteractionAssets/down_arrow.svg'),
                    id:"360_sort_1"
                },
                {
                    value:'Nomor',
                    img:require('../../assets/InteractionAssets/gray_top_arrow.svg'),
                    id:"360_sort_2"
                },
                {
                    value:'Video',
                    img:require('../../assets/InteractionAssets/down_arrow.svg'),
                    id:"360_sort_3"
                },
                {
                    value:'Video',
                    img:require('../../assets/InteractionAssets/gray_top_arrow.svg'),
                    id:"360_sort_4"
                },
                {
                    value:'Durasi Video',
                    img:require('../../assets/InteractionAssets/down_arrow.svg'),
                    id:"360_sort_5"
                },
                 {
                    value:'Durasi Video',
                    img:require('../../assets/InteractionAssets/gray_top_arrow.svg'),
                    id:"360_sort_6"
                },
                 {
                    value:'Durasi Siswa Menonton',
                    img:require('../../assets/InteractionAssets/down_arrow.svg'),
                    id:"360_sort_7"
                },
                 {
                    value:'Durasi Siswa Menonton',
                    img:require('../../assets/InteractionAssets/down_arrow.svg'),
                    id:"360_sort_8"
                },
            ])
                let videoObj360 = ref(['Column','Donut',]) // option dropdown chart
                let video360ValueChart = ref([
                {
                    name: 'Durasi Siswa',
                    data: [10, 20, 30, 40, 50, 60, 70, 80, 90,30],
                    color:'#004F98'
                }
            ])
                let video360ValueOptions=ref(
                {chart:{
                    type:'bar',
                    height:320
                        },plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '70%',
                        endingShape: 'rounded'
                    },
                    },dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    xaxis: {
                        categories: ['1', '2', '3', '4', '5', '6', '7', '8', '9','10'],
                    },
                    yaxis: {
                        title: {
                            text: 'Durasi Interaksi (menit)'
                        }
                    },
                    fill: {
                        opacity: 1 ,
                    }, 
                    tooltip: {
                        y: {
                            formatter: function (val) {
                            return "$ " + val + " thousands"
                            }
                        }
                    }
                        }
            )

                let video360ValueOptionsDonut=ref({
                    "Watch":44,
                    'Duration':56
                })
                let video360ValueOptionsPengulangan=ref()
                
                let paginate_video360 = ref(1)
                let start_page_video360 = ref(0)
                let end_page_video360 = ref(5)


                let total_page_table_video360 = ref(0)
                let total_page_chart_video360 = ref(0)
                let total_item_table_video360 = ref(0)

                let paginate_chart_video360 = ref (1)
                let start_page_chart_video360 = ref(0)
                let end_page_chart_video360 =  ref(10)

                watch(async()=>statusChartVideo360.value,function(){
                // console.log(statusChartVideo360.value,'1610 jalan')
                if(!allDataVideo360.value){
                    // console.log(allDataVideo360.value)
                    let find_percentage = {
                        "watch":0,
                        'Duration':100
                    }
                    video360_percentage.value = find_percentage
                    allDataVideo360.value = {
                        "percentage":0,
                        "total_seen_duration":0,
                        "total_duration":0
                    }
                    
                }
                findDataForTableChart(paginate_chart_video360.value,'video360')
            })
                
           // VIDEO 360 STATE END

            const secondToMinutesAndSeconds=(duration,status)=>{
                    var hrs = ~~(duration / 3600);
                    var mins = ~~((duration % 3600) / 60);
                    var secs = ~~duration % 60;

                    // Output like "1:01" or "4:03:59" or "123:03:59"
                    var ret = "";

                    if(status === 'full'){
                        if (hrs > 0) {
                            ret += "" + hrs + ":" + (mins < 10 ? "0" : "") + "" + mins + "Min" + (secs < 10 ? "0": "") + secs + 'Sc';
                        }else {
                            ret += "" + mins + "Min" + (secs < 10 ? "0" : "") + secs + 'Sc';
                            // ret += "" + secs;
                        }
                    }else {
                        if (hrs > 0) {
                            ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
                        }else {
                            ret += "" + mins + ":" + (secs < 10 ? "0" : "");
                            ret += "" + secs;
                        }
                    }

                    return ret;
            }

            function fmtMSS(s){
                let second = Math.floor(s)
                return(second-(second%=60))/60+(9<second?' : ':' : 0')+second
            }
            const second_to_minutes_full=(s)=>{
                 let second = Math.floor(s)
                return(second-(second%=60))/60+' Min '  +(9<second?`${second}`:'0')+ ' Sc'
            }

     

            const sortFuncObject=(getAllObject)=>{
                
                if(objSortActive.value === 'sp_nomor_asc'){
                    getAllObject.sort(function(a,b){
                        return a.oldData.id-b.oldData.id})
                }else if (objSortActive.value === 'sp_nomor_desc'){
                     getAllObject.sort(function(a,b){
                        return b.oldData.id-a.oldData.id})
                }else if(objSortActive.value === 'sp_durasi_asc'){
                    getAllObject.sort(function(a,b){
                        return a.oldData.duration-b.oldData.duration})
                }else if(objSortActive.value === 'sp_durasi_desc'){
                    getAllObject.sort(function(a,b){
                        return b.oldData.duration-a.oldData.duration})
                }else if (objSortActive.value === 'sp_pengulangan_asc'){
                    getAllObject.sort(function(a,b){
                        return a.oldData.iteration - b.oldData.iteration})
                }else if (objSortActive.value === 'sp_pengulangan_desc'){
                    getAllObject.sort(function(a,b){
                        return b.oldData.iteration - a.oldData.iteration})
                }

            
                objectTable.value=getAllObject
                return getAllObject
            }
            const sortFuncVideo=(getAllVideo)=>{
                // console.log(getAllVideo,'before')
                if(videoSortActive.value === 'vid_sort_1'){ // sort by number asc
                    getAllVideo.sort(function(a,b){
                        return a.id - b.id})
                }else if (videoSortActive.value === 'vid_sort_2'){ // sort by number desc
                     getAllVideo.sort(function(a,b){
                        return b.id - a.id})
                }else if(videoSortActive.value === 'vid_sort_3'){ // sort by title asc
                    getAllVideo.sort(function(a,b){
                        return a.oldData.title.localeCompare(b.oldData.title)})
                }else if(videoSortActive.value === 'vid_sort_4'){ // sort by title desc
                    getAllVideo.sort(function(a,b){
                        return b.oldData.title.localeCompare(a.oldData.title)})
                }else if (videoSortActive.value === 'vid_sort_5'){ // sort by video asc
                    getAllVideo.sort(function(a,b){
                        return a.oldData.duration - b.oldData.duration})
                }else if (videoSortActive.value === 'vid_sort_6'){ // sort by video desc
                    getAllVideo.sort(function(a,b){
                        return b.oldData.duration - a.oldData.duration})
                }else if (videoSortActive.value === 'vid_sort_7'){ // sort by video dura asc
                    getAllVideo.sort(function(a,b){
                        return a.oldData.seen_duration - b.oldData.seen_duration})
                }else if (videoSortActive.value === 'vid_sort_8'){ // sort by video dura desc
                    getAllVideo.sort(function(a,b){
                        return b.oldData.seen_duration - a.oldData.seen_duration})
                }
                videoTable.value=getAllVideo
                // console.log(getAllVideo,' after')
                return getAllVideo
            }
            const sortFuncVideo360=(getAllVideo)=>{
                // console.log(getAllVideo,'before')
                if(video360SortActive.value === '360_sort_1'){ // sort by number asc
                    getAllVideo.sort(function(a,b){
                        return a.id - b.id})
                }else if (video360SortActive.value === '360_sort_2'){ // sort by number desc
                     getAllVideo.sort(function(a,b){
                        // console.log(a,b)
                        return b.id - a.id})
                }else if(video360SortActive.value === '360_sort_3'){ // sort by title asc
                    getAllVideo.sort(function(a,b){
                        return a.oldData.title.localeCompare(b.oldData.title)})
                }else if(video360SortActive.value === '360_sort_4'){ // sort by title desc
                    getAllVideo.sort(function(a,b){
                        return b.oldData.title.localeCompare(a.oldData.title)})
                }else if (video360SortActive.value === '360_sort_5'){ // sort by video asc
                    getAllVideo.sort(function(a,b){
                        return a.oldData.duration - b.oldData.duration})
                }else if (video360SortActive.value === '360_sort_6'){ // sort by video desc
                    getAllVideo.sort(function(a,b){
                        return b.oldData.duration - a.oldData.duration})
                }else if (video360SortActive.value === '360_sort_7'){ // sort by video dura asc
                    getAllVideo.sort(function(a,b){
                        return a.oldData.seen_duration - b.oldData.seen_duration})
                }else if (video360SortActive.value === '360_sort_8'){ // sort by video dura desc
                    getAllVideo.sort(function(a,b){
                        return b.oldData.seen_duration - a.oldData.seen_duration})
                }
                videoTable360.value=getAllVideo
                // console.log(getAllVideo,' after')
                return getAllVideo
            }


            const sortFuncStandpoint=(getAllStandPointTable)=>{
                
                if(standPointSortActive.value === 'sp_nomor_asc'){
                    getAllStandPointTable.sort(function(a,b){
                        return a.oldData.id-b.oldData.id})
                }else if (standPointSortActive.value === 'sp_nomor_desc'){
                     getAllStandPointTable.sort(function(a,b){
                        return b.oldData.id-a.oldData.id})
                }else if(standPointSortActive.value === 'sp_durasi_asc'){
                    getAllStandPointTable.sort(function(a,b){
                        return a.oldData.duration-b.oldData.duration})
                }else if(standPointSortActive.value === 'sp_durasi_desc'){
                    getAllStandPointTable.sort(function(a,b){
                        return b.oldData.duration-a.oldData.duration})
                }else if (standPointSortActive.value === 'sp_pengulangan_asc'){
                    getAllStandPointTable.sort(function(a,b){
                        return a.oldData.iteration - b.oldData.iteration})
                }else if (standPointSortActive.value === 'sp_pengulangan_desc'){
                    getAllStandPointTable.sort(function(a,b){
                        return b.oldData.iteration - a.oldData.iteration})
                }
                standPointTable.value = getAllStandPointTable
                // console.log(standPointTable.value)
                return getAllStandPointTable

                // set up standpoint chart
               
            }

            const fetching_video360=async()=>{
                isLoadingVideo360.value = true
                // console.log('loading')
                // console.log(isLoadingVideo360.value)
                Axios.post(`https://us-central1-millea-lab.cloudfunctions.net/web/api/getVideo360Data`,{
                     "owner_uid":uidCustomer.value,
                     "classroom_uid":classUidActive.value,
                    "scene_uid":sceneUidActive.value,
                    "viewer_uid":studentUidActive.value
                }).then((res)=>{
                    // console.log(res.data)
                    let watch = res.data.percentage
                    let total_watch = 100 - watch
                    
                    let find_percentage = {
                        "watch":`${watch.toFixed(2)}%`,
                        'Duration':total_watch.toFixed(2)
                    }
                    video360_percentage.value = find_percentage
                    let new_item = res.data
                    new_item.total_duration = second_to_minutes_full(res.data.total_duration,'full')
                    new_item.total_seen_duration = second_to_minutes_full(res.data.total_seen_duration,'full')
                    allDataVideo360.value = new_item
                    let newAllVideo = []
                    res.data.videos.forEach((val,id)=>{
                        let duration = val.duration
                        newAllVideo.push({
                            oldData:val,
                            durationMinute:fmtMSS(duration),
                            durationSecond:Math.floor(duration),
                            durationMinuteSeen:fmtMSS(val.seen_duration),
                            id:(id+1)
                        })
                    })
                    // console.log(newAllVideo,'new all video 1664')
                    videoTable360.value = newAllVideo
                    total_page_table_video360.value = Math.ceil(newAllVideo.length / 5)
                    total_page_chart_video360.value = Math.ceil(newAllVideo.length / 10)
                    total_item_table_video360.value = newAllVideo.length
                    // console.log(paginate_chart_video.value,' pagina te chart video')
                    findDataForTableChart(paginate_chart_video.value,'video360') 
                    isLoadingVideo360.value = false   
                }).catch((err)=>{
                    console.log(err.message)
                    videoTable360.value = []
                    findDataForTableChart(paginate_chart_video.value,'video360') 
                    isLoadingVideo360.value = false   
                })
                
                // console.log(isLoadingVideo360.value)
            }

            const fetching_video=async()=>{
                isLoadingVideo.value = true
                console.log(uidCustomer.value,classUidActive.value,sceneUidActive.value,studentUidActive.value)
                
                await Axios.post(`https://us-central1-millea-lab.cloudfunctions.net/web/api/getVideoData`,{
                     "owner_uid":uidCustomer.value,
                     "classroom_uid":classUidActive.value,
                    "scene_uid":sceneUidActive.value,
                    "viewer_uid":studentUidActive.value
                }).then((res)=>{
                    let watch = res.data.percentage.toFixed(2)
                    let total_watch = 100 - watch
                    
                    let find_percentage = {
                        "watch":`${watch}`,
                        'Duration':total_watch
                    }
                    video_percentage.value = find_percentage
                     let new_item = res.data
                    new_item.total_duration = second_to_minutes_full(res.data.total_duration,'full')
                    new_item.total_seen_duration = second_to_minutes_full(res.data.total_seen_duration,'full')
                    allDataVideo.value = new_item
                    let newAllVideo = []
                    res.data.videos.forEach((val,id)=>{
                        let duration = val.duration
                        newAllVideo.push({
                            oldData:val,
                            durationMinute:fmtMSS(duration),
                            durationSecond:Math.floor(duration),
                            durationMinuteSeen:fmtMSS(val.seen_duration),
                            id:(id+1)
                        })
                    })
                    // console.log(newAllVideo,'new all video 1664')
                    videoTable.value = newAllVideo
                    total_page_table_video.value = Math.ceil(newAllVideo.length / 5)
                    total_page_chart_video.value = Math.ceil(newAllVideo.length / 10)
                    total_item_table_video.value = newAllVideo.length
                    // console.log(paginate_chart_video.value,' pagina te chart video')
                    findDataForTableChart(paginate_chart_video.value,'video')    
                }).catch((err)=>{
                    console.log(err.message)
                    videoTable.value = []
                    findDataForTableChart(paginate_chart_video.value,'video')    
                })
                isLoadingVideo.value = false
            }
            const fetching_object=async()=>{
                isLoadingObject.value = true
                await Axios.post(`https://us-central1-millea-lab.cloudfunctions.net/web/api/getObjectData`,{
                     "owner_uid":uidCustomer.value,
                     "classroom_uid":classUidActive.value,
                    "scene_uid":sceneUidActive.value,
                    "viewer_uid":studentUidActive.value
                }).then((res)=>{
                    // console.log(res.data,' object ')

                    let newAllObject = []
                    res.data.forEach((val,id)=>{
                        let duration = val.duration
                        newAllObject.push({
                            oldData:val,
                            durationMinute:fmtMSS(duration),
                            durationSecond:Math.floor(duration),
                            id:(id+1)
                        })
                    })
                    objectTable.value = newAllObject
                    
                    total_page_table_object.value = Math.ceil((newAllObject.length / 5) )
                    total_page_chart_object.value = Math.ceil((newAllObject.length / 10))
                    // console.log(total_page_chart_object.value,' total page')
                    total_item_table_object.value = newAllObject.length
                    findDataForTableChart(paginate_chart_object.value,'object')
                }).catch((err)=>{
                    console.log(err)
                    objectTable.value = []
                    findDataForTableChart(paginate_chart_object.value,'object')
                })
                isLoadingObject.value = false
            }

            const fetching_interaction=async()=>{
                // let owner_uid = localStorage.getItem('idStorage')
                isLoadingInteraction.value = true
                // console.log(classUidActive.value,'class uid')
                // console.log(uidCustomer.value,'class uid')
                // console.log(sceneUidActive.value,'class uid')
                // console.log(studentUidActive.value,'class uid')
                await Axios.post(`https://us-central1-millea-lab.cloudfunctions.net/web/api/getStandpointData`,{
                    "classroom_uid":classUidActive.value,
                    "owner_uid":uidCustomer.value,
                    "scene_uid":sceneUidActive.value,
                    "viewer_uid":studentUidActive.value
                }).then((res)=>{
                    // console.log(res.data)
                    let newAllStandPointTable = []
                     res.data.forEach((val,id)=>{
                        let duration = val.duration
                        newAllStandPointTable.push({
                            oldData:val,
                            durationMinute:fmtMSS(duration),
                            durationSecond:Math.floor(duration),
                            id:(id+1)
                        })     
                    })
                    standPointTable.value = newAllStandPointTable      
                    console.log(standPointTable.value,' standpoint table value 2535')
                    total_page_table.value = Math.ceil((newAllStandPointTable.length / 5))
                    total_page_chart.value = Math.ceil((newAllStandPointTable.length / 10))
                    total_item_table.value = newAllStandPointTable.length
                    findDataForTableChart(paginate_chart_standpoint.value,'standpoint')
                }).catch((err)=>{
                    console.log(err.message)
                    standPointTable.value = []
                    findDataForTableChart(paginate_chart_standpoint.value,'standpoint')
                })
                isLoadingInteraction.value = false

            }

            const fetching_class=async()=>{ // fetching class scene student

                // filter find class
                let allClassValue = []
              
                if(allDataOverview.value !== undefined || allDataOverview.value.length > 0 ){
                    allDataOverview.value.forEach((val)=>{
                        allClassValue.push(val.name)
                    })
                    optionClass.value = allClassValue
             
                }else {
                    // console.log('masuk ke else allDataOverview Kosong')
                }

            }

            const fetch=async()=>{
                // console.log('untuk fetching data api')
                // get state for standpoint 
                // eslint-disable-next-line no-unused-vars
                uidCustomer.value = await store.getters.getValueUidCustomer
                allDataOverview.value = await store.getters.getAllOverview 
                
                isLoginGlobal.value = store.getters.getIsLogin

                optionStandpointInteractionSort.value = await store.getters.getAllStandPointTable.optionStandPointInteraction
                optionObjectInteractionSort.value = await store.getters.getAllStandPointTable.optionStandPointInteraction
                optionStandpoint.value=  await store.getters.getAllStandPointTable.optionStandPointSort

                await fetching_class()
                await fetching_interaction()
                await fetching_object()
                await fetching_video()
                await fetching_video360()

    


                isLoginGlobal.value = false
                // isLoadingInteraction = false
                // isLoadingObject = false
                // isLoadingVideo = false
                // isLoadingVideo360 = false

      
            }
            // console.log(allDataFromApi.value)

              // CHECKING SCREEN

            var onresize = function() {
                //your code here
                //this is just an example
                let width = document.body.clientWidth;
                if(width<600){
                    isMobilePageActive.value = true
                }else {
                    isMobilePageActive.value = false
                }
            }
            onMounted(()=>{
               window.addEventListener("resize", onresize);
            })
          
            // CHECKING SCREEN
  
            onBeforeMount(()=>{
                fetch() // fetching all data
                onresize() // for checking screen size
                // Chart.options.plugins.legend.position = 'bottom';
            })

            const toggleSelected=()=>{
                // console.log(value)
            }

            const clickCallback=(pageNum)=>{
                //  console.log(pageNum)
                let new_end = pageNum * 5
                let new_start = new_end - 5 
    
                start_page_standpoint.value =  new_start
                end_page_standpoint.value = new_end
            }
            const toInteraction=()=>{
                // router.push('/users/eduardo')
            }

            // MODALS OPEN / CLOSE

            const new_close=()=>{
                closeModal()
            }

            const open_modal = (id) =>{
                console.log(standPointTable.value[id])
                valueActiveModals.value = standPointTable.value[id]
                document.getElementById("backdrop").style.display = "block"
                document.getElementById("modalSpecialObject").style.display = "block"
                document.getElementById("modalSpecialObject").className += "show"
            }
                
            function closeModal() {
                console.log('close modal jalanlet ')
                document.getElementById("backdrop").style.display = "none"
                document.getElementById("modalSpecialObject").style.display = "none"
                document.getElementById("modalSpecialObject").className += document.getElementById("modalSpecialObject").className.replace("show", "")
            }
            // Get the modal
            var modal = document.getElementById('modalSpecialObject');
            
            // When the user clicks anywhere outside of the modal, close it
            window.onclick = function(event) {
            if (event.target == modal) {
                closeModal()
            }
            }
            // MODALS OPEN / CLOSE
             return {
                open_modal,
                closeModal,
                path,
                optionMenu,
                optionLeftName,
                optionRightName,
                VueSelect,
                // Treeselect,
                value,
                options,
                toInteraction,
                pathQuizAct,
                fetch,
                pageStatus,
                valueClass,
                optionSort,
                sort_by_active,
                studentChartValue,
                statusChartObject,
                statusChartStandpoint,
                standpointValueChart,
                standpointValueOptions,
                optionStandpoint,
                standPointTable,
                open_sort_standpoint,
                isSortActiveSp,
                close_sort_standpoint,
                change_sort_standpoint,
                page_paginate,
                clickCallback,
                standPointSortActive,
                optionStandpointInteractionSort,
                log,
                isSortActiveObj,
                objectTable,
                objSortActive,
                valueObjSort,
                statusObj,
                optionObj,
                statusChartVideo,
                videoObj,
                videoTable,
                videoSortActive,
                optionVideoSort,
                isSortActiveVid,
                optionVideo360Sort,
                video360SortActive,
                isSortActiveVid360,
                videoObj360,
                statusChartVideo360,
                video360ValueOptions,
                video360ValueChart,
                objectValueChart,
                objectValueOptions,
                videoValueChart,
                videoValueOptions,
                paginate_standpoint,
                start_page_standpoint,
                end_page_standpoint,
                changePageStandpoint,
                total_page_table,
                total_item_table,
                changeChartStandpoint,
                start_page_chart_sp,
                end_page_chart_sp,
                paginate_chart_standpoint,
                total_page_chart,
                updateStandpointValueOptions,
                standpointValueOptionsPengulangan,
                findDataForTableChart,
                objectValueOptionsPengulangan,
                export_to_csv,
                download_csv,
                classValue,
                sceneValue,
                studentValue,
                onresize,
                isMobilePageActive,
                isLoginGlobal,
                fetching_interaction,
                fetching_video,
                fetching_object,
                total_page_table_object,
                total_page_chart_object,
                total_item_table_object,
                paginate_chart_object,
                start_page_chart_obj,
                end_page_chart_obj,
                changePageObject,
                paginate_object,
                start_page_object,
                end_page_object,
                sortFuncObject,
                optionObjectInteractionSort,
                changeChartObject,
                total_page_table_video,
                total_page_chart_video,
                total_item_table_video,
                paginate_chart_video,
                fetching_class,
                paginate_video,
                start_page_video,
                end_page_video,
                start_page_chart_video,
                end_page_chart_video,
                changePageVideo,
                videoValueOptionsPengulangan,
                changeChartVideo,
                videoValueOptionsDonut,
                video360ValueOptionsDonut,
                video360ValueOptionsPengulangan,
                paginate_video360,
                start_page_video360,
                end_page_video360,
                total_page_table_video360,
                total_page_chart_video360,
                total_item_table_video360,
                paginate_chart_video360,
                start_page_chart_video360,
                end_page_chart_video360,
                fetching_video360,
                videoTable360,
                changeChartVideo360,
                sortFuncVideo,
                sortFuncVideo360,
                allDataVideo,
                allDataVideo360,
                video360_percentage,
                video_percentage,
                allDataOverview,
                optionClass,
                optionScene,
                optionStudent,
                toggleSelected,
                uidCustomer,
                sceneUidActive,
                studentUidActive,
                classUidActive,
                isLoadingInteraction,
                isLoadingObject,
                isLoadingVideo,
                isLoadingVideo360,
                videoDonutOptions,
                secondToMinutesAndSeconds,
                fmtMSS,
                second_to_minutes_full,
                new_close,
                valueActiveModals
                
            }


           
        }


     
    }

</script>

<style lang="scss">
    @import '../../scss/AnalyticsInteraction.scss';
    @import '../../scss/Mobile/AnalyticsInteraction.scss';
</style>
<style src="@vueform/multiselect/themes/default.css"></style>
