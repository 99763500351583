<template>

     <div class="small-box-result" v-if="status">
        <div class="sbr-left ">
                <p class="skeleton-box">
                </p>
            <div class="box-percentage skeleton-box">
                <p class="">
                    <!-- <img src="../../assets/overview/arrow_navy.svg" alt=""> -->
                    <!-- {{percentage}} -->
                </p>
                <p>
                    <!-- <span class="skeleton-box " style="width:50%;"></span> -->
                </p>
            </div> 
        </div>
        <div class="sbr-right">
            <!-- <img :src="`${img}`" alt="" class="skeleton-box "> -->
            <div class="skeleton-box " style="width:50%;height:50%;border-radius:50%;">

            </div>
        </div>
    </div>

    <div class="small-box-result" v-else>
        <div class="sbr-left">
                <p>
                    {{name}} <br>
                    <span>
                        {{score}}
                    </span>
                </p>
                <img :src="`${img}`" alt="">    
           
        </div>
        <div class="sbr-right">
             <div class="box-percentage">
                <p>
                    <img src="../../assets/overview/arrow_navy.svg" alt="">
                    {{percentage}}
                </p>
                <p>{{lastUpdate}}</p>
            </div> 
        </div>
    </div>
</template>


<script>
// import { defineComponent } from '@vue/composition-api'

export default {
  name:'smallCard',
  props:{
    img:String,
    lastUpdate:String,
    percentage:String,
    name:String,
    score:String,
    status:Boolean
  },
  setup(){
    return {

    }
  }
}
</script>


<style scoped lang="scss">
$bg-gray:#f2f6fa;
$font-gray:#8898AA;
$font-green:#2DCE89;
$font-darkgray:#32325D;
$font-blue:#11CDEF;
$font-red:#F5365C;
$line-brown:linear-gradient(360deg, #D88144 -4.89%, #FFC357 105.5%);
$bg-new:linear-gradient(154.57deg, #FAF1EA 0%, #EDF2F7 100%);
$navy:#004F98;
$gradient-navy:linear-gradient(360deg, #004F98 -4.89%, #007FFF 105.5%);;
$font-black:#32325D;
$font-darkblue: #525F7F;
$new_gray: #ADADAD;
 .small-box-result{
    width:24%;
    min-width:24%;
    height:80%;
    background-color: white;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 32px rgba(136, 152, 170, 0.15);
    border-radius: 6px;
    margin-right:10px;
    //padding-top:10px;
    padding-right:15px;
    padding-left:15px;
    padding-top:10px;
    padding-bottom:10px;
    .sbr-left{
        width:100%;
        height:100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: row;
        
        p:nth-child(1){
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 300;
            font-size: 13px;
            line-height: 18px;
            color:$new_gray;
            text-align:left; 
            width:100%;
            // padding-left:10px;
            span{
                color:$font-black;
                font-size:20px;
                position: relative;
                top:5px;
            }
        }
       
    }
    .sbr-right{
        width: 100%;
        height:100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        padding-top:20px;
        .box-percentage{
            width:100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            img{
            width:20px;
            height:20px;
            }
            // p{
            //     width:50%;
            //     font-size:65px;
            //     font-family: 'Open Sans';
            //     font-style: normal;
            //     font-weight: 600;
            //     // font-size: 72px;
            //     line-height: 98px;
            //     color: $new_gray;
            // }
            
            // padding-left:10px;
           
        }
        
        
         p:nth-child(1){
                color:$navy;
                text-align: left;
                width:auto;
                padding:0px;
                font-weight:300;
                padding-right:5px;
                display: flex;
                justify-content: center;
            }
            p:nth-child(2){
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 300;
                font-size: 13px;
                line-height: 18px;
                color:$new_gray;
                text-align:left; 
                width:auto;
                // padding-left:10px;
            }
    }
}
.skeleton-box {
    display: inline-block;
    height: 1em;
    position: relative;
    overflow: hidden;
    background-color: #DDDBDD;
  
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        rgba(#fff, 0) 0,
        rgba(#fff, 0.2) 20%,
        rgba(#fff, 0.5) 60%,
        rgba(#fff, 0)
      );
      animation: shimmer 2s infinite;
      content: '';
    }
  
    @keyframes shimmer {
      100% {
        transform: translateX(100%);
      }
    }
  }
</style>